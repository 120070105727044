export const MOCK_WORKERS_DATA = [
  {
    lat: 30,
    long: -110,
    id: '999',
    nameEng: 'Chan Tai Man',
    nameZht: '陳大文',
    trade: 'General Worker',
    contractor: 'Sample Contractor',
    lastUpdated: '2222-22-22 22:22',
    isTemper: true,
    isHeartRate: true,
    isSos: false,
    isAlert: true,
    isLowBattery: true
  }
];

export const MOCK_ZONES_DATA = [
  {
    zoneId: '99',
    zoneName: 'Sample Zone 99',
    color: 'FF0000',
    lat: 31,
    long: -109
  }
];

export const MOCK_GEO_FENCES_DATA = [
  {
    type: 'work',
    color: 'FF33F0',
    gps: [
      {
        lat: 31,
        long: -109
      },
      {
        lat: 30,
        long: -108
      },
      {
        lat: 31,
        long: -112
      }
    ]
  }
];
