import React from 'react'
import ButtonModel from './buttonModel';

const OuterLineButton: React.FC<{
	viewModel: ButtonModel
}> = ({ viewModel }) => {
	return (
		<button
			className='outer-button-normal'
			onClick={viewModel.onClick}
			style={viewModel.style}
		>
			{viewModel.title}
		</button>
	)
}

export default OuterLineButton;
