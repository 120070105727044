import React, {useEffect, useState} from 'react'
import {Zone} from "../../model/home/homePageModel";
import {ControlZone} from "../../model/project/projectModel";
import ZoneList from "./zoneList";
import {useTranslation} from "react-i18next";
import NormalLabel from "../label/normalLabel";
import AppIconButton from "../button/IconButton";
import IconClose from "../../assests/images/icon-close.png";
import {AppService} from "../../services/appService";
import {parseISO} from "rsuite/cjs/utils/dateUtils";
import {getDateTime} from "../../extension/dateExtension";

interface AiCamZoneAlert {
  id: string,
  projectId: string,
  zoneName: string,
  deviceId: string,
  incidentTypeId: number,
  incidentValue: number,
  url: string,
  createdAt: string,
}

const AiCamZoneList: React.FC<{
  appService: AppService,
  projectID: string,
  aiCamAlertOverview?: { [key: string]: number } | null,
  zoneList: Zone[],
  controlZoneList: ControlZone[],
  isShowZeroCountZones: boolean,
  onChangeViewType?: (viewType: string) => void
}> = ({ appService, projectID, aiCamAlertOverview, zoneList, controlZoneList, isShowZeroCountZones, onChangeViewType }) => {
  const { t } = useTranslation()
  const [selectedZoneName, setSelectedZoneName] = useState<string|null>();
  const [zoneAlerts, setZoneAlerts] = useState<AiCamZoneAlert[]|null>();
  const [iframeUrl, setIframeUrl] = useState<string|null>();

  const aiCamAlertOverviewJson = aiCamAlertOverview ? JSON.stringify(aiCamAlertOverview) : null;
  useEffect(() => {
    if (selectedZoneName) {
      appService.dashboardService
        .getTodayProjectZoneAiCameraAlerts({
          projectID, zoneName: selectedZoneName
        })
        .subscribe(
          (value: any) => {
            setZoneAlerts(value.data.data);
          }
        );
    }
  }, [aiCamAlertOverviewJson, selectedZoneName]);

  const getZoneList = () => {
    const resultZoneList = zoneList.map(x => ({
      ...x,
      count: aiCamAlertOverview ? aiCamAlertOverview[x.name] ?? 0 : null,
    }) as Zone).filter(x => x.count || isShowZeroCountZones)
    if (aiCamAlertOverview) {
      return resultZoneList.sort((a, b) => b.count! - a.count!)
    }
    return resultZoneList;
  }

  const getControlZoneList = () => {
    const resultZoneList = controlZoneList.map(x => ({
      name: x.zone,
      count: aiCamAlertOverview ? aiCamAlertOverview[x.zone] ?? 0 : null,
    }) as Zone).filter(x => x.count || isShowZeroCountZones)
    if (aiCamAlertOverview) {
      return resultZoneList.sort((a, b) => b.count! - a.count!)
    }
    return resultZoneList;
  }

  console.log('zoneAlerts', zoneAlerts)
  return (
    <>
      {selectedZoneName ? (
        <div style={{ width: '100%', height: '62vh', padding: '15px 20px 20px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <NormalLabel title={selectedZoneName} fontSize={'20'} />
            </div>
            <AppIconButton
              viewModel={{
                onClick: () => setSelectedZoneName(null),
              }}
              image={IconClose}
            />
          </div>
          <div style={{ marginTop: 12, flex: 1, backgroundColor: 'black', borderRadius: 8, padding: 15, overflow: 'auto' }}>
            <table className="ai-cam-alert-table">
              <thead>
              <tr>
                <th><NormalLabel title={'時間'} fontSize={"18"} /></th>
                <th><NormalLabel title={'內容'} fontSize={"18"} /></th>
                <th><NormalLabel title={'圖片/影片'} fontSize={"18"} /></th>
              </tr>
              </thead>
              <tbody>
              {zoneAlerts?.map(x => (
                <tr key={x.id}>
                  <td><NormalLabel title={getDateTime(parseISO(x.createdAt))} fontSize={"18"} /></td>
                  <td>
                    <NormalLabel
                      title={{
                        1: 'Missing Safety Helmet',
                        2: 'Missing Reflective Vest',
                        3: 'Heavy Machinery Detected',
                        4: 'People Intrusion Detected',
                      }[x.incidentTypeId] ?? 'Unknown Incident'}
                      fontSize={"18"}
                    />
                  </td>
                  <td>
                    <button type="button" onClick={() => setIframeUrl(x.url)} style={{ cursor: 'pointer', background: 'unset', border: 0 }}>
                      <NormalLabel title={`連結`} fontSize={"18"} />
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          {iframeUrl && (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, backdropFilter: 'blur(10px)' }}>
              <div className={"ai-cam-iframe-container"} style={{ position: 'relative', width: 1080, height: 768, padding: 50, }}>
                <div style={{ position: 'absolute', top: 10, right: 10 }}>
                  <AppIconButton
                    viewModel={{
                      onClick: () => setIframeUrl(null),
                    }}
                    image={IconClose}
                  />
                </div>

                <iframe src={iframeUrl} style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className='home-bottom-zone-div'>
            <ZoneList
              contentTitle={t('home_alert_ai_cam')}
              contentType={'ai-cam'}
              title={t('home_working_zone')}
              selectedZone={undefined}
              zoneList={getZoneList()}
              lastUpdateTime={null ?? ""}
              isControlZone={false}
              onChangeViewType={onChangeViewType}
              onItemClickHandle={x => setSelectedZoneName(x.name)}
              mutedList={[]}
            />
          </div>
          <div className='home-bottom-zone-div'>
            <ZoneList
              contentType={'ai-cam'}
              title={t('home_controlled_zone')}
              selectedZone={undefined}
              zoneList={getControlZoneList()}
              lastUpdateTime={null ?? ""}
              isControlZone={true}
              onItemClickHandle={x => setSelectedZoneName(x.name)}
              mutedList={[]}
            />
          </div>
        </>
      )}
    </>
  )
}

export default AiCamZoneList