import { CancelToken } from "axios";
import { AppService } from "./appService";

const dashboardService = (service: AppService) => ({
	fetchData: (formData: any, cancelToken: CancelToken) =>
		service.request(
			'post',
			'statistics',
			formData,
			"",
			cancelToken,
		),

	getZoneDetail: (formData: any) =>
		service.request(
			'post',
			'statistics/zones',
			formData,
		),
	getZoneOverview: (formData: any) =>
		service.request(
			'post',
			'statistics/zone-overview',
			formData,
		),
	getWeather: () =>
		service.request(
			'post',
			'statistics/weather'
		),
	getControlZone: (formData: any) =>
		service.request(
			'post',
			'statistics/control-zones',
			formData,
		),
	toggleControlZoneIsDenyAllWorkers: (formData: any) =>
		service.request(
			'post',
			'statistics/control-zones/deny-all-workers',
			formData,
		),
	getAlertTable: (formData: any) =>
		service.request(
			'post',
			'statistics/alert-messages',
			formData,
		),
	deleteAllMessage: (formData: any) =>
		service.request(
			'post',
			'statistics/alert-messages/delete',
			formData,
		),
	getTodayProjectAlertOverview: (formData: any) =>
		service.request(
			'post',
			'statistics/alert-overview',
			formData,
		),
	getTodayProjectPermitOverview: (formData: any) =>
		service.request(
			'post',
			'statistics/permit/overview',
			formData,
		),
	getTodayProjectZonePermitList: (formData: any) =>
		service.request(
			'post',
			'statistics/permit/zone',
			formData,
		),
	getTodayProjectGasSensorOverview: (formData: any) =>
		service.request(
			'post',
			'statistics/gas-sensor/overview',
			formData,
		),
	getTodayProjectZoneGasSensorList: (formData: any) =>
		service.request(
			'post',
			'statistics/gas-sensor/zone',
			formData,
		),
	getProjectGasSensor: (formData: any) =>
		service.request(
			'post',
			'statistics/gas-sensor',
			formData,
		),
	getProjectEnvSensor: (formData: any) =>
		service.request(
			'post',
			'statistics/env-sensor',
			formData,
		),
	getTodayProjectAiCameraOverview: (formData: any) =>
		service.request(
			'post',
			'statistics/ai-cam/alert-overview',
			formData,
		),
	getTodayProjectZoneAiCameraAlerts: (formData: any) =>
		service.request(
			'post',
			'statistics/ai-cam/alerts',
			formData,
		),
	getTodayProjectWorkerHealthAlertCount: (formData: any) => 
		service.request(
			'post',
			'statistics/workerHealth/alerts',
			formData
		),
	getELockOverview: (formData: any) =>
		service.request(
			'post',
			'statistics/e-lock/overview',
			formData,
		),
	getProjectELockStats: (formData: any) =>
		service.request(
			'post',
			'statistics/e-lock',
			formData,
		),
	getWorkerWatch: (formData: any) =>
		service.request(
			'post',
			'statistics/worker-watch',
			formData,
		),
	getMicSummary: (formData: any) =>
		service.request(
			'post',
			'statistics/mic/summary',
			formData,
		),
	getMicComponent: (formData: any) =>
		service.request(
			'post',
			'statistics/mic/component',
			formData,
		),
	getMicZoneSummary: (formData: any) =>
		service.request(
			'post',
			'statistics/mic/zone/summary',
			formData,
		),
});

export default dashboardService;
