import React from 'react';
import Container from 'react-bootstrap/Container';

import Logo from '../assests/images/logo.png';
import Row from 'react-bootstrap/Row';

const PageNotFound: React.FC = ({ }) => {
	return (
		<Container fluid className='main-container'>
			<Container fluid className='page-not-found-div'>
				<Row>
					<div>
						<img src={Logo} alt='brand logo'></img>

					</div>
				</Row>
				<br /><br /><br />
				<Row>
					<label className='page-not-found-text'>
						Page Not found
					</label>
				</Row>

			</Container>
		</Container >
	);
}

export default PageNotFound;