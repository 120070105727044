import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export const GisMapLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
    >
      <CircularProgress />
    </Box>
  );
};
