import { createSlice } from "@reduxjs/toolkit";

export const viewSlice = createSlice({
	name: "view",
	initialState: {
		showProject: false,
		showSetting: false,
		showFilter: false,
		showFilterHideOption: false,
		showLoading: false,
		showTokenExpired: false,
		showZeroCountZones: false,
		showMic: false,
	},
	reducers: {
		showProject: (state, action) => {
			state.showProject = action.payload;
		},
		showSetting: (state, action) => {
			state.showSetting = action.payload;
		},
		showFilter: (state, action) => {
			state.showFilter = action.payload;
		},
		showFilterHideOption: (state, action) => {
			state.showFilterHideOption = action.payload;
		},
		showLoading: (state, action) => {
			state.showLoading = action.payload;
		},
		showTokenExpired: (state, action) => {
			state.showTokenExpired = action.payload;
		},
		showZeroCountZones: (state, action) => {
			state.showZeroCountZones = action.payload;
		},
		showMic: (state, action) => {
			state.showMic = action.payload;
		}
	},
});

export const { showProject, showSetting, showFilter, showFilterHideOption, showLoading, showTokenExpired, showZeroCountZones, showMic } = viewSlice.actions;

export default viewSlice.reducer;