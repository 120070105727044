import { AppService } from "./appService";

const projectServices = (service: AppService) => ({
	getAttendProjectList: () =>
		service.request(
			'get',
			'projects',
		),

	getConstractorList: (projectId: string) =>
		service.request(
			'post',
			`projects/contractors`,
			{
				projectID: projectId
			}
		),

	getProjectTradeList: (projectId: string) =>
		service.request(
			'post',
			`projects/trades`,
			{
				projectID: projectId
			}
		),
});

export default projectServices;
