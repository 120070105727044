import React from 'react'
import NormalLabel from "../label/normalLabel";
import {useTranslation} from "react-i18next";
import {AppService} from "../../services/appService";
import {ProjectInfo} from "../../model/project/projectModel";
import {MicComponent} from "../../model/MicComponent";

type MicTrackingProps = {
  appService: AppService
  project: ProjectInfo,
}

const MicTracking: React.FC<MicTrackingProps> = ({ appService, project }) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState<string>('');
  const [micComponent, setMicComponent] = React.useState<MicComponent|null>(null);
  
  const getComponentStatus = (micComponent: MicComponent): string | null => {
    if (micComponent.status === 'InAssembling') {
      return t("home_mimep_in_assembling");
    }
    if (micComponent.status === 'NotYetInstalled') {
      return t("home_mimep_not_yet_installed");
    }
    if (micComponent.status === 'Completed') {
      return t("home_mimep_completed");
    }
    return null;
  }
  
  const handleSearch = () => {
    if (search === '') {
      setMicComponent(null);
      return;
    }
    appService.dashboardService
      .getMicComponent({
        "projectID": project.projectID,
        "micID": search,
      })
      .subscribe(
        (value: any) => {
          setMicComponent(value.data.data);
        },
        (error) => {}
      );
  }
  
  return (
    <div
      style={{
        padding: '15px 25px',
        height: '100%',
      }}
    >
      <div className="d-flex justify-content-between align-items-center" style={{ height: 40 }}>
        <NormalLabel title={t("home_mimep_tracking")} fontSize="24" />
        <div className="d-flex align-items-center">
          <input 
            type="text" 
            placeholder={t("home_mimep_tracking_search_placeholder")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            style={{
              padding: '8px 12px',
              borderRadius: 5,
              border: 0,
              outline: 'none',
              width: 200,
              fontSize: 16,
              marginRight: 8,
              fontWeight: 700,
              color: '#000',
            }}
          />
          <button type="button" onClick={handleSearch} style={{ marginRight: -8, padding: 4, cursor: 'pointer', display: 'flex', background: 'transparent', outline: 'none' }}>
            <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ padding: 0 }}>
              <path d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
      <div>
        <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: 'calc(50% - 10px)' }}>
            <NormalLabel title={t("home_mimep_tracking_item_id")} fontSize="20" />
          </div>
          <div style={{ width: 'calc(50% - 10px)' }}>
            <NormalLabel title={micComponent?.micId || '-'} fontSize="20" />
          </div>
        </div>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: 'calc(50% - 10px)' }}>
            <NormalLabel title={t("home_mimep_tracking_zone")} fontSize="20" />
          </div>
          <div style={{ width: 'calc(50% - 10px)' }}>
            <NormalLabel title={micComponent ? micComponent.installedZoneName || micComponent.lastSeenZoneName || 'N/A' : '-'} fontSize="20" />
          </div>
        </div>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: 'calc(50% - 10px)' }}>
            <NormalLabel title={t("home_mimep_tracking_status")} fontSize="20" />
          </div>
          <div style={{ width: 'calc(50% - 10px)' }}>
            <NormalLabel title={micComponent ? getComponentStatus(micComponent) || 'N/A' :'-'} fontSize="20" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MicTracking