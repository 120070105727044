import { i18n, TFunction } from "i18next";
import { useEffect, useState } from "react";
import { CHUNK_SIZE } from "../../../model/constants";
import {HealthDataModel, WorkerWatchModel} from "../../../model/home/homePageModel";
import { ZoneItemDetail } from "../../../model/project/projectModel";
import ArrowLabel from "../../label/arrowLabel";
import BorderedLabel from "../../label/borderedLabel";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";
import moment from "moment";
import {parseISO} from "rsuite/cjs/utils/dateUtils";

export const WorkerWithHealthContent: React.FC<{
  wokerList: (ZoneItemDetail)[],
  workerWatch?: WorkerWatchModel[],
  isControlZone: boolean,
  t: TFunction<"translation">,
  i18n: i18n
}> = ({ wokerList, workerWatch, isControlZone, t, i18n }) => {

  const [pageIndex, setpageIndex] = useState(1);

  const [sorting, setSorting] = useState({ key: "", aesOrder: false });

  const [workerLists, setWorkerLists] = useState<ZoneItemDetail[]>(wokerList);

  useEffect(() => {
    if (sorting.key.length > 0) {

      const _wokerList: any = [...wokerList]

      _wokerList.sort((a: any, b: any) => {
        if (sorting.key.includes(".")) {
          const spiltKey = sorting.key.split(".")
          if (typeof (a[spiltKey[0]][spiltKey[1]] ?? "-") === "number" &&
            typeof (b[spiltKey[0]][spiltKey[1]] ?? "-") === "number") {
            const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? 0);
            const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? 0);
            return nestedValueA - nestedValueB
          } else {
            const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? "0").toString();
            const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? "0").toString();
            return nestedValueA.localeCompare(nestedValueB)
          }
        } else {
          if (typeof (a[sorting.key] ?? "-") === "number" && typeof (b[sorting.key] ?? "-") === "number") {
            const valueA = (a[sorting.key] ?? 0);
            const valueB = (b[sorting.key] ?? 0);
            return valueA - valueB
          }
          return (a[sorting.key] ?? "-").localeCompare((b[sorting.key] ?? "-"))
        }
      })

      setWorkerLists(sorting.aesOrder ? _wokerList : _wokerList.reverse())
    } else {
      setWorkerLists(wokerList);
    }

  }, [sorting])

  const groupWorkerList = (sorting.key.length > 0 ? workerLists : wokerList)?.map((value, index) => {
    return index % CHUNK_SIZE === 0 ?
      (sorting.key.length > 0 ? (workerLists ?? []) : wokerList).slice(index, index + CHUNK_SIZE) : null
  }).filter((e) => { return e });

  const setSortingHandle = (key: string) => {
    var aesOrder = !sorting.aesOrder;

    if (key !== sorting.key) {
      aesOrder = true;
    }
    setSorting({
      key: key,
      aesOrder: aesOrder
    })
  }

  const getSortArrowByKey = (key: string) => {
    if (key === sorting.key) {
      return !sorting.aesOrder;
    }
    return false;
  }

  const isEng = i18n.language.includes("en")

  return (
    <div className='paging-container-div' style={{ width: '100%' }}>
      <div style={{
        paddingLeft: '30px',
        paddingRight: '30px',
        display: 'flex',
        width: '100%'
      }}
      >
        <div style={{
          flex: "2",
        }}
             onClick={() => setSortingHandle(isEng ? "workerNameEn" : "workerNameZh")}>
          <ArrowLabel title={t('home_zone_detail_name')} isUp={getSortArrowByKey(isEng ? "workerNameEn" : "workerNameZh")} />
        </div>
        <div style={{
          flex: "4",
        }}
             onClick={() => setSortingHandle(isEng ? "contractorEn" : "contractorZh")}>
          <ArrowLabel title={t('home_contractor')} isUp={getSortArrowByKey(isEng ? "contractorEn" : "contractorZh")} />
        </div>

        <div style={{
          flex: "3",
        }}
             onClick={() => setSortingHandle(isEng ? "tradeEn" : "tradeZh")}>
          <ArrowLabel title={t('home_trade')} isUp={getSortArrowByKey(isEng ? "tradeEn" : "tradeZh")} />
        </div>
        <div style={{
          flex: "1",
          paddingLeft: '10px',
        }}
             onClick={() => setSortingHandle("age")}>
          <ArrowLabel title={t('home_zone_detail_worker_age')} isUp={getSortArrowByKey("age")} />
        </div>
        {isControlZone && (
          <div style={{ flex: '2', paddingLeft: '10px' }}>
            <NormalLabel title={t('home_zone_authorisation')} />
          </div>
        )}
        <div style={{
          flex: "3",
          paddingLeft: '10px',
        }}>
          <NormalLabel title={t('health_status_alert')} />
        </div>
        <div style={{
          flex: "3",
          paddingLeft: '10px',
        }}>
          <NormalLabel title={t('watch_status')} />
        </div>
      </div>
      <div style={{ height: '11px' }} />
      <div className='div-spacing-zone-line' />
      <div className='paging-div-full-content'>
        {
          (groupWorkerList[pageIndex - 1] ?? [])?.map((value, index) => {
            return (
              ZoneHealthContentTable(
                isControlZone,
                index,
                value?.healthData!,
                t,
                workerWatch,
                value?.workerId,
              )
            )
          })
        }
      </div>
      <div className='div-spacing-zone-line' />
      <Paging
        pageSize={groupWorkerList?.length ?? 1}
        onPageClick={(index) => {
          setpageIndex(index);
        }}
      />
    </div >
  );
}

const ZoneHealthContentTable = (
  isControlZone: boolean,
  index: number,
  healthData: HealthDataModel,
  t: TFunction<"translation">,
  workerWatch?: WorkerWatchModel[],
  workerId?: string,
) => {
  const workerWatchData = workerWatch?.find(x => x.workerId === workerId);
  const alertType = workerWatchData?.alertType;
  const watchBattery = workerWatchData?.watchBattery;
  const watchBatteryLastUpdatedAt = workerWatchData?.watchBatteryLastUpdatedAt ? moment(parseISO(workerWatchData.watchBatteryLastUpdatedAt)) : null
  const currentDate = moment();
  const diffInMinutes = currentDate.diff(watchBatteryLastUpdatedAt, 'minutes');
  
  const getHealthStatus = () => {
    var status: string[] = []

    // const hr = healthData.workerHr;
    // const temp = healthData.workerTemp;
    //
    // if (hr != null && (hr > 140 || hr < 40)) {
    //   status.push(t("health_heart_rate_abnormally_alert"))
    // }

    if (alertType?.includes('LowTemperature') || alertType?.includes('HighTemperature')) {
      status.push(t("health_abnormal_tempture_alert"))
    }

    if (alertType?.includes('Fall')) {
      status.push(t("health_falling_alert"))
    }

    if (alertType?.includes('HelmetOff')) {
      status.push(t("health_helmet_off_alert"))
    }

    if (alertType?.includes('Still')) {
      status.push(t("health_stand_still_alert"))
    }

    if (alertType?.includes('SOS')) {
      status.push(t("health_sos_alert"))
    }

    if (alertType?.includes('BTOff')) {
      status.push(t("health_smartag_offline_alert"))
    }

    if (!alertType) {
      status = [t("health_normal_alert")]
    }

    return status;
  }

  const getColor = (): string => {
    if (isControlZone && !healthData.isAuth) {
      return "rgba(255, 143, 13, 1)"
    }
    if (statusList.includes(t("health_normal_alert")) ||
      (!statusList.includes(t("health_falling_alert")) &&
        !statusList.includes(t("health_abnormal_tempture_alert")) &&
        !statusList.includes(t("health_heart_rate_abnormally_alert")))
    ) {
      return "rgba(255, 255, 255, 1)"
    }
    return "rgba(255, 143, 13, 1)"
  }

  const getStatusColor = (status: string): string => {
    if (status === t("health_normal_alert") ||
      status === t("health_stand_still_alert") ||
      status === t("health_smartag_offline_alert")) {
      return "rgba(255, 255, 255, 1)"
    }
    return "rgba(255, 143, 13, 1)"
  }

  const getBorderColor = (status?: string): string => {
    if (status !== undefined) {
      if (status === t("health_normal_alert")) {
        return "rgba(255, 255, 255, 0)"
      } else {
        return "rgba(255, 143, 13, 1)"
      }
    } else {

      if (!statusList.includes(t("health_falling_alert")) &&
        !statusList.includes(t("health_abnormal_tempture_alert")) &&
        !statusList.includes(t("health_heart_rate_abnormally_alert"))
      ) {
        return "rgba(255, 255, 255, 0)"
      } else {
        return "rgba(255, 143, 13, 1)"
      }
    }
  }

  const statusList = getHealthStatus();

  const textColor = getColor();

  return (
    <div
      key={index}
      data-id={workerId}
      style={{
        paddingTop: '13px',
        paddingBottom: '13px',
        display: 'flex',
      }}
    >
      <div
        style={{
          flex: "2",
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere',
        }}
      >
        <NormalLabel title={healthData.workerName ?? "-"} textColor={textColor} />
      </div>

      <div
        style={{
          flex: '4',
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere',
          maxWidth: '60%'
        }}
      >
        <NormalLabel title={healthData.workerContractor ?? "-"} textColor={textColor} />
      </div>
      <div
        style={{
          flex: "3",
          whiteSpace: 'pre-line',
          paddingLeft: '10px'
        }}
      >
        <NormalLabel title={healthData.workerTrade ?? "-"} textColor={textColor} />
      </div>
      <div
        style={{
          flex: "1",
          paddingLeft: '10px'
        }}
      >
        <NormalLabel title={healthData.workerAge ?? "-"} textColor={textColor} />
      </div>
      {isControlZone && (
        <div
          style={{
            flex: "2",
            paddingLeft: '10px'
          }}
        >
          <NormalLabel title={healthData.isAuth ? t("home_controlled_zone_authorised") : t("home_controlled_zone_unauthorised")} textColor={getColor()} />
        </div>
      )}
      <div
        style={{
          flex: "3",
          paddingLeft: '10px',
          display: "flex"
        }}
      >
        {
          statusList.includes(t("health_normal_alert")) ? <div style={{ width: "16px", height: "16px" }}></div> : <div className='circle-div' style={{ marginTop: 5 }} />
        }
        <div style={{
          display: "grid",
          gridTemplateColumns: "100px 100px",
          gridTemplateRows: "auto",
          rowGap: "15px",
        }}>
          {
            statusList.map((ele) => {
              return <div >
                <BorderedLabel
                  title={`${ele}`}
                  textColor={getStatusColor(ele)}
                  borderColor={getBorderColor(ele)}
                />
              </div>
            })
          }
        </div>
      </div>
      <div
        style={{
          flex: "3",
          paddingLeft: '10px',
          display: "flex"
        }}
      >
        {watchBattery && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NormalLabel title={`${watchBattery}%`} style={{ width: 'auto' }} />
            <div
              style={{
                marginLeft: 12,
                marginRight: 4,
                width: 12,
                height: 12,
                borderRadius: 6,
                backgroundColor: diffInMinutes < 15 ? 'green' : diffInMinutes <= 30 ? 'orange' : 'red',
              }}
            />
            {watchBatteryLastUpdatedAt && (
              <NormalLabel title={`${watchBatteryLastUpdatedAt.format('yyyy-MM-DD HH:mm')}`} style={{ width: 'auto' }} />
            )}
          </div>
        )}
      </div>
    </div >
  )
}