import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './screen/home';
import Login from './screen/login';
import PageNotFound from './screen/pageNotFound';
import Header from './components/header';
import './style/style.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import PickProject from './screen/pickProject';
import { useSelector } from "./redux/store";
import LogoutBlock from './components/logoutBlock';
import { useDispatch } from "react-redux";
import { AppService } from './services/appService';
import { Spinner } from 'react-bootstrap';
import { MessageDialog } from './components/dialog';
import { showTokenExpired } from './redux/viewSlice';
import HomeDemo from './screen/homeDeme';

import './i18n'
import { useTranslation } from "react-i18next";

const App: React.FC = ({ }) => {

  const { t, i18n } = useTranslation()

  const dispatch = useDispatch();

  const isAuth = useSelector((state: any) => state.user.isAuth as boolean);
  const isShowProject = useSelector((state: any) => state.view.showProject);
  const isShowSetting = useSelector((state: any) => state.view.showSetting);
  const isShowLoading = useSelector((state: any) => state.view.showLoading);
  const isShowTokenExpired = useSelector((state: any) => state.view.showTokenExpired);

  const appService = new AppService(process.env.REACT_APP_API_URL ?? "https://czapi.maventhoughts.com/");

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: (isAuth),
    authenticationPath: '/login',
  };

  return (
    <Router>
      <Header appService={appService} />
      <main>
        <Routes>
          <Route>
            <Route path='/' element={
              isAuth ? <Navigate to={'/home'} /> :
                <Navigate to={'/login'} />
            }
            />
          </Route>
          <Route
            path='/project'
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<PickProject appService={appService} />} />
            }
          />
          <Route
            path='/home'
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Home appService={appService} />} />
            }
          />
          <Route
            path='/homes'
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<HomeDemo appService={appService} />} />
            }
          />
          <Route path='/login' element={<Login appService={appService} />} />
          <Route path='/*' element={<PageNotFound />} />
        </Routes>
      </main>
      {
        isShowLoading ?
          <div className='pop-up-div-loading'>
            <Spinner animation="border" role="status" >
              <span className="visually-hidden"></span>
            </Spinner>
          </div> : null
      }
      {
        isShowProject ?
          <div className='pop-up-div'>
            <PickProject appService={appService} />
          </div>
          : null
      }
      {
        isShowSetting ?
          <div className='pop-up-div' >
            <LogoutBlock appService={appService} />
          </div>
          : null
      }
      {
        isShowTokenExpired ?
          <MessageDialog
            dialogProps={{
              title: t('dialog_session_expired_title'),
              content: t('dialog_session_expired_message'),
              btnTitle: t('dialog_session_expired_button'),
              open: true,
              handleClose: () => {
                dispatch(showTokenExpired(false));
              }
            }}
          /> : null
      }
      <div
        style={{
          position: 'absolute',
          bottom: '0px',
          right: '20px',
          height: '30px',
          width: '100px',
          color: 'white',
          textAlign: 'right',
          fontSize: '20px',
        }}>
        {process.env.REACT_APP_VERSION}
      </div>
    </Router >
  );
}

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

export function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet
}: ProtectedRouteProps) {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
};


export default App;
