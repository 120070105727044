import React, {useEffect, useState} from 'react'
import {Zone} from "../../model/home/homePageModel";
import {ControlZone} from "../../model/project/projectModel";
import ZoneList from "./zoneList";
import {useTranslation} from "react-i18next";
import NormalLabel from "../label/normalLabel";
import AppIconButton from "../button/IconButton";
import IconClose from "../../assests/images/icon-close.png";
import {AppService} from "../../services/appService";
import {parseISO} from "rsuite/cjs/utils/dateUtils";
import {getDateTime} from "../../extension/dateExtension";

interface Permit
{
	id: string,
	projectId: string,
	zoneName: string,
	permitId: string,
	type: string;
	applicant?:string;
	formName?:string;
	workers?: any[];
	startDate: string,
	endDate: string,
	status: string,
}

const PermitZoneList: React.FC<{
	appService: AppService,
	projectID: string,
	permitOverview?: {[key: string]: number}|null,
	zoneList: Zone[],
	controlZoneList: ControlZone[],
	isShowZeroCountZones: boolean,
	onChangeViewType?: (viewType: string) => void
}> = ({appService, projectID, permitOverview, zoneList, controlZoneList, isShowZeroCountZones, onChangeViewType}) =>
{
	const {t} = useTranslation()
	const [selectedZoneName, setSelectedZoneName] = useState<string|null>();
	const [zonePermits, setZonePermits] = useState<Permit[]|null>();
	const [iframeUrl, setIframeUrl] = useState<string|null>();

	const permitOverviewJson = permitOverview ? JSON.stringify(permitOverview) : null;
	useEffect(() =>
	{
		if (selectedZoneName)
		{
			appService.dashboardService
			.getTodayProjectZonePermitList({
				projectID, zoneName: selectedZoneName
			})
			.subscribe(
				(value: any) =>
				{
					setZonePermits(value.data.data);
				}
			);
		}
	}, [permitOverviewJson, selectedZoneName]);

	const getZoneList = () =>
	{
		const resultZoneList = zoneList.map(x => ({
			...x,
			count: permitOverview ? permitOverview[x.name] ?? 0 : null,
		}) as Zone).filter(x => x.count || isShowZeroCountZones)
		if (permitOverview)
		{
			return resultZoneList.sort((a, b) => b.count! - a.count!)
		}
		return resultZoneList;
	}

	const getControlZoneList = () =>
	{
		const resultZoneList = controlZoneList.map(x => ({
			name: x.zone,
			count: permitOverview ? permitOverview[x.zone] ?? 0 : null,
		}) as Zone).filter(x => x.count || isShowZeroCountZones)
		if (permitOverview)
		{
			return resultZoneList.sort((a, b) => b.count! - a.count!)
		}
		return resultZoneList;
	}

	console.log('zonePermits', zonePermits)
	return (
		<>
			{selectedZoneName ? (
				<div style={{width: '100%', height: '62vh', padding: '15px 20px 20px', display: 'flex', flexDirection: 'column'}}>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<div>
							<NormalLabel title={selectedZoneName} fontSize={'20'}/>
						</div>
						<AppIconButton
							viewModel={{
								onClick: () => setSelectedZoneName(null),
							}}
							image={IconClose}
						/>
					</div>
					<div style={{marginTop: 12, flex: 1, backgroundColor: 'black', borderRadius: 8, padding: 15, overflow: 'auto'}}>
						<table className="ai-cam-alert-table">
							<thead>
							<tr>
								<th><NormalLabel title={'許可證ID'} fontSize={"18"}/></th>
								<th><NormalLabel title={'許可證種類'} fontSize={"18"}/></th>
								<th><NormalLabel title={'申請人'} fontSize={"18"}/></th>
								<th><NormalLabel title={'工作時間'} fontSize={"18"}/></th>
								<th><NormalLabel title={'狀態'} fontSize={"18"}/></th>
							</tr>
							</thead>
							<tbody>
							{zonePermits?.map(x => (
								<tr key={x.id}>
									<td><NormalLabel title={x.permitId.split('-')[0]} fontSize={"18"}/></td>
									<td><NormalLabel title={(x.formName)?x.formName:''} fontSize={"18"}/></td>
									<td><NormalLabel title={(x.applicant)?x.applicant:''} fontSize={"18"}/></td>
									<td><NormalLabel title={`${getDateTime(parseISO(x.startDate))} - ${getDateTime(parseISO(x.endDate))}`} fontSize={"18"}/></td>
									<td><NormalLabel title={`${(x.status === 'APPROVED') ? "生效中" : (x.status === 'EXPIRED') ? '已過期' : ''}`} fontSize={"18"}/></td>
								</tr>
							))}
							</tbody>
						</table>
					</div>

					{iframeUrl && (
						<div style={{position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, backdropFilter: 'blur(10px)'}}>
							<div className={"ai-cam-iframe-container"} style={{position: 'relative', width: 1080, height: 768, padding: 50,}}>
								<div style={{position: 'absolute', top: 10, right: 10}}>
									<AppIconButton
										viewModel={{
											onClick: () => setIframeUrl(null),
										}}
										image={IconClose}
									/>
								</div>

								<iframe src={iframeUrl} style={{width: '100%', height: '100%'}}/>
							</div>
						</div>
					)}
				</div>
			) : (
				<>
					<div className='home-bottom-zone-div'>
						<ZoneList
							contentTitle={t('home_permit')}
							contentType={'permit'}
							title={t('home_working_zone')}
							selectedZone={undefined}
							zoneList={getZoneList()}
							lastUpdateTime={null ?? ""}
							isControlZone={false}
							onChangeViewType={onChangeViewType}
							onItemClickHandle={x => setSelectedZoneName(x.name)}
							mutedList={[]}
						/>
					</div>
					<div className='home-bottom-zone-div'>
						<ZoneList
							contentType={'permit'}
							title={t('home_controlled_zone')}
							selectedZone={undefined}
							zoneList={getControlZoneList()}
							lastUpdateTime={null ?? ""}
							isControlZone={true}
							onItemClickHandle={x => setSelectedZoneName(x.name)}
							mutedList={[]}
						/>
					</div>
				</>
			)}
		</>
	)
}

export default PermitZoneList