import React from 'react'
import {useTranslation} from "react-i18next";
import NormalLabel from "../label/normalLabel";
import {Chart} from "react-google-charts";

const AlertOverview: React.FC<{
  alertOverview: any
}> = ({ alertOverview }) => {
  const { t } = useTranslation()
  const colors = [
    '#ffc501', 
    '#5a7a34', 
    '#5b9b0e', 
    '#d9d9d9', 
    '#ff6280',
    '#457be7',
    '#9654e5',
  ];
  const renderTitleAndCount = (color: string, title: string, count: number|null) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 6 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8, width: 12, height: 12, backgroundColor: color }} />
          <NormalLabel title={title} fontSize={'16'}  />
        </div>
        <div style={{ marginLeft: 16 }}>
          <NormalLabel title={`${count ?? "-"}`} fontSize={'16'} />
        </div>
      </div>
    )
  }
  const hasAnyAlert = () => {
    const unauthorizedEntry = alertOverview?.unauthorizedEntry || 0;
    const workerStatus = alertOverview?.workerStatus || 0;
    const expiredPermit = alertOverview?.expiredPermit || 0;
    const expiredMachine = alertOverview?.expiredMachine || 0;
    const aiCamera = alertOverview?.aiCamera || 0;
    const gasSensor = alertOverview?.gasSensor || 0;
    const eLock = alertOverview?.eLock || 0;
    return unauthorizedEntry > 0 || workerStatus > 0 || expiredPermit > 0 || expiredMachine > 0 || aiCamera > 0 || gasSensor > 0 || eLock > 0;
  }
  return (
    <div
      className=''
      style={{
        padding: '15px 25px',
        height: '100%',
      }}
    >
      <div style={{ height: 40 }}>
        <NormalLabel title={t("home_alert_overview")} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 4 }}>
        <div style={{ flex: 1 }}>
          <div>
            {renderTitleAndCount(colors[0], t("home_alert_control_zone"), alertOverview?.unauthorizedEntry)}
            {renderTitleAndCount(colors[1], t("home_alert_worker_status"), alertOverview?.workerStatus)}
            {renderTitleAndCount(colors[2], t("home_alert_permit"), alertOverview?.expiredPermit)}
            {renderTitleAndCount(colors[3], t("home_alert_machine_object"), alertOverview?.expiredMachine)}
            {renderTitleAndCount(colors[4], t("home_alert_inspection"), alertOverview?.aiCamera)}
            {renderTitleAndCount(colors[5], t("home_alert_gas_sensor"), alertOverview?.gasSensor)}
            {renderTitleAndCount(colors[6], t("home_alert_e_lock"), alertOverview?.eLock)}
          </div>
        </div>
        <div style={{ width: 160, display: 'flex', justifyContent: 'flex-end' }}>
          {alertOverview && hasAnyAlert() && (
            <div style={{ width: 120, height: 120, overflow: 'hidden', flexShrink: 0 }}>
              <div style={{ transform: 'translate(0, -25px)' }}>
                <Chart
                  chartType="PieChart"
                  data={[
                    ["Alert", "Count"],
                    ["unauthorizedEntry", alertOverview?.unauthorizedEntry || 0],
                    ["workerStatus", alertOverview?.workerStatus || 0],
                    ["expiredPermit", alertOverview?.expiredPermit || 0],
                    ["expiredMachine", alertOverview?.expiredMachine || 0],
                    ["aiCamera", alertOverview?.aiCamera || 0],
                    ["gasSensor", alertOverview?.gasSensor || 0],
                    ["eLock", alertOverview?.eLock || 0],
                  ]}
                  options={{
                    colors,
                    legend: { position: "none" },
                    chartArea: {
                      width: '100%',
                      height: '100%',
                    },
                    pieSliceText: "none",
                    backgroundColor: 'transparent',
                    pieSliceBorderColor: 'transparent',
                    enableInteractivity: false,
                  }}
                  width={"120px"}
                  height={"160px"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AlertOverview