import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';

type MessageDialogProps = {
	title: string,
	content: string,
	btnTitle?: string,
	btnCancel?: string,
	open: boolean,
	handleClose: any,
};

export const MessageDialog: React.FC<{
	dialogProps: MessageDialogProps
}> = ({ dialogProps }) => {

	return (
		<div>
			<Dialog
				open={dialogProps.open}
				onClose={dialogProps.handleClose}
				maxWidth={'md'}
			>
				<DialogTitle>
					<label className='dialog-title' >
						{dialogProps.title}
					</label>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<label className='dialog-content' >
							{dialogProps.content}
						</label>
					</DialogContentText>
					<label className='dialog-content-dummy' >
						{ }
					</label>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={dialogProps.handleClose}
						style={{ width: '100%' }}
					>
						<div className='confrim-button'>
							{dialogProps?.btnTitle ?? "重試"}
						</div>
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	);
}

export const ConfirmDialog: React.FC<{
	dialogProps: MessageDialogProps,
	confirmCallBack: () => void,
}> = ({ dialogProps, confirmCallBack }) => {

	return (
		<Dialog
			open={dialogProps.open}
			onClose={dialogProps.handleClose}
			fullWidth={true}
			maxWidth={'xs'}
		>
			<DialogTitle>
				<label className='dialog-title' >
					{dialogProps.title}
				</label>
			</DialogTitle>
			<label className='dialog-content-dummy' >
				{ }
			</label>
			<DialogActions>
				<Button
					onClick={dialogProps.handleClose}
					style={{ width: '100%' }}
				>
					<div className='cancel-button'>
						{dialogProps.btnCancel}
					</div>
				</Button>
				<div style={{ width: '1px', height: '39px', backgroundColor: 'rgb(237, 237, 237)' }} />
				<Button
					onClick={() => confirmCallBack()}
					style={{ width: '100%' }}
				>
					<div className='confrim-button'>
						{dialogProps.btnTitle}
					</div>
				</Button>
			</DialogActions>
		</Dialog>
	);
}