import React, {useEffect, useState} from 'react'
import {Zone} from "../../model/home/homePageModel";
import {ControlZone} from "../../model/project/projectModel";
import ZoneList from "./zoneList";
import {useTranslation} from "react-i18next";
import NormalLabel from "../label/normalLabel";
import AppIconButton from "../button/IconButton";
import IconClose from "../../assests/images/icon-close.png";
import OfflineRedDot from "../../assests/images/offline-red-dot.png"
import OnlineGreenDot from "../../assests/images/online-green-dot.png"
import LowBatteryIcon from "../../assests/images/low-battery-icon.png"
import FullBatteryIcon from "../../assests/images/full-battery-icon.png"
import {AppService} from "../../services/appService";
import {parseISO} from "rsuite/cjs/utils/dateUtils";
import {getDateTime} from "../../extension/dateExtension";
import moment from "moment/moment";
import { number } from 'yup';

interface ELock {
  projectId: string,
  zoneName: string,
  eLockDeviceID: string,
  eLockDeviceSn: string,
  lockStatus?: string,
  battery?: string,
  updatedAt?: string
}

const LockStatusColour =  {
  'UNLOCKED-OPEN': 'lightgreen',
  'UNLOCKED-CLOSE': 'lightgreen',
  'LOCKED': 'white',
}

const ELockZoneList: React.FC<{
  appService: AppService,
  projectID: string,
  eLockOverview?: { [key: string]: number } | null,
  zoneList: Zone[],
  controlZoneList: ControlZone[],
  isShowZeroCountZones: boolean,
  onChangeViewType?: (viewType: string) => void
}> = ({ appService, projectID, eLockOverview, zoneList, controlZoneList, isShowZeroCountZones, onChangeViewType }) => {
  const { t } = useTranslation()
  const [eLocks, setELocks] = useState<ELock[]|null>();
  const [eLockZones, setELockZones] = useState<string[]>([]);
  const [iframeUrl, setIframeUrl] = useState<string|null>();
  const [zoneFilter, setZoneFilter] = useState('All');

  const eLockOverviewJson = eLockOverview ? JSON.stringify(eLockOverview) : null;
  useEffect(() => {
    appService.dashboardService
      .getProjectELockStats({
        projectID,
        filter: zoneFilter
      })
      .subscribe(
        (value: any) => {
          setELocks(value.data.data.eLocks);
          setELockZones(['All', ...value.data.data.zones]);
        }
      );
  }, [eLockOverviewJson, zoneFilter]);

  const getZoneList = () => {
    const resultZoneList = zoneList.map(x => ({
      ...x,
      count: eLockOverview ? eLockOverview[x.name] ?? 0 : null,
    }) as Zone).filter(x => x.count || isShowZeroCountZones)
    if (eLockOverview) {
      return resultZoneList.sort((a, b) => b.count! - a.count!)
    }
    return resultZoneList;
  }

  const getControlZoneList = () => {
    const resultZoneList = controlZoneList.map(x => ({
      name: x.zone,
      count: eLockOverview ? eLockOverview[x.zone] ?? 0 : null,
    }) as Zone).filter(x => x.count || isShowZeroCountZones)
    if (eLockOverview) {
      return resultZoneList.sort((a, b) => b.count! - a.count!)
    }
    return resultZoneList;
  }

//   const getGasSensorTypeReading = (gasSensor: GasSensor, type: string) => {
//     const sensor = gasSensor.sensors.find(x => x.type === type);
//     if (sensor) {
//       const {reading, unit, date} = sensor;
//       const dateString = moment(parseISO(date)).format('DD-MM-yyyy  HH:mm:ss')
//       return `${reading} ${unit} (${dateString})`;
//     }
//     return '-';
//   }

  return (
    <>
      {
        <div style={{ width: '100%', height: '62vh', padding: '15px 20px 20px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <NormalLabel title={t('home_alert_e_lock')} fontSize={'20'} />
            </div>
            
            <div style={{position: 'relative', display:'flex', alignItems: 'right'}}>
              <div style={{position: 'relative', padding: '16px 10px', display: 'flex', alignItems: 'right'}}>
                <div>
                  <NormalLabel title={eLockZones.find(x => x === zoneFilter) || '-'} fontSize={'20'} style={{ whiteSpace: 'nowrap' }}/>
                </div>
                <div style={{marginLeft: 6, transform: 'translate(0, -3px)'}}>
                  <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 17L0.210582 0.499997L28.7894 0.5L14.5 17Z" fill="white"/>
                  </svg>
                </div>
                <select
                  value={zoneFilter}
                  onChange={e => setZoneFilter(e.target.value)}
                  style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', fontSize: 24}}
                >
                  {eLockZones.map(x => (
                    <option value={x}>{x}</option>
                  ))}
                </select>
              </div>

              <AppIconButton
                viewModel={{
                  onClick: () => onChangeViewType ? onChangeViewType('default') : null,
                }}
                image={IconClose}
              />
            </div>
          </div>
          
          <div style={{ marginTop: 12, flex: 1, backgroundColor: 'black', borderRadius: 8, padding: 15, overflow: 'auto' }}>
            <table className="ai-cam-alert-table">
              <thead>
              <tr>
                <th><NormalLabel title={t('elock_list_name')} fontSize={"18"} /></th>
                <th><NormalLabel title={t('elock_list_zone')} fontSize={"18"} /></th>
                <th><NormalLabel title={t('elock_list_status')} fontSize={"18"} /></th>
                <th><NormalLabel title={t('elock_list_battery')} fontSize={"18"} /></th>
                <th><NormalLabel title={t('elock_list_updated_at')} fontSize={"18"} /></th>
              </tr>
              </thead>
              <tbody>
              {eLocks?.map(eLock => (
                <tr key={eLock.eLockDeviceID}>
                    <td>
                      <NormalLabel 
                        title={eLock.eLockDeviceID} 
                        fontSize={"18"} 
                        lineHeight="1.3" 
                        textColor={LockStatusColour[eLock.lockStatus as keyof typeof LockStatusColour] || 'orange'}
                      />
                    </td>
                    <td>
                      <NormalLabel 
                        title={eLock.zoneName} 
                        fontSize={"18"} 
                        lineHeight="1.3" 
                        textColor={LockStatusColour[eLock.lockStatus as keyof typeof LockStatusColour] || 'orange'}
                      />
                    </td>
                    <td>
                      <NormalLabel 
                        title={eLock.lockStatus??"NA"} 
                        fontSize={"18"} 
                        lineHeight="1.3"
                        textColor={LockStatusColour[eLock.lockStatus as keyof typeof LockStatusColour] || 'orange'}
                      />
                    </td>
                    <td>
                      <div style={{position: 'relative', display:'flex'}}>
                        <AppIconButton
                          viewModel={{ onClick: () => {} }}
                          image={
                            Number(eLock?.battery) > 20 ? FullBatteryIcon : LowBatteryIcon
                          }
                        />
                        <NormalLabel 
                          title={
                            (eLock.battery) ? (Number(eLock?.battery) > 20 ? "Normal" : "Low Battery") : ("NA")
                          } 
                          fontSize={"18"} 
                          lineHeight="1.3" 
                        />
                      </div>
                    </td>
                    <td>
                    <div style={{position: 'relative', display:'flex'}}>
                        <AppIconButton
                          viewModel={{ onClick: () => {} }}
                          image={
                            moment(eLock.updatedAt).isBetween(moment().subtract(1, 'hour'), moment()) ? OnlineGreenDot : OfflineRedDot
                          }
                        />
                        <NormalLabel title={eLock.updatedAt ? moment(eLock.updatedAt).format('YYYY-MM-DD HH:mm'): "NA"} fontSize={"18"} lineHeight="1.3" />
                      </div>
                      
                    </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          {iframeUrl && (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, backdropFilter: 'blur(10px)' }}>
              <div className={"ai-cam-iframe-container"} style={{ position: 'relative', width: 1080, height: 768, padding: 50, }}>
                <div style={{ position: 'absolute', top: 10, right: 10 }}>
                  <AppIconButton
                    viewModel={{
                      onClick: () => setIframeUrl(null),
                    }}
                    image={IconClose}
                  />
                </div>

                <iframe src={iframeUrl} style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}

export default ELockZoneList