import {useEffect, useState} from "react";
import {CHUNK_SIZE} from "../../../model/constants";
import ArrowLabel from "../../label/arrowLabel";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";
import {MicComponent} from "../../../model/MicComponent";
import {useTranslation} from "react-i18next";

type ComponentContentProps = {
  displayItemId: boolean
  components: MicComponent[]
}

const MicZoneComponentsContent: React.FC<ComponentContentProps> = ({ displayItemId, components}) => {
  const [pageIndex, setpageIndex] = useState(1);
  const [sorting, setSorting] = useState({ key: "", aesOrder: false });
  const [componentList, setComponentList] = useState<MicComponent[]>(components);

  useEffect(() => {
    if (sorting.key.length > 0) {

      const _componentList: any = [...components]

      _componentList.sort((a: any, b: any) => {
        if (sorting.key.includes(".")) {
          const spiltKey = sorting.key.split(".")
          if (typeof (a[spiltKey[0]][spiltKey[1]] ?? "-") === "number" &&
            typeof (b[spiltKey[0]][spiltKey[1]] ?? "-") === "number") {
            const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? 0);
            const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? 0);
            return nestedValueA - nestedValueB
          } else {
            const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? "0").toString();
            const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? "0").toString();
            return nestedValueA.localeCompare(nestedValueB)
          }
        } else {
          if (typeof (a[sorting.key] ?? "-") === "number" && typeof (b[sorting.key] ?? "-") === "number") {
            const valueA = (a[sorting.key] ?? 0);
            const valueB = (b[sorting.key] ?? 0);
            return valueA - valueB
          }
          return (a[sorting.key] ?? "-").localeCompare((b[sorting.key] ?? "-"))
        }
      })

      setComponentList(sorting.aesOrder ? _componentList : _componentList.reverse())
    } else {
      setComponentList(components);
    }

  }, [sorting])

  const groupWorkerList = (sorting.key.length > 0 ? componentList : componentList)?.map((value, index) => {
    return index % CHUNK_SIZE === 0 ?
      (sorting.key.length > 0 ? (componentList ?? []) : componentList).slice(index, index + CHUNK_SIZE) : null
  }).filter((e) => { return e });

  const setSortingHandle = (key: string) => {
    var aesOrder = !sorting.aesOrder;

    if (key !== sorting.key) {
      aesOrder = true;
    }
    setSorting({
      key: key,
      aesOrder: aesOrder
    })
  }

  const getSortArrowByKey = (key: string) => {
    if (key === sorting.key) {
      return !sorting.aesOrder;
    }
    return false;
  }

  return (
    <div className='paging-container-div' style={{ width: '100%' }}>
      <div style={{
        paddingLeft: '30px',
        paddingRight: '30px',
        display: 'flex',
        width: '100%'
      }}
      >
        {displayItemId && (
          <div
            style={{
              flex: "2",
            }}
            onClick={() => setSortingHandle("micId")}
          >
            <ArrowLabel title={'Item ID'} isUp={getSortArrowByKey("micId")} />
          </div>
        )}
        <div
          style={{
            flex: "3",
          }}
          onClick={() => setSortingHandle("contractor")}
        >
          <ArrowLabel title={'Contractor'} isUp={getSortArrowByKey("contractor")} />
        </div>
        <div
          style={{
            flex: "2",
          }}
          onClick={() => setSortingHandle("micType")}
        >
          <ArrowLabel title={'Type'} isUp={getSortArrowByKey("micType")} />
        </div>
        <div
          style={{
            flex: "2",
          }}
          onClick={() => setSortingHandle("status")}
        >
          <ArrowLabel title={'Status'} isUp={getSortArrowByKey("status")} />
        </div>
      </div>
      <div style={{ height: '11px' }} />
      <div className='div-spacing-zone-line' />
      <div className='paging-div-full-content'>
        {(groupWorkerList[pageIndex - 1] ?? [])?.map((value, index) => (
          <ComponentRow
            key={index}
            index={index}
            component={value}
            displayItemId={displayItemId}
          />
        ))}
      </div>
      <div className='div-spacing-zone-line' />
      <Paging
        pageSize={groupWorkerList?.length ?? 1}
        onPageClick={(index) => {
          setpageIndex(index);
        }}
      />
    </div >
  );
}

type ComponentRowProps = {
  index: number
  component: MicComponent
  displayItemId: boolean
}

const ComponentRow = ({ index, component, displayItemId }: ComponentRowProps) => {
  const { t } = useTranslation()
  const getComponentStatus = (micComponent: MicComponent): string | null => {
    if (micComponent.status === 'InAssembling') {
      return t("home_mimep_in_assembling");
    }
    if (micComponent.status === 'NotYetInstalled') {
      return t("home_mimep_not_yet_installed");
    }
    if (micComponent.status === 'Completed') {
      return t("home_mimep_completed");
    }
    return null;
  }
  const textColor = component.status === 'Completed' ? `rgb(56, 224, 83)` : undefined;
  return (
    <div
      key={index}
      style={{
        paddingTop: '13px',
        paddingBottom: '13px',
        display: 'flex',
      }}
    >
      {displayItemId && (
        <div
          style={{
            flex: "2",
            whiteSpace: 'pre-line',
            overflowWrap: 'anywhere',
          }}
        >
          <NormalLabel title={component.micId || '-'} textColor={textColor} />
        </div>
      )}
      <div
        style={{
          flex: "3",
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere',
        }}
      >
        <NormalLabel title={component.contractor || '-'} textColor={textColor} />
      </div>
      <div
        style={{
          flex: "2",
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere',
        }}
      >
        <NormalLabel title={component.micType} textColor={textColor} />
      </div>
      <div
        style={{
          flex: "2",
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere',
        }}
      >
        <NormalLabel title={getComponentStatus(component) || '-'} textColor={textColor} />
      </div>
    </div >
  )
}

export default MicZoneComponentsContent;