import React, { useState, useEffect } from 'react'
import WeatherImage from '../assests/images/weather-image.png';
import { WeatherModel } from '../model/home/homePageModel';
import { AppService } from '../services/appService';

const Weather: React.FC<{
	appService: AppService
}> = ({ appService }) => {

	const [weather, setWeather] = useState<WeatherModel>();

	const temperature = "";

	var [didInit, setDidInit] = useState(false);


	useEffect(() => {
		if (!didInit) {
			getWeather();
			setDidInit(true);
		}
	}, [])

	const getWeather = () => {
		appService.dashboardService
			.getWeather()
			.subscribe(
				(value: any) => {
					setWeather(value.data.data)
				},
				(error) => {

				}
			)
	}

	return (
		<div className='weather-div'>
			<img
				src={
					((weather?.icons?.length ?? 0) > 0
						&& (weather?.icons[0]?.length ?? 0) > 0) ?
						weather!.icons[0] : WeatherImage
				}
				className='weather-image'
				alt='weather image' />
			<label className='normal-label' style={{ fontSize: '16' }}>
				{weather?.temperature != null ? weather.temperature : temperature}°C
			</label>
		</div>
	);
}

export default Weather