import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ButtonModel from '../components/button/buttonModel';
import TotalWorker from '../components/home/totalWorker';
import AlertTable from '../components/home/alertTable';
import WorkerInZone from '../components/home/workerInZone';
import { DashbardDetail, SiteMapDetail, Zone, GroupedPresentWorkers } from '../model/home/homePageModel';
import ZoneList from '../components/home/zoneList';
import WorkInZoneDetail from '../components/home/workInZoneDetail';
import ZoneDetail from '../components/home/zoneDetail';
import AlertTableDetail from '../components/home/alertTableDetail';
import FilterDetail from '../components/home/filterDetail';
import { useSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import { showFilter } from '../redux/viewSlice';
import { AppService } from '../services/appService';
import { useNavigate } from 'react-router-dom';
import { ControlZone, ProjectInfo } from '../model/project/projectModel';
import { HandleLogout } from '../services/authenticationService';
import { ProjectFilter } from '../redux/filterSlice';
import axios from 'axios';
import ZoomPanSitemap from '../components/home/zoom-pan-sitemap';
import { MessageDialog } from '../components/dialog';
import { getZoneAsOfTime } from '../extension/dateExtension';
import { AlertTableModel } from '../model/alertTable/alertTableModel';
import { SampleAlertTableList, SampleControlZoneList, SampleNormalZoneList } from '../testing/sampleData';

enum ContentType {
	normal,
	siteMap,
	zoneDetail,
	alertTable,
	workerInZoneDetail,
	workerInZoneDetailSec,
}

const HomeDemo: React.FC<{ appService: AppService }> = ({ appService }) => {

	const [isAlertWarning, setIsAlertWarning] = useState(false);
	const [open, setOpen] = useState(false);

	const [dashboardDetail, setDashboardDetail] = useState<DashbardDetail>();
	// const [normalZoneList, setNormalZoneList] = useState<Zone[]>();
	// const [controlZoneDetail, setControlZoneDetail] = useState<ControlZone[]>();
	const [alertTableList, setAlertTableList] = useState<AlertTableModel>(SampleAlertTableList);

	const [contentType, setContentType] = useState<ContentType>();
	const [selectedZone, setSelectedZone] = useState<Zone | ControlZone>();
	const filterModel = (useSelector((state: any) => state.filter.projectFilter)) as ProjectFilter;

	const isShowFilter = useSelector((state: any) => state.view.showFilter);
	const isShowFilterHideOption = useSelector((state: any) => state.view.showFilterHideOption);
	const project = useSelector((state: any) => state.user.project as ProjectInfo);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const controller = new AbortController();

	let CancelToken = axios.CancelToken;
	let source = CancelToken.source();

	const [isFiltering, setIsFiltering] = useState<boolean>(false);
	const [siteMapDetail, setSiteMapDetail] = useState<SiteMapDetail>();

	// var isRefreshingToken = false

	const [staticAsOf, setStaticAsOf] = useState<string>();
	const [normalZoneAsOf, setNormalZoneAsOf] = useState<string>();
	const [controlZoneAsOf, setControlZoneAsOf] = useState<string>();

	useEffect(() => {
		if (project === null || project.projectID === null) {
			HandleLogout(dispatch, navigate)
			return
		}
		if (isFiltering === false) {
			setContentType(ContentType.siteMap)
			setSelectedZone(undefined)
		}
		setStaticAsOf(getZoneAsOfTime(new Date()))
		setNormalZoneAsOf(getZoneAsOfTime(new Date()))
		getSiteMapInfo();

		const timer = setInterval(() => {
			setStaticAsOf(getZoneAsOfTime(new Date()))
			setNormalZoneAsOf(getZoneAsOfTime(new Date()))
		}, 1000 * 15);

		const controlZoneTimer = setInterval(() => {
			setControlZoneAsOf(getZoneAsOfTime(new Date()))
		}, 1000 * 5);

		return () => {
			source.cancel('Cancelled request');
			clearInterval(timer);
			clearInterval(controlZoneTimer);
		};

	}, [project, filterModel]);

	const getSiteMapInfo = async () => {
		const url = process.env.REACT_APP_CMS_API_URL + '/project/:projectId/sitemap-annotations';
		await axios.get(
			url.replace(':projectId', project.projectID),
			{
				withCredentials: false
			}
		).then((res) => {
			setSiteMapDetail(res.data.data);
		}).catch((err) => {
			// console.log(err);
		})
	}

	const handleClose = () => {
		setOpen(false);
	};

	const workerInZoneFirst: ButtonModel = {
		title: '顯示全部',
		onClick: () => {
			dispatch(showFilter(false));
			setContentType(ContentType.workerInZoneDetail)
		},
		style: {
			height: '40px',
		}
	}

	const bottomZoneGroup = (zoneList: Zone[]) => (
		<div className='home-bottom-zone-div'>
			<ZoneList
				title={'工作區域'}
				selectedZone={selectedZone}
				zoneList={zoneList}
				lastUpdateTime={normalZoneAsOf ?? ""}
				isControlZone={false}
				onItemClickHandle={(value: any) => {
					dispatch(showFilter(false));
					setSelectedZone(value)
					setContentType(ContentType.zoneDetail)
				}}
			/>
		</div>
	)
	const bottomRestrictZoneGroup = (zoneList: ControlZone[]) => (
		<div className='home-bottom-zone-div'>
			<ZoneList
				title={'限制區域'}
				selectedZone={undefined}
				controlZoneList={zoneList}
				lastUpdateTime={controlZoneAsOf ?? ""}
				isControlZone={true}
				onItemClickHandle={(value) => {
					dispatch(showFilter(false));
					setSelectedZone(value)
					setContentType(ContentType.zoneDetail)
				}} />
		</div>
	)

	const mainContent = (innerContent?: JSX.Element) => {
		const zoneList = SampleNormalZoneList ?? [];
		const _controlZoneDetail = SampleControlZoneList ?? [];
		return (
			<div className='home-bottom-section-div'>
				{bottomZoneGroup(zoneList)}
				{bottomRestrictZoneGroup(_controlZoneDetail)}
				{innerContent}
			</div>
		);
	}

	const _setSelectZone = (zoneName: string, zoneList: (ControlZone | Zone)[]) => {
		const result = zoneList.filter(e => {
			const zone = (e as ControlZone);
			if (zone !== null && zone.zone === zoneName) {
				return e
			} else {
				const normalZone = e as Zone
				if (normalZone.name === zoneName) {
					return e
				}
			}
		})
		if (result.length > 0) {
			setSelectedZone(result[0]);
		}
	}

	const siteMap = (): JSX.Element => {
		const normalZone = SampleNormalZoneList ?? [];
		const controlZone = SampleControlZoneList ?? [];
		const _zoneList = [...normalZone, ...controlZone];
		const imgUrl = (siteMapDetail?.sitemapImage != null) ? siteMapDetail?.sitemapImage.url : '';
		return (
			<div className='home-bottom-site-map-div'>
				<ZoomPanSitemap
					zones={normalZone}
					controlZone={controlZone}
					imageUrl={imgUrl}
					annotations={siteMapDetail?.sitemapAnnotations ?? []}
					onClickImage={(coordinate) => {
						// console.log(coordinate)
					}}
					onClickAnnotationOfIndex={(index, zoneName) => {
						_setSelectZone(zoneName, _zoneList)
						setContentType(ContentType.zoneDetail)
					}}
				/>
			</div>
		);
	}

	const filterContent = (hideOption: boolean): JSX.Element => {
		return (
			<div className='full-size-div'>
				<FilterDetail
					appService={appService}
					projectId={project.projectID}
					hideOption={hideOption}
					confirmCallBack={() => {
						dispatch(showFilter(false));
						setIsFiltering(true);
						if ((selectedZone === null || selectedZone === undefined)
							&& (dashboardDetail?.zones?.length ?? 0) > 0) {
							setSelectedZone(dashboardDetail?.zones[0])
						}
						setContentType(ContentType.siteMap);
					}}
					closeCallBack={() => {
						dispatch(showFilter(false));
						setContentType(ContentType.siteMap)
					}} />
			</div>
		);
	}

	const workInZone = (): JSX.Element => {
		const list: GroupedPresentWorkers[] = [];
		if (dashboardDetail?.presentWorkers[0] !== null) {
			dashboardDetail?.presentWorkers[0].contractors.forEach(e => {
				list.push({
					contractor: e.contractor,
					morningContractors: e,
				})
			})
		}

		if (dashboardDetail?.presentWorkers[1] !== null) {
			dashboardDetail?.presentWorkers[1].contractors.forEach(e => {
				const index = list.findIndex(v => v.contractor === e.contractor)
				console.log(`check index ${index}`)
				if (index !== -1) {
					list[index].afternoonContractors = e
				} else {
					list.push({
						contractor: e.contractor,
						afternoonContractors: e,
					})
				}
			})
		}

		return (
			<div className='full-size-div'>
				<WorkInZoneDetail
					groupedList={list}
					closeCallBack={() => {
						setContentType(ContentType.siteMap)
					}}
				/>
			</div>
		);
	}

	const alertDetail = (): JSX.Element => {
		return (
			<div className='full-size-div'>
				<AlertTableDetail
					appService={appService}
					projectId={project.projectID}
					projectUid={project.id}
					isAlertWarning={isAlertWarning}
					isDemo={true}
					deleteAllMessageHandle={() => {

					}}
					closeCallBack={() => {
						setContentType(ContentType.siteMap)
					}} />
			</div>
		);
	}

	const zoneDetail = (detail?: Zone | ControlZone, isControlZone: boolean = false): JSX.Element => (
		<div >
			<ZoneDetail
				appService={appService}
				projectId={project.projectID}
				projectUId={project.id}
				detail={detail}
				isDemo={true}
				closeCallBack={() => {
					setContentType(ContentType.siteMap)
				}}
				isControlZone={isControlZone}
				isMuted={false}
				muteToggleHandle={() => { }}
			/>
		</div >
	)

	const renderContent = (): JSX.Element => {
		switch (contentType) {
			case ContentType.siteMap:
				return mainContent(siteMap());
			case ContentType.zoneDetail:
				var _isControlZone = false
				const _controlZone = (selectedZone as ControlZone)
				if (_controlZone !== null) {
					_isControlZone = _controlZone?.authorizedWorkers !== null && _controlZone?.unauthorizedWorkers != null
				}
				// console.log(_isControlZone)
				return zoneDetail(selectedZone, _isControlZone);
			case ContentType.alertTable:
				return mainContent(alertDetail());
		}
		return mainContent(siteMap());
	}

	const isShowNormalContent = (): boolean => {
		return (!isShowFilter
			&& contentType !== ContentType.workerInZoneDetail
			&& contentType !== ContentType.workerInZoneDetailSec)
	}

	return (
		<div className='main-container'>
			<Grid className='home-grid'>
				<div className='home-top-section-div'>
					<div className='home-top-total-woker-div'>
						<TotalWorker
							workCount={`150`}
							lastUpdateTime={staticAsOf ?? ""}
						/>
					</div>
					<div className='home-top-woker-in-zone-div'>
						<WorkerInZone
							time={"09:15am"}
							count={150}
							btnModel={workerInZoneFirst}
							showButton={true}
						/>
						<div style={{ height: '10px' }} />
						<WorkerInZone
							time={"02:15pm"}
							count={150}
							showButton={false}
						/>
					</div>
					<div className='home-top-alert-table-div'>
						<AlertTable
							messageList={(alertTableList?.data ?? []).slice(0, 2)}
							isAlertWarning={isAlertWarning}
							showAllCallBack={() => {
								dispatch(showFilter(false));
								setContentType(ContentType.alertTable)
							}}
							mutedCount={0}
						/>
					</div>
				</div>
				{
					isShowFilter ? mainContent(filterContent(true)) :
						isShowFilterHideOption ? mainContent(filterContent(false)) :
							(contentType === ContentType.workerInZoneDetail) ? mainContent(workInZone()) :
								(isShowNormalContent()) ? renderContent() : null
				}

			</Grid >
			<MessageDialog
				dialogProps={{
					title: '出現錯誤',
					content: '出現錯誤，請稍後再試',
					btnTitle: '確定',
					open: open,
					handleClose: handleClose
				}}
			/>
		</div >
	);
}

export default HomeDemo;