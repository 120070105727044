import { Dispatch } from 'redux';
import {
  updateGisLoading,
  updateIsGis,
  updateMapGeoFence,
  updateMapProjectConfig,
  updateMapWorker,
  updateMapZone
} from '../../redux/gisMap/gisMapSlice';
import { AppService } from '../appService';
import { CmpAdminProjectConfigRes, CommonApiRes, RealTimeMapInfoRes } from './gisMapApiModel';
import { MOCK_GEO_FENCES_DATA, MOCK_WORKERS_DATA, MOCK_ZONES_DATA } from './gisMapMockData';

const gisMapService = (service: AppService) => ({
  fetchIsGisByProjectId: (dispatch: Dispatch, projectID: string) => {
    dispatch(updateGisLoading(true));

    if (process.env.REACT_APP_ENABLE_GIS_MAP_MOCK_DATA) {
      setTimeout(() => {
        dispatch(updateIsGis({ isGIS: true }));
      }, 2000);
      dispatch(updateGisLoading(false));
      return;
    }

    service.request('get', `projects/config/cmpAdmin?projectId=${projectID}`).subscribe(
      (value: any) => {
        const { data } = value.data as CommonApiRes<CmpAdminProjectConfigRes>;
        dispatch(updateIsGis(data.isGIS));
        dispatch(updateMapProjectConfig(data));
        dispatch(updateGisLoading(false));
      },
      (error) => dispatch(updateGisLoading(false))
    );
  },

  fetchMapByProjectId: (dispatch: Dispatch, projectID: string) => {
    dispatch(updateGisLoading(true));

    if (process.env.REACT_APP_ENABLE_GIS_MAP_MOCK_DATA) {
      setTimeout(() => {
        dispatch(updateMapWorker(MOCK_WORKERS_DATA));
        dispatch(updateMapZone(MOCK_ZONES_DATA));
        dispatch(updateMapGeoFence(MOCK_GEO_FENCES_DATA));
        dispatch(updateGisLoading(false));
      }, 2000);
      return;
    }

    service.request('get', `map/realTimeMapInfo?projectId=${projectID}`).subscribe(
      (value: any) => {
        const { data } = value.data as CommonApiRes<RealTimeMapInfoRes>;
        const { worker, zone, geoFence } = data;

        dispatch(updateMapWorker(worker));
        dispatch(updateMapZone(zone));
        dispatch(updateMapGeoFence(geoFence));

        dispatch(updateGisLoading(false));
      },
      (error) => dispatch(updateGisLoading(false))
    );
  },

  mockUpdateMapByProjectId: (dispatch: Dispatch) => {
    dispatch(
      updateMapWorker([
        {
          id: 'gis-test-worker',
          nameEng: 'gis-test-worker',
          nameZht: '',
          trade: 'General Worker',
          contractor: '',
          lastUpdated: '2024-03-06T01:15:49.512Z',
          lat: 22.448035,
          long: 114.114379,
          isTemper: false,
          isHeartRate: false,
          isSos: false,
          isAlert: false,
          isLowBattery: false
        }
      ])
    );
  }
});

export default gisMapService;
