import { i18n, TFunction } from "i18next";
import { useState } from "react";
import { CHUNK_SIZE, FILTER_HUMAN } from "../../../model/constants";
import { ZoneItemDetail } from "../../../model/project/projectModel";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";

export const MechanicalContent: React.FC<{
	isControlZone: Boolean,
	filterType: string,
	mechanicalList: ZoneItemDetail[] | undefined,
	t: TFunction<"translation">,
	i18n: i18n,
}> = ({ mechanicalList, filterType, isControlZone, t, i18n }) => {

	const [pageIndex, setpageIndex] = useState(1);

	const groupMechanicalList = mechanicalList?.map((value, index) => {
		return index % CHUNK_SIZE === 0 ?
			mechanicalList.slice(index, index + CHUNK_SIZE) : null
	}).filter((e) => { return e }) ?? [];

	const isEng = i18n.language.includes("en")

	return (
		<div className='paging-container-div' style={{ width: '100%' }}>
			<div className='zone-detail-table-header'>
				{
					isControlZone ? <>
						<div style={{ flex: '2' }}>
							<NormalLabel
								title={
									filterType === FILTER_HUMAN ?
										t('home_zone_detail_name') :
										t('home_controlled_zone_detail_mechine_operator_name')}
							/>
						</div>
						<div style={{ flex: '3' }}>
							<NormalLabel
								title={
									filterType === FILTER_HUMAN ?
										t('home_contractor') :
										t('home_controlled_zone_detail_mechine_contractor')}
							/>
						</div>
						<div style={{ flex: '1' }}>
							<NormalLabel title={t('home_zone_detail_worker_age')} />
						</div>
						<div style={{ flex: '1' }}>
							<NormalLabel title={t('home_trade')} />
						</div>
						<div style={{ flex: '1' }}>
							<NormalLabel title={filterType === FILTER_HUMAN ?
								t('home_zone_authorisation') :
								t('home_controlled_zone_detail_mechine_operte_permit')}
							/>
						</div>
					</> : <>
						<div style={{ flex: '2' }}>
							<NormalLabel title={t('home_zone_detail_name')} />
						</div>
						<div style={{ flex: '1' }}>
							<NormalLabel title={t('home_machine_type')} />
						</div>
						<div style={{ flex: '5' }}>
							<NormalLabel title={t('home_contractor')} />
						</div>
					</>
				}

			</div>
			<div style={{ height: '11px' }} />
			<div className='div-spacing-zone-line' />
			<div className='paging-div-full-content'>
				{
					(groupMechanicalList[pageIndex - 1] ?? [])?.map((value, index) => {
						if (isControlZone) {
							const textColor = value.isAuth ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 143, 13, 1)';
							return (
								<div key={index} style={{
									paddingTop: '16px',
									paddingBottom: '16px',
									display: 'flex',
									width: '100%',
								}}>
									<div style={{
										flex: '2',
										whiteSpace: 'pre-line',
										overflowWrap: 'anywhere',
									}}>
										<NormalLabel title={isEng ? value.workerNameEn : value.workerNameZh ?? ""} textColor={textColor} />
									</div>
									<div style={{
										flex: '3',
										whiteSpace: isControlZone ? 'normal' : 'pre-line',
										overflowWrap: 'anywhere',
									}}>
										<NormalLabel title={isEng ? value.contractorEn : value.contractorZh ?? ""} textColor={textColor} />
									</div>
									<div style={{ flex: '1' }}>
										<NormalLabel title={value.age ?? ""} textColor={textColor} />
									</div>
									<div style={{ flex: '1' }}>
										<NormalLabel title={value.trade ?? ""} textColor={textColor} />
									</div>
									<div style={{ flex: '1' }}>
										<NormalLabel
											title={value.isAuth ? t(filterType === FILTER_HUMAN ?
												"home_controlled_zone_authorised" :
												'home_controlled_zone_detail_mechine_operte_permit_allow') :
												t(filterType === FILTER_HUMAN ?
													"home_controlled_zone_unauthorised" :
													"home_controlled_zone_detail_mechine_operte_permit_deny")}
											textColor={textColor}
										/>
									</div>
								</div>
							)
						} else {
							return (
								<div key={index} style={{ paddingTop: '16px', paddingBottom: '16px', display: 'flex', width: '100%' }}>
									<div style={{
										flex: '2',
										whiteSpace: 'pre-line',
										overflowWrap: 'anywhere',
									}}>
										<NormalLabel title={isEng ? value.workerNameEn : value.workerNameZh ?? ""} />
									</div>
									<div style={{ flex: '1' }}>
										<NormalLabel title={value.trade ?? ""} />
									</div>
									<div style={{
										flex: '5',
										whiteSpace: isControlZone ? 'normal' : 'pre-line',
										overflowWrap: 'anywhere',
									}}>
										<NormalLabel title={isEng ? value.contractorEn : value.contractorZh ?? ""} />
									</div>
								</div>
							)
						}

					})
				}
			</div>
			<div className='div-spacing-zone-line' />
			<Paging
				pageSize={groupMechanicalList?.length ?? 0}
				onPageClick={(index) => {
					setpageIndex(index)
				}}
			/>
		</div >
	);
}