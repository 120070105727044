import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ButtonModel from '../components/button/buttonModel';
import TotalWorker from '../components/home/totalWorker';
import AlertTable from '../components/home/alertTable';
import WorkerInZone from '../components/home/workerInZone';
import {
  DashbardDetail,
  SiteMapDetail,
  Zone,
  GroupedPresentWorkers,
  ZonerStatus,
  WorkerWatchModel
} from '../model/home/homePageModel';
import ZoneList from '../components/home/zoneList';
import WorkInZoneDetail from '../components/home/workInZoneDetail';
import ZoneDetail from '../components/home/zoneDetail';
import AlertTableDetail from '../components/home/alertTableDetail';
import FilterDetail from '../components/home/filterDetail';
import { useSelector } from '../redux/store';
import { useDispatch } from 'react-redux';
import { showFilter, showFilterHideOption, showLoading, showMic } from '../redux/viewSlice';
import { AppService } from '../services/appService';
import { useNavigate } from 'react-router-dom';
import { ControlZone } from '../model/project/projectModel';
import { HandleLogout, RefreshToken } from '../services/authenticationService';
import { ProjectFilter } from '../redux/filterSlice';
import axios from 'axios';
import ZoomPanSitemap from '../components/home/zoom-pan-sitemap';
import { MessageDialog } from '../components/dialog';
import { getZoneAsOfTime } from '../extension/dateExtension';
import { AlertTableModel } from '../model/alertTable/alertTableModel';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { FILTER_HEALTH, FILTER_HUMAN, FILTER_MANCHINE } from '../model/constants';
import AlertOverview from '../components/home/alertOverview';
import AiCamZoneList from '../components/home/aiCamZoneList';
import PermitZoneList from '../components/home/permitZoneList';
import GasSensorZoneList from '../components/home/gasSensorZoneList';
import ELockZoneList from '../components/home/eLockZoneList';
import TotalMic from '../components/home/totalMic';
import MicTracking from '../components/home/micTracking';
import { MicSummary } from '../model/MicSummary';
import MicZoneList from '../components/home/micZoneList';
import MicZoneDetail from '../components/home/micZoneDetail';
import { GisMap } from '../components/map/gisMap';
import { selectMapProjectIsGis } from '../redux/gisMap/gisMapSelector';
// import { SampleAlertTableList } from '../testing/sampleData';

enum ContentType {
  normal,
  siteMap,
  zoneDetail,
  alertTable,
  workerInZoneDetail,
  workerInZoneDetailSec,
  mic
}

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

const Home: React.FC<{ appService: AppService }> = ({ appService }) => {
  const { t } = useTranslation();

  const [isAlertWarning, setIsAlertWarning] = useState(false);
  const [isFiltierAlertWarning, setIsFiltierAlertWarning] = useState(false);
  const [isAlertTypeWarning, setIsAlertTypeWarning] = useState(false);
  const [open, setOpen] = useState(false);

  const [dashboardDetail, setDashboardDetail] = useState<DashbardDetail>();
  const [micSummary, setMicSummary] = useState<MicSummary | null>();
  const [normalZoneList, setNormalZoneList] = useState<Zone[]>();
  const [controlZoneDetail, setControlZoneDetail] = useState<ControlZone[]>();
  const [alertTableList, setAlertTableList] = useState<AlertTableModel>();
  const [alertOverview, setAlertOverview] = useState<any>();
  const [permitOverview, setPermitOverview] = useState<any>();
  const [aiCamAlertOverview, setAiCamAlertOverview] = useState<any>();
  const [gasSensorOverview, setGasSensorOverview] = useState<any>();
  const [gasSensors, setGasSensors] = useState<any>();
  const [envSensors, setEnvSensors] = useState<any>();
  const [eLockOverview, setELockOverview] = useState<any>();
  const [workerWatch, setWorkerWatch] = useState<WorkerWatchModel[]>();

  const [contentType, setContentType] = useState<ContentType>();
  const [selectedZone, setSelectedZone] = useState<Zone | ControlZone>();
  const [selectedMicZoneName, setSelectedMicZoneName] = useState<string | null>();
  const [zonerStatusList, setZonerStatusList] = useState<ZonerStatus[]>();
  const [validAdminPanel, setValidAdminPanel] = useState<boolean>(false);

  const [mainContentViewType, setMainContentViewType] = useState<string>('default');

  const filterModel = useSelector((state: any) => state.filter.projectFilter) as ProjectFilter;
  const isShowMic = useSelector((state: any) => state.view.showMic);

  const isShowFilter = useSelector((state: any) => state.view.showFilter);
  const isShowFilterHideOption = useSelector((state: any) => state.view.showFilterHideOption);
  const isShowZeroCountZones = useSelector((state: any) => state.view.showZeroCountZones);
  const project = useSelector((state) => state.user.project);
  const isGis = useSelector(selectMapProjectIsGis);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const controller = new AbortController();

  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [siteMapDetail, setSiteMapDetail] = useState<SiteMapDetail>();

  let isRefreshingToken = false;

  const [staticAsOf, setStaticAsOf] = useState<string>();
  const [normalZoneAsOf, setNormalZoneAsOf] = useState<string>();
  const [controlZoneAsOf, setControlZoneAsOf] = useState<string>();

  // const storedMqttClient = useRef<MqttClient>()
  //
  // useEffect(() => {
  // 	if (project != null && project.projectID != null) {
  // 		const mqttClient = connect(`mqtt://${process.env.REACT_APP_CMP_MQTT_HOST}`, {
  // 			port: parseInt(process.env.REACT_APP_CMP_MQTT_PORT!),
  // 			username: process.env.REACT_APP_CMP_MQTT_USER,
  // 			password: process.env.REACT_APP_CMP_MQTT_PASSWORD,
  // 		})
  // 		mqttClient.on('connect', function () {
  // 			console.log('MQTT - Connected');
  //
  // 			const topic = `out/aicam/cmp/${project.projectID}`;
  // 			mqttClient.subscribe(topic, function (err) {
  // 				if (!err) {
  // 					console.log(`MQTT - Subscribed ${topic}`);
  // 				} else {
  // 					console.log(`MQTT - Failed to subscribe ${topic}`);
  // 				}
  // 			});
  // 		});
  // 		storedMqttClient.current = mqttClient;
  // 	}
  // 	return () => {
  // 		storedMqttClient.current?.end(true)
  // 	};
  // }, [project])

  useEffect(() => {
    dispatch(showMic(false));
  }, []);

  useEffect(() => {
    if (!isShowMic) {
      setSelectedMicZoneName(null);
    }
  }, [isShowMic]);

  useEffect(() => {
    if (project === null || project.projectID === null) {
      HandleLogout(dispatch, navigate);
      return;
    }
    if (isFiltering === false) {
      setContentType(ContentType.siteMap);
      setSelectedZone(undefined);
    }

    getZoneList();
    getControlZoneDetail();
    getControlZoneAlertStatus(); // get different filter control zone info
    getDashboardData(true);
    getMicSummary();
    getAlertTable();
    getAlertOverview();
    getPermitOverview();
    getAiCamAlertOverview();
    getGasSensorOverview();
    getGasSensors();
    getEnvSensors();
    getELockOverview();
    getWorkerWatch();
    getSiteMapInfo();
    getZonerStatus();
    getAdminPanelValid();

    const timer5s = setInterval(() => {
      getControlZoneDetail();
      getControlZoneAlertStatus();
    }, 1000 * 5);

    const timer15s = setInterval(() => {
      getZoneList();
      getZonerStatus();
      getAlertTable();
      getAlertOverview();
      getPermitOverview();
      getAiCamAlertOverview();
      getGasSensorOverview();
      getGasSensors();
      getEnvSensors();
      getELockOverview();
      getWorkerWatch();
    }, 1000 * 15);

    const timer60s = setInterval(() => {
      getDashboardData(false);
      getMicSummary();
    }, 1000 * 60);

    return () => {
      source.cancel('Cancelled request');
      clearInterval(timer5s);
      clearInterval(timer15s);
      clearInterval(timer60s);
    };
  }, [project, filterModel]);

  useEffect(() => {
    if (project?.projectID) {
      appService.gisMapServices.fetchIsGisByProjectId(dispatch, project.projectID);
    }
  }, [appService.gisMapServices, dispatch, project]);

  const getSiteMapInfo = async () => {
    const url = process.env.REACT_APP_CMS_API_URL + '/project/:projectId/sitemap-annotations';
    await axios
      .get(url.replace(':projectId', project?.projectID ?? ''), {
        withCredentials: false
      })
      .then((res) => {
        setSiteMapDetail(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getAdminPanelValid = async () => {
    const url = process.env.REACT_APP_ADMIN_PANEL_URL_API + '/admin-users/validate';
    await axios
      .post(url, {
        projectID: project?.projectID,
        username: localStorage.getItem('uid')
      })
      .then((res) => {
        setValidAdminPanel(res.data.data.valid);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getDashboardData = (_showLoading: boolean = false) => {
    controller.abort();
    dispatch(showLoading(_showLoading));
    appService.dashboardService
      .fetchData(
        {
          projectID: project?.projectID,
          projectUID: project?.id,
          contractorID: filterModel.constructorUid !== 'all' ? filterModel.constructorUid : null,
          contractor: filterModel.constructorCode !== 'all' ? filterModel.constructorCode : null,
          trade: filterModel.type !== '顯示全部' ? filterModel.type : null
        },
        source.token
      )
      .subscribe(
        (value: any) => {
          if (_showLoading === true) {
            dispatch(showLoading(false));
          }
          if (isFiltering) {
            setIsFiltering(false);
          }
          setDashboardDetail(value.data.data);
          setStaticAsOf(getZoneAsOfTime(new Date()));
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getMicSummary = () => {
    appService.dashboardService
      .getMicSummary({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setMicSummary(value.data.data);
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getZoneList = () => {
    appService.dashboardService
      .getZoneDetail({
        projectID: project?.projectID,
        projectUID: project?.id,
        contractorID: filterModel.constructorUid !== 'all' ? filterModel.constructorUid : null,
        contractor: filterModel.constructorCode !== 'all' ? filterModel.constructorCode : null,
        zones: null,
        trade: filterModel.type !== '顯示全部' ? filterModel.type : null,
        workerType:
          filterModel.filterType === FILTER_HUMAN || filterModel.filterType === FILTER_HEALTH
            ? 'human'
            : 'machine'
      })
      .subscribe(
        (value: any) => {
          setNormalZoneList(value.data.data.zones);
          setNormalZoneAsOf(getZoneAsOfTime(new Date()));
        },
        (error) => {
          RefreshToken(appService, error.httpStatusCode, dispatch, navigate, () => {
            dispatch(showLoading(false));
            getZoneList();
          });
        }
      );
  };

  const getControlZoneDetail = () => {
    controller.abort();
    appService.dashboardService
      .getControlZone({
        projectID: project?.projectID,
        projectUID: project?.id,
        workerType:
          filterModel.filterType === FILTER_HUMAN || filterModel.filterType === FILTER_HEALTH
            ? 'human'
            : 'machine'
      })
      .subscribe(
        (value: any) => {
          setControlZoneDetail(value.data.data);

          setControlZoneAsOf(getZoneAsOfTime(new Date()));
          if (
            (value.data.data?.filter((e: any) => e.unauthorizedWorkers.count > 0) ?? []).length > 0
          ) {
            setIsAlertWarning(true);
            getAlertTable();
          } else {
            setIsAlertWarning(false);
          }
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getControlZoneAlertStatus = () => {
    controller.abort();
    appService.dashboardService
      .getControlZone({
        projectID: project?.projectID,
        projectUID: project?.id,
        workerType:
          filterModel.filterType === FILTER_HUMAN || filterModel.filterType === FILTER_HEALTH
            ? 'machine'
            : 'human'
      })
      .subscribe(
        (value: any) => {
          if (
            (value.data.data?.filter((e: any) => e.unauthorizedWorkers.count > 0) ?? []).length > 0
          ) {
            setIsFiltierAlertWarning(true);
            getAlertTable();
          } else {
            setIsFiltierAlertWarning(false);
          }
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getAlertTable = () => {
    appService.dashboardService
      .getAlertTable({
        projectID: project?.projectID,
        page: 0,
        pageSize: 20
      })
      .subscribe(
        (value: any) => {
          console.log(value);
          setAlertTableList(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getZonerStatus = () => {
    appService.zonerStatusServices.getZonerStatus(project?.projectID ?? '').subscribe(
      (value: any) => {
        setZonerStatusList(value.data.data);
        if (isRefreshingToken) {
          isRefreshingToken = false;
        }
      },
      (error) => {
        if (!isRefreshingToken) {
          isRefreshingToken = true;
          refreshTokenHandle(error.httpStatusCode);
        }
      }
    );
  };

  const getAlertOverview = () => {
    appService.dashboardService
      .getTodayProjectAlertOverview({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setAlertOverview(value.data.data);

          console.log('ALERT OVERVIEW:', value.data.data);

          if (
            value.data.data.expiredPermit > 0 ||
            value.data.data.expiredMachine > 0 ||
            value.data.data.aiCamera > 0
          ) {
            setIsAlertTypeWarning(true);
          }

          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getPermitOverview = () => {
    appService.dashboardService
      .getTodayProjectPermitOverview({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setPermitOverview(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getAiCamAlertOverview = () => {
    appService.dashboardService
      .getTodayProjectAiCameraOverview({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setAiCamAlertOverview(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getGasSensorOverview = () => {
    appService.dashboardService
      .getTodayProjectGasSensorOverview({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setGasSensorOverview(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getGasSensors = () => {
    appService.dashboardService
      .getProjectGasSensor({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setGasSensors(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getEnvSensors = () => {
    appService.dashboardService
      .getProjectEnvSensor({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setEnvSensors(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const getELockOverview = () => {
    appService.dashboardService
      .getELockOverview({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setELockOverview(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetShowFilter = () => {
    dispatch(showFilterHideOption(false));
    dispatch(showFilter(false));
  };

  const getWorkerWatch = () => {
    appService.dashboardService
      .getWorkerWatch({
        projectID: project?.projectID
      })
      .subscribe(
        (value: any) => {
          setWorkerWatch(value.data.data);
          if (isRefreshingToken) {
            isRefreshingToken = false;
          }
        },
        (error) => {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            refreshTokenHandle(error.httpStatusCode);
          }
        }
      );
  };

  const refreshTokenHandle = (errorCode: number) => {
    RefreshToken(
      appService,
      errorCode,
      dispatch,
      navigate,
      () => {
        getZoneList();
        getControlZoneDetail();
        getAlertTable();
        getDashboardData(true);
        getMicSummary();
        getAlertOverview();
        getPermitOverview();
        getAiCamAlertOverview();
        getGasSensorOverview();
        getGasSensors();
        getEnvSensors();
        getELockOverview();
        getWorkerWatch();
      },
      () => {
        isRefreshingToken = false;
        setOpen(true);
        dispatch(showLoading(false));
      }
    );
  };

  const workerInZoneFirst: ButtonModel = {
    title: t('home_worker_present_show_all'),
    onClick: () => {
      resetShowFilter();
      setContentType(ContentType.workerInZoneDetail);
    },
    style: {
      height: '40px'
    }
  };

  const bottomZoneGroup = (zoneList: Zone[]) => (
    <div className="home-bottom-zone-div">
      <ZoneList
        contentTitle={t('home_worker_present')}
        contentType={'default'}
        title={t('home_working_zone')}
        selectedZone={selectedZone}
        zoneList={zoneList}
        lastUpdateTime={normalZoneAsOf ?? ''}
        isControlZone={false}
        onChangeViewType={setMainContentViewType}
        onItemClickHandle={(value: any) => {
          resetShowFilter();
          setSelectedZone(value);
          setContentType(ContentType.zoneDetail);
        }}
        mutedList={[]}
      />
    </div>
  );

  const bottomRestrictZoneGroup = (zoneList: ControlZone[]) => (
    <div className="home-bottom-zone-div">
      <ZoneList
        contentType={'default'}
        title={t(
          filterModel.filterType === FILTER_MANCHINE
            ? 'home_controlled_zone_mechine_title'
            : 'home_controlled_zone'
        )}
        selectedZone={undefined}
        controlZoneList={zoneList}
        lastUpdateTime={controlZoneAsOf ?? ''}
        isControlZone={true}
        onItemClickHandle={(value) => {
          resetShowFilter();
          setSelectedZone(value);
          setContentType(ContentType.zoneDetail);
        }}
        showAdminBtn={validAdminPanel}
        mutedList={(zonerStatusList ?? [])
          .filter((e) => e.status?.includes('OFF') ?? false)
          .map((e) => e.zone)}
      />
    </div>
  );

  const _setSelectZone = (zoneName: string, zoneList: (ControlZone | Zone)[]) => {
    console.log(zoneList);
    const result = zoneList.filter((e) => {
      const zone = e as ControlZone;
      if (zone != null && zone.zone === zoneName) {
        return e;
      } else {
        const normalZone = e as Zone;
        if (normalZone.name === zoneName) {
          return e;
        }
      }
    });
    if (result.length > 0) {
      setSelectedZone(result[0]);
    }
  };

  const siteMap = (): JSX.Element => {
    const normalZone = normalZoneList ?? [];
    const controlZone = controlZoneDetail ?? [];
    const _zoneList = [...normalZone, ...controlZone];
    const imgUrl = siteMapDetail?.sitemapImage != null ? siteMapDetail?.sitemapImage.url : '';

    return (
      <div className="home-bottom-site-map-div">
        {isGis ? (
          <GisMap
            appService={appService}
            onWorkerMarkerClick={(zoneName) => {
              if (!controlZoneDetail) return;

              const foundControlZone = controlZoneDetail.find((zone) => zone.zone === zoneName);
              if (foundControlZone) {
                resetShowFilter();
                setSelectedZone(foundControlZone);
                setContentType(ContentType.zoneDetail);
              } else {
                const foundNormalZone = normalZone.find((zone) => zone.name === zoneName);
                if (!foundNormalZone) return;
                resetShowFilter();
                setSelectedZone(foundNormalZone);
                setContentType(ContentType.zoneDetail);
              }
            }}
          />
        ) : (
          <ZoomPanSitemap
            mainContentViewType={mainContentViewType}
            permitOverview={permitOverview}
            aiCamAlertOverview={aiCamAlertOverview}
            gasSensorOverview={gasSensorOverview}
            eLockOverview={eLockOverview}
            micSummaryZones={micSummary?.zones}
            zones={normalZone}
            controlZone={controlZone}
            imageUrl={imgUrl}
            annotations={siteMapDetail?.sitemapAnnotations ?? []}
            onClickImage={(coordinate) => {
              // console.log(coordinate)
            }}
            onClickAnnotationOfIndex={(index, zoneName) => {
              if (isShowMic) {
                setSelectedMicZoneName(zoneName);
              }
              if (mainContentViewType !== 'default') {
                return;
              }
              console.log(`check ${zoneName}`);
              _setSelectZone(zoneName, _zoneList);
              setContentType(ContentType.zoneDetail);
            }}
          />
        )}
      </div>
    );
  };

  const mainContent = (innerContent?: JSX.Element) => {
    const zoneList = normalZoneList ?? [];
    const zoneListFiltered = isShowZeroCountZones
      ? zoneList
      : zoneList.filter((x) => (x.count ?? 0) > 0);
    const controlZoneList = controlZoneDetail ?? [];
    return (
      <div className="home-bottom-section-div">
        <div className="home-bottom-section-zone-div">
          <div className="home-bottom-section-zone-inner-div">
            {isShowMic ? (
              <MicZoneList
                micSummary={micSummary}
                zoneList={zoneList}
                controlZoneList={controlZoneList}
                isShowZeroCountZones={isShowZeroCountZones}
                onChangeViewType={setMainContentViewType}
                onSelectZoneName={(zoneName) => {
                  setSelectedMicZoneName(zoneName);
                  setContentType(ContentType.mic);
                }}
              />
            ) : (
              <>
                {mainContentViewType === 'default' && (
                  <>
                    {bottomZoneGroup(zoneListFiltered)}
                    {bottomRestrictZoneGroup(controlZoneList)}
                  </>
                )}
                {mainContentViewType === 'permit' && (
                  <PermitZoneList
                    appService={appService}
                    projectID={project?.projectID ?? ''}
                    permitOverview={permitOverview}
                    zoneList={zoneList}
                    controlZoneList={controlZoneList}
                    isShowZeroCountZones={isShowZeroCountZones}
                    onChangeViewType={setMainContentViewType}
                  />
                )}
                {mainContentViewType === 'ai-cam' && (
                  <AiCamZoneList
                    appService={appService}
                    projectID={project?.projectID ?? ''}
                    aiCamAlertOverview={aiCamAlertOverview}
                    zoneList={zoneList}
                    controlZoneList={controlZoneList}
                    isShowZeroCountZones={isShowZeroCountZones}
                    onChangeViewType={setMainContentViewType}
                  />
                )}
                {mainContentViewType === 'gas-sensor' && (
                  <GasSensorZoneList
                    appService={appService}
                    projectID={project?.projectID ?? ''}
                    gasSensorOverview={gasSensorOverview}
                    gasSensors={gasSensors}
                    envSensors={envSensors}
                    zoneList={zoneList}
                    controlZoneList={controlZoneList}
                    isShowZeroCountZones={isShowZeroCountZones}
                    onChangeViewType={setMainContentViewType}
                  />
                )}
                {mainContentViewType === 'e-lock' && (
                  <ELockZoneList
                    appService={appService}
                    projectID={project?.projectID ?? ''}
                    eLockOverview={eLockOverview}
                    zoneList={zoneList}
                    controlZoneList={controlZoneList}
                    isShowZeroCountZones={isShowZeroCountZones}
                    onChangeViewType={setMainContentViewType}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {innerContent}
      </div>
    );
  };

  const filterContent = (hideOption: boolean): JSX.Element => {
    return (
      <div className="full-size-div" style={{ flex: 8 }}>
        <FilterDetail
          appService={appService}
          projectId={project?.projectID ?? ''}
          hideOption={hideOption}
          confirmCallBack={() => {
            resetShowFilter();
            setIsFiltering(true);
            if (
              (selectedZone === null || selectedZone === undefined) &&
              (dashboardDetail?.zones?.length ?? 0) > 0
            ) {
              setSelectedZone(dashboardDetail?.zones[0]);
            }
            setContentType(ContentType.siteMap);
          }}
          closeCallBack={() => {
            resetShowFilter();
            setContentType(ContentType.siteMap);
          }}
        />
      </div>
    );
  };

  const workInZone = (): JSX.Element => {
    const list: GroupedPresentWorkers[] = [];
    if (dashboardDetail?.presentWorkers[0] != null) {
      dashboardDetail?.presentWorkers[0].contractors.forEach((e) => {
        list.push({
          contractor: e.contractor,
          nowContractors: e
        });
      });
    }

    if (dashboardDetail?.presentWorkers[1] != null) {
      dashboardDetail?.presentWorkers[1].contractors.forEach((e) => {
        const index = list.findIndex((v) => v.contractor === e.contractor);
        if (index !== -1) {
          list[index].morningContractors = e;
        } else {
          list.push({
            contractor: e.contractor,
            morningContractors: e
          });
        }
      });
    }

    if (dashboardDetail?.presentWorkers[2] != null) {
      dashboardDetail?.presentWorkers[2].contractors.forEach((e) => {
        const index = list.findIndex((v) => v.contractor === e.contractor);
        if (index !== -1) {
          list[index].afternoonContractors = e;
        } else {
          list.push({
            contractor: e.contractor,
            afternoonContractors: e
          });
        }
      });
    }

    return (
      <div className="full-size-div" style={{ flex: 8 }}>
        <WorkInZoneDetail
          groupedList={list}
          closeCallBack={() => {
            setContentType(ContentType.siteMap);
          }}
        />
      </div>
    );
  };

  const alertDetail = (): JSX.Element => {
    return (
      <div className="full-size-div" style={{ flex: 8 }}>
        <AlertTableDetail
          appService={appService}
          projectId={project?.projectID ?? ''}
          projectUid={project?.id ?? ''}
          isAlertWarning={isAlertWarning || isFiltierAlertWarning || isAlertTypeWarning}
          canDeleteMsg={validAdminPanel}
          deleteAllMessageHandle={() => {
            getAlertTable();
          }}
          closeCallBack={() => {
            setContentType(ContentType.siteMap);
          }}
        />
      </div>
    );
  };

  const renderContent = (): JSX.Element => {
    if (isShowMic && selectedMicZoneName && project) {
      return (
        <MicZoneDetail
          appService={appService}
          project={project}
          summary={micSummary}
          zoneName={selectedMicZoneName}
          closeCallBack={() => {
            setContentType(ContentType.siteMap);
            setSelectedMicZoneName(null);
          }}
        />
      );
    }
    switch (contentType) {
      case ContentType.siteMap:
        return mainContent(siteMap());
      case ContentType.zoneDetail:
        var _isControlZone = false;
        const _controlZone = selectedZone as ControlZone;
        if (_controlZone != null) {
          _isControlZone =
            _controlZone?.authorizedWorkers != null && _controlZone?.unauthorizedWorkers != null;
        }
        return zoneDetail(selectedZone, _isControlZone);
      case ContentType.alertTable:
        return mainContent(alertDetail());
      case ContentType.mic:
        if (selectedMicZoneName && project) {
          return (
            <MicZoneDetail
              appService={appService}
              project={project}
              summary={micSummary}
              zoneName={selectedMicZoneName}
              closeCallBack={() => {
                setContentType(ContentType.siteMap);
                setSelectedMicZoneName(null);
              }}
            />
          );
        }
    }
    return mainContent(siteMap());
  };

  const isShowNormalContent = (): boolean => {
    return (
      !isShowFilter &&
      contentType !== ContentType.workerInZoneDetail &&
      contentType !== ContentType.workerInZoneDetailSec
    );
  };

  const zoneDetail = (detail?: Zone | ControlZone, isControlZone: boolean = false): JSX.Element => {
    const matchStatus = (zonerStatusList ?? []).filter(
      (e) => e.zone === (detail as ControlZone)?.zone
    );
    var isMuted = false;
    if (matchStatus.length > 0) {
      isMuted = !(matchStatus[0].status === 'OFF');
    }
    return (
      <div>
        <ZoneDetail
          appService={appService}
          projectId={project?.projectID ?? ''}
          projectUId={project?.id ?? ''}
          detail={detail}
          workerWatch={workerWatch}
          closeCallBack={() => {
            setContentType(ContentType.siteMap);
          }}
          isControlZone={isControlZone}
          isMuted={isMuted}
          muteToggleHandle={() => {
            getZonerStatus();
          }}
        />
      </div>
    );
  };

  return (
    <div className="main-container">
      <Grid className="home-grid">
        <div className="home-top-section-div">
          <div className="home-top-total-woker-div">
            {isShowMic ? (
              <TotalMic micSummary={micSummary} />
            ) : (
              <TotalWorker
                workCount={`${dashboardDetail?.totalWorkers ?? '-'}`}
                lastUpdateTime={staticAsOf ?? ''}
                countAmLabel={t('home_09_worker_present')}
                countPmLabel={t('home_02_worker_present')}
                countAm={dashboardDetail?.presentWorkers[1]?.count}
                countPm={dashboardDetail?.presentWorkers[2]?.count}
                btnModel={workerInZoneFirst}
              />
            )}
          </div>
          <div className="home-top-woker-in-zone-div">
            {isShowMic && project ? (
              <MicTracking appService={appService} project={project} />
            ) : (
              <AlertOverview alertOverview={alertOverview} />
            )}
          </div>
          <div className="home-top-alert-table-div">
            <AlertTable
              messageList={(alertTableList?.data ?? []).slice(0, 2)}
              isAlertWarning={isAlertWarning || isFiltierAlertWarning || isAlertTypeWarning}
              showAllCallBack={() => {
                resetShowFilter();
                setContentType(ContentType.alertTable);
              }}
              mutedCount={
                zonerStatusList?.filter((e) => (e.status ?? '').includes('OFF')).length ?? 0
              }
            />
          </div>
        </div>
        {isShowFilter
          ? mainContent(filterContent(true))
          : isShowFilterHideOption
          ? mainContent(filterContent(false))
          : contentType === ContentType.workerInZoneDetail
          ? mainContent(workInZone())
          : isShowNormalContent()
          ? renderContent()
          : null}
      </Grid>
      <MessageDialog
        dialogProps={{
          title: t('dialog_error_title'),
          content: t('dialog_error_message'),
          btnTitle: t('dialog_error_button'),
          open: open,
          handleClose: handleClose
        }}
      />
    </div>
  );
};

export default Home;
