import { createSlice } from '@reduxjs/toolkit';
import { MapGeoFence, MapWorker, MapZone } from '../../model/gisMap/gisMapModel';

export interface GisMapPojectInfo {
  isGIS: boolean;
  lat: number;
  long: number;
  zoomLvl: number;
}
export interface GisMapState {
  gisLoading: boolean;

  projectInfo: GisMapPojectInfo;

  mapWorkers: Array<MapWorker>;
  mapZone: Array<MapZone>;
  mapGeoFences: Array<MapGeoFence>;
}

const initialState: GisMapState = {
  gisLoading: false,

  projectInfo: {
    isGIS: false,
    lat: 0,
    long: 0,
    zoomLvl: 1
  },

  mapWorkers: [],
  mapZone: [],
  mapGeoFences: []
};

export const gisMapSlice = createSlice({
  name: 'gisMap',
  initialState,
  reducers: {
    updateGisLoading: (state, action) => {
      state.gisLoading = action.payload;
    },
    updateIsGis: (state, action) => {
      state.projectInfo.isGIS = action.payload;
    },
    updateMapProjectConfig: (state, action) => {
      state.projectInfo = action.payload;
    },
    updateMapWorker: (state, action) => {
      state.mapWorkers = action.payload;
    },
    updateMapZone: (state, action) => {
      state.mapZone = action.payload;
    },
    updateMapGeoFence: (state, action) => {
      state.mapGeoFences = action.payload;
    }
  }
});

export const {
  updateGisLoading,
  updateIsGis,
  updateMapProjectConfig,
  updateMapWorker,
  updateMapZone,
  updateMapGeoFence
} = gisMapSlice.actions;

export default gisMapSlice.reducer;
