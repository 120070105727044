import React from 'react'
import ButtonModel from './buttonModel'

const AppTextIconButton: React.FC<{
	viewModel: ButtonModel,
	image: string
}> = ({ viewModel, image }) => {
	return (
		<button
			className='text-icon-button'
			onClick={viewModel.onClick}
		>
			<img src={image} alt="" />
			<div
				className='normal-label'
				style={{
					textAlign: 'left',
					paddingLeft: '17px',
					fontSize: '12px'
				}}
			>
				{viewModel.title}
			</div>
		</button >
	)
}

export default AppTextIconButton;