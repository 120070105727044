import React from 'react'
import '../i18n'
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../i18n";
import NormalButton from './button/normalButton';

const SwitchLanguage: React.FC<{
}> = ({ }) => {

	const { t, i18n } = useTranslation()

	return (
		<div className='setting-language-div'>
			{
				availableLanguages.map((lang) => (
					<div>
						<NormalButton viewModel={{
							title: lang === "tc" ? "繁" : "EN",
							onClick: () => {
								i18n.changeLanguage(lang)
							},
							style: {
								marginTop: '20px',
								marginBottom: '20px',
								marginRight: '10px',
								width: '50px',
								height: '50px',
								borderRadius: '50%',
								border: '1px solid white',
								color: i18n.language.includes(lang) ? 'rgb(255, 143, 13)' : 'white',
								background: i18n.language.includes(lang) ? 'white' : 'transparent',
							}
						}} />
					</ div>
				))
			}
		</div>
	);
}

export default SwitchLanguage