import React, { useState } from 'react'
import { Pagination } from 'react-bootstrap'

const maxPageDisplay = 4;

const Paging: React.FC<{
	pageSize: number,
	onPageClick: (index: number) => void
}> = ({ pageSize, onPageClick }) => {

	const [currentIndex, setCurrentIndex] = useState<number>(1);

	const defaultStartIndex = 1;

	const pageItem = (): JSX.Element[] => {
		var _pageItem: JSX.Element[] = [];

		const isShowMore = currentIndex > maxPageDisplay;
		const startIndex = isShowMore ? (currentIndex - 1) - defaultStartIndex : 1;
		const endIndex = pageSize < maxPageDisplay ?
			pageSize : isShowMore ? Math.min(...[pageSize, (currentIndex + 1)]) : maxPageDisplay;

		for (let i = startIndex; i <= endIndex; i++) {
			_pageItem.push(
				<Pagination.Item
					key={i}
					active={currentIndex === i}
					onClick={() => {
						setCurrentIndex(i);
						onPageClick(i)
					}}
				>
					{i}
				</Pagination.Item>
			);
		}
		return _pageItem;
	}

	const goPrevPage = () => {
		if (currentIndex > 1) {
			const index = currentIndex - 1;
			onPageClick(index);
			setCurrentIndex(index);
		}
	}

	const goNextPage = () => {
		if (currentIndex < pageSize) {
			const index = currentIndex + 1;
			onPageClick(index);
			setCurrentIndex(index);
		}
	}

	return (
		<Pagination className='pagination-div'>
			<Pagination.Prev
				id='arrow-page'
				onClick={() => goPrevPage()}
				disabled={currentIndex === defaultStartIndex}
			/>
			{
				(currentIndex === pageSize && pageSize > maxPageDisplay) ?
					<Pagination.Ellipsis disabled /> : null
			}
			{pageItem()}
			{
				(currentIndex < pageSize - 1 && pageSize > maxPageDisplay) ?
					<Pagination.Ellipsis disabled /> : null
			}
			<Pagination.Next
				id='arrow-page'
				onClick={() => goNextPage()}
				disabled={currentIndex === pageSize}
			/>
		</Pagination>
	)
}

export default Paging