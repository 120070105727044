import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { MarkerOptions, GoogleMap, DrawInfo, LatLng } from './googleMap';
import { useEffect, useMemo, useState } from 'react';
import './gisStyles.css';
import {
  INFO_WINDOW_ICON_SET,
  INTERVAL_FETCH_TIME,
  MARKER_ICON_SET,
  ZONE_ICON_SET
} from './gisConfig';
import { useSelector } from '../../redux/store';
import { AppService } from '../../services/appService';
import { useDispatch } from 'react-redux';
import {
  selectGisLoading,
  selectMapGeoFences,
  selectMapWorkers,
  selectMapWorkersObj,
  selectMapZone,
  selectMapProjectIsGis,
  selectMapProjectConfig
} from '../../redux/gisMap/gisMapSelector';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { GisMapLoading } from './gisMapLoading';
import moment from 'moment';

interface GisMapProps {
  appService: AppService;
  onWorkerMarkerClick?: (zoneName: string) => void;
}

const zoneLabelClassname = 'zone-marker-label';

export const GisMap = ({ appService, onWorkerMarkerClick }: GisMapProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const project = useSelector((state) => state.user.project);
  const isGis = useSelector(selectMapProjectIsGis);
  const mapProjectConfig = useSelector(selectMapProjectConfig);

  const [zoom, setZoom] = useState(0);
  const [center, setCenter] = useState<LatLng>({ lat: 0, lng: 0 });

  const workers = useSelector(selectMapWorkers);
  const workersObj = useSelector(selectMapWorkersObj);
  const mapZone = useSelector(selectMapZone);
  const geoFences = useSelector(selectMapGeoFences);

  const apiLoading = useSelector(selectGisLoading);

  const [lastProjectId, setLastProjectId] = useState('');

  const [testInterval, setTestInterval] = useState<NodeJS.Timer>();

  // 1) check project have gis map
  useEffect(() => {
    if (project?.projectID) {
      // only fetch when new or changed project
      if (lastProjectId !== project.projectID) {
        setLastProjectId(project.projectID);
        appService.gisMapServices.fetchIsGisByProjectId(dispatch, project.projectID);
      }
    }
  }, [appService.gisMapServices, dispatch, lastProjectId, project]);

  // 2) if have gis map then fetch map data
  useEffect(() => {
    if (isGis && project?.projectID) {
      const fetch = () =>
        appService.gisMapServices.fetchMapByProjectId(dispatch, project.projectID);

      fetch();
      const timer = setInterval(fetch, INTERVAL_FETCH_TIME);
      setTestInterval(timer);
      return () => clearInterval(timer);
    }
  }, [appService.gisMapServices, dispatch, isGis, project]);

  // 3) prepare marker data for pass into map
  const convertedMarkers: Record<string, MarkerOptions> = useMemo(() => {
    const result: Record<string, MarkerOptions> = {};

    // zone marker
    mapZone.forEach((zone) => {
      const { lat, long, zoneName } = zone;
      result[zoneName] = {
        markerId: zoneName,
        position: { lat: lat, lng: long },
        // TODO: add colour option for zone
        icon: ZONE_ICON_SET.zoneGreen,
        label: {
          text: zoneName,
          className: zoneLabelClassname
        },
        type: 'zone',
        onClick: onWorkerMarkerClick
      };
    });

    // worker marker
    workers.forEach((worker) => {
      const { isAlert, isHeartRate, isLowBattery, isSos, isTemper, id, lat, long } = worker;
      const isDangerWorker = isAlert || isHeartRate || isLowBattery || isSos || isTemper;

      result[id] = {
        markerId: id,
        position: { lat: lat, lng: long },
        icon: isDangerWorker ? MARKER_ICON_SET.activeWorker : MARKER_ICON_SET.worker,
        iconSize: { width: 26, height: 31 },
        type: 'worker'
      };
    });

    return result;
  }, [mapZone, onWorkerMarkerClick, workers]);

  // 4) calculate info window content on render
  const workerInfoWindowContent = (markerId: string) => {
    let targetWorker = workersObj[markerId];
    const {
      isTemper,
      isHeartRate,
      isSos,
      isAlert,
      isLowBattery,
      id,
      nameEng,
      nameZht,
      trade,
      contractor,
      lastUpdated
    } = targetWorker;

    const temperatureIcon = isTemper
      ? INFO_WINDOW_ICON_SET.temperatureAlert
      : INFO_WINDOW_ICON_SET.temperature;

    const heartbeatIcon = isHeartRate
      ? INFO_WINDOW_ICON_SET.heartbeatAlert
      : INFO_WINDOW_ICON_SET.heartbeat;

    const sosIcon = isSos ? INFO_WINDOW_ICON_SET.sosAlert : INFO_WINDOW_ICON_SET.sos;

    const exclamation = isAlert
      ? INFO_WINDOW_ICON_SET.exclamationAlert
      : INFO_WINDOW_ICON_SET.exclamation;

    const battery = isLowBattery ? INFO_WINDOW_ICON_SET.batteryAlert : INFO_WINDOW_ICON_SET.battery;

    const formatedLastUpdate = moment(lastUpdated).format('YYYY-M-D HH:mm');

    return `<div class="worker-info-window">
              <div class="info-window-text">
                <div>ID: ${id}</div>
                <div>Name EN: ${nameEng}</div>
                <div>Name CH: ${nameZht}</div>
                <div>Trade: ${trade}</div>
                <div>Contrator: ${contractor}</div>
              <div>
              <div class="info-window-icons-container">
                <div class="info-window-icon"><img src=${temperatureIcon} /></div>
                <div class="info-window-icon"><img src=${heartbeatIcon} /></div>
                <div class="info-window-icon"><img src=${sosIcon} /></div>
                <div class="info-window-icon"><img src=${exclamation} /></div>
                <div class="info-window-icon"><img src=${battery} /></div>
              </div>
              <div class="info-window-date">Last updated: ${formatedLastUpdate}</div>
            </div>`;
  };

  const convertedGeoFences: Record<string, DrawInfo> | undefined = useMemo(() => {
    const result: Record<string, DrawInfo> = {};

    geoFences.forEach((geoFence, index) => {
      result[index] = {
        color: `#${geoFence.color}`,
        latlng: geoFence.gps.map(({ lat, long }) => ({
          lat,
          lng: long
        })),
        editable: false
      };
    });
    return result;
  }, [geoFences]);

  useEffect(() => {
    if (mapProjectConfig.zoomLvl) setZoom(mapProjectConfig.zoomLvl);
  }, [mapProjectConfig.zoomLvl]);

  useEffect(() => {
    if (mapProjectConfig.lat && mapProjectConfig.long)
      setCenter({ lat: mapProjectConfig.lat, lng: mapProjectConfig.long });
  }, [mapProjectConfig.lat, mapProjectConfig.long]);

  // Test auto update
  const testAutoUpdate = () => {
    clearInterval(testInterval);
    appService.gisMapServices.mockUpdateMapByProjectId(dispatch);
  };

  const render = (status: Status) => {
    if (status === Status.FAILURE) return <div>{t('dialog_error_title')}</div>;
    return <></>;
  };

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {process.env.REACT_APP_ENABLE_TEST_AUTO_UPDATE && (
        <button onClick={testAutoUpdate}>TEST AUTO UPDATE</button>
      )}

      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY ?? ''} render={render}>
        <GoogleMap
          center={center}
          zoom={zoom}
          markers={convertedMarkers}
          workerInfoWindowContent={workerInfoWindowContent}
          polygons={convertedGeoFences}
        />
      </Wrapper>

      {apiLoading && <GisMapLoading />}
    </Box>
  );
};
