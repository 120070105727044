import React, {useCallback, useEffect, useState} from "react";
import AppIconButton from "../button/IconButton";
import IconClose from "../../assests/images/icon-close.png";
import NormalLabel from "../label/normalLabel";
import moment from "moment/moment";
import {AppService} from "../../services/appService";
import {useTranslation} from "react-i18next";
import {MicSummary} from "../../model/MicSummary";
import {ProjectInfo} from "../../model/project/projectModel";
import {MicZoneSummary} from "../../model/MicZoneSummary";
import MicZoneTypesContent from "./micZoneDetailComponent/typeDetailComponent";
import MicZoneComponentsContent from "./micZoneDetailComponent/componentDetailComponent";
import {Switch} from "@mui/material";
import {showZeroCountZones} from "../../redux/viewSlice";

type MicZoneDetailProps = {
  appService: AppService,
  project: ProjectInfo,
  summary: MicSummary | null | undefined,
  zoneName: string;
  closeCallBack: React.MouseEventHandler
}

const MicZoneDetail = ({ appService, project, summary, zoneName, closeCallBack }: MicZoneDetailProps) => {
  const { t } = useTranslation()
  const [asOfDate, setAsOfDate] = useState<Date|null>(null);
  const [micZoneSummary, setMicZoneSummary] = useState<MicZoneSummary|null>(null);
  const [displayItemId, setDisplayItemId] = useState<boolean>(true);
  
  const getMicZoneSummary = useCallback(() => {
    if (project && zoneName) {
      appService.dashboardService
        .getMicZoneSummary({
          "projectID": project.projectID,
          "zone": zoneName,
        })
        .subscribe(
          (value: any) => {
            setMicZoneSummary(value.data.data);
            setAsOfDate(new Date());
          },
          (error) => {}
        );
    }
  }, [project, zoneName])
  
  useEffect(() => {
    let interval: any;
    getMicZoneSummary();
    interval = setInterval(() => {
      getMicZoneSummary();
    }, 6000);
    return () => clearInterval(interval);
  }, [getMicZoneSummary]);
  return (
    <div >
      <div
        className='div-box-container-dark-zone-detail'
        style={{
          border: '2px solid rgba(255,143,13,1)',
          display: 'flex',
          height: '100%',
          flexDirection: 'column'
        }}
      >
        <div style={{ paddingTop: '20px', paddingBottom: '10px', display: 'flex', position: 'relative' }}>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <AppIconButton
              viewModel={{
                title: '',
                onClick: closeCallBack,
                style: {}
              }}
              image={IconClose}
            />
          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <div style={{ height: 120, display: 'flex', flexDirection: 'column', }}>
              <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, }}>
                <div style={{ display: 'flex' }}>
                  <NormalLabel title={t('home_as_of_time') + ' ' + (asOfDate ? moment(asOfDate).format('YYYY-MM-DD HH:mm') : '-')} fontSize={'20'} />
                  <div style={{ marginLeft: 16, display: 'flex', alignItems: 'center' }}>
                    <div className='circle-div-white' style={{ marginRight: 0 }} />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', flex: 1 }}>
                <div style={{ maxWidth: '160px' }}>
                  <NormalLabel title={zoneName} fontSize={'24'} lineHeight="1.5" />
                </div>
                <div style={{ marginLeft: 32 }}>
                  <NormalLabel title={summary ? `${summary.zones[zoneName] || 0}` : '-'} fontSize={'80'} />
                </div>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <NormalLabel title="Display Item ID" fontSize={'14'} style={{ width: 'fit-content' }} />
              <Switch
                sx={{
                  '.MuiSwitch-track': {
                    background: 'white',
                  },
                  '.Mui-checked': {
                    color: 'rgb(255, 143, 13) !important',
                  },
                  '.Mui-checked + .MuiSwitch-track': {
                    background: 'rgb(255, 143, 13) !important',
                  }
                }}
                defaultChecked={displayItemId}
                onClick={() => setDisplayItemId(!displayItemId)}
              />
            </div>
          </div>
        </div>

        <div className='full-size-flex-row-div'>
          <div style={{ width: '30%' }}>
            {micZoneSummary && (
              <MicZoneTypesContent
                micTypes={micZoneSummary.micTypes}
              />
            )}
          </div>
          <div
            style={{
              width: `70%`,
              marginLeft: '10px'
            }}
          >
            {micZoneSummary && (
              <MicZoneComponentsContent
                displayItemId={displayItemId}
                components={micZoneSummary.components}
              />
            )}
          </div>
        </div>
      </div >
    </div>
  )
}

export default MicZoneDetail;