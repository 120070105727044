// component version 1.4.1
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _intersection from 'lodash/intersection';
import _difference from 'lodash/difference';

interface CustomMarkerBase {
  type: 'worker' | 'zone';
  markerId: string;
  iconSize?: { width: number; height: number };
  onClick?: (id: string) => void;
}
export interface MarkerOptions extends google.maps.MarkerOptions, CustomMarkerBase {}
export interface Marker extends google.maps.Marker, CustomMarkerBase {}

export interface LatLng extends google.maps.LatLngLiteral {}
export interface DrawInfo {
  color: string;
  latlng: Array<LatLng> | [];
  editable: boolean;
}

export type VextexActions = 'add' | 'edit' | 'delete';

export type GoogleMapExposeFunctions = {
  resetMarker: () => void;
};

interface GoogleMapProps {
  center: LatLng;
  zoom: number;
  mapTypeId?: 'hybrid' | 'roadmap' | 'satellite' | 'terrain';
  paths?: Record<string, DrawInfo>;
  polygons?: Record<string, DrawInfo>;
  onChangePolygon?: (updatedVertexGrp: Array<LatLng>, action: VextexActions) => void;
  markers?: Record<string, MarkerOptions>;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  workerInfoWindowContent?: (markerId: string) => Element | string;
}

export const GoogleMap = forwardRef<GoogleMapExposeFunctions, GoogleMapProps>(
  (
    {
      center,
      zoom,
      mapTypeId = google.maps.MapTypeId.TERRAIN,
      paths,
      polygons,
      onChangePolygon,
      markers,
      onClick,
      workerInfoWindowContent = () => '<div>No Info</div>'
    },
    selfRef
  ) => {
    const ref = useRef(null);
    const [googleMap, setGoogleMap] = useState<google.maps.Map>();

    const markerInstancesRef = useRef<Record<string, Marker>>({});
    const infoWindowInstancesRef = useRef<Record<string, google.maps.InfoWindow>>({});

    const [polygonInstances, setPolygonInstances] = useState<Record<string, google.maps.Polygon>>(
      {}
    );
    const [polylineInstances, setPolylineInstances] = useState<
      Record<string, google.maps.Polyline>
    >({});

    // init map
    useEffect(() => {
      if (ref.current) {
        const customMapStyles = [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }
        ];

        const map = new google.maps.Map(ref.current, {
          zoom,
          mapTypeId,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: customMapStyles
        });
        setGoogleMap(map);
      }
    }, [zoom, mapTypeId]);

    useEffect(() => {
      if (googleMap) googleMap.setCenter(center);
    }, [center, googleMap]);

    useEffect(() => {
      if (googleMap) {
        // remove old and init click callback for onClick map event
        ['click'].forEach((eventName) => google.maps.event.clearListeners(googleMap, eventName));

        if (onClick) googleMap.addListener('click', onClick);
      }
    }, [googleMap, onClick]);

    useImperativeHandle(selfRef, () => ({
      resetMarker() {
        removeOldMarkers(Object.keys(markerInstancesRef.current));
        markerInstancesRef.current = {};
      }
    }));

    const addMarker = useCallback(
      (markerOption: MarkerOptions) => {
        let newInfoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

        const { iconSize } = markerOption;
        const iconSrc: google.maps.Icon = {
          url: typeof markerOption.icon === 'string' ? markerOption.icon : '',
          scaledSize: iconSize
            ? new google.maps.Size(iconSize.width, iconSize.height)
            : new google.maps.Size(33, 39)
        };

        const newMarker = new google.maps.Marker({
          map: googleMap,
          ...markerOption,
          icon: iconSrc
        });

        if (markerOption.type === 'worker') {
          newMarker.addListener('click', () => {
            // close all other info window before open one
            Object.keys(infoWindowInstancesRef.current).forEach((key) => {
              infoWindowInstancesRef.current[key].close();
            });

            if (infoWindowInstancesRef.current[markerOption.markerId]) {
              infoWindowInstancesRef.current[markerOption.markerId].open({
                anchor: newMarker,
                map: googleMap
              });
            } else {
              newInfoWindow = new google.maps.InfoWindow({
                content: workerInfoWindowContent(markerOption.markerId),
                maxWidth: 430
              });
              newInfoWindow.open({
                anchor: newMarker,
                map: googleMap
              });
              newInfoWindow.addListener('closeclick', () => {
                delete infoWindowInstancesRef.current[markerOption.markerId];
              });
            }
          });
        }

        if (markerOption.type === 'zone') {
          newMarker.addListener('click', () => {
            markerOption.onClick && markerOption.onClick(markerOption.markerId);
          });
        }

        return { marker: newMarker as Marker, infoWindow: newInfoWindow };
      },
      [googleMap, workerInfoWindowContent]
    );

    const updateMarker = useCallback(
      ({ markerId, position, icon, type }: MarkerOptions) => {
        const instance = markerInstancesRef.current[markerId];
        const instancePosition = instance.getPosition();

        if (
          instancePosition &&
          (instancePosition.lat !== position?.lat || instancePosition.lng !== position?.lng)
        )
          instance.setPosition(position);

        if ((instance.getIcon() as google.maps.Icon).url !== icon) {
          instance.setIcon({
            url: typeof icon === 'string' ? icon : '',
            scaledSize: new google.maps.Size(33, 39)
          });
        }

        // Possibly google map initial slower than marker create,
        // so need set map to marker instance after google map created
        if (googleMap && (!instance.getMap() || instance.getMap() !== googleMap))
          instance.setMap(googleMap);

        // update info window content on every update (worker)
        if (type === 'worker' && infoWindowInstancesRef.current[markerId]) {
          infoWindowInstancesRef.current[markerId].setContent(workerInfoWindowContent(markerId));
        }

        return { marker: instance, infoWindow: infoWindowInstancesRef.current[markerId] };
      },
      [googleMap, infoWindowInstancesRef, markerInstancesRef, workerInfoWindowContent]
    );

    const removeOldMarkers = useCallback(
      (markersToBeRemove: Array<string>) => {
        markersToBeRemove.forEach((key) => {
          markerInstancesRef.current[key].setMap(null);
          infoWindowInstancesRef.current[key].setContent('');
        });
      },
      [infoWindowInstancesRef, markerInstancesRef]
    );

    // marker handler
    useEffect(() => {
      if (!markers) return;

      const newMarkerKeys = Object.keys(markers);
      const oldMarkerKeys = Object.keys(markerInstancesRef.current);

      // remove old markers
      const markersToBeRemove = oldMarkerKeys.filter((key) => !newMarkerKeys.includes(key));
      removeOldMarkers(markersToBeRemove);

      // update old markers and return instance
      const matchedOldMarkers = _intersection(newMarkerKeys, oldMarkerKeys);
      const updatedOldMarkers = matchedOldMarkers.map((key) => updateMarker(markers[key]));

      // create new markers and return instance
      const needCreateMarkers = _difference(newMarkerKeys, matchedOldMarkers);
      const createdMarkers = needCreateMarkers.map((key) => addMarker(markers[key]));

      // replace the instances in useRef
      const combinedMarkers = [...updatedOldMarkers, ...createdMarkers];
      const resultMarkers: Record<string, Marker> = {};
      const resultInfoWindows: Record<string, google.maps.InfoWindow> = {};
      combinedMarkers.forEach(({ marker, infoWindow }) => {
        resultMarkers[marker.markerId] = marker;
        resultInfoWindows[marker.markerId] = infoWindow;
      });

      markerInstancesRef.current = resultMarkers;
      infoWindowInstancesRef.current = resultInfoWindows;
    }, [addMarker, markers, removeOldMarkers, updateMarker]);

    // Googlemap overlay layer for Edit menu
    // const createContextMenu = () => {
    //     const contextMenu = document.createElement("div");
    //     contextMenu.className = "delete-menu-item";
    //     contextMenu.innerHTML = "Delete";
    //     return contextMenu;
    // };

    // const overlay = useMemo(() => {
    //     return EditOverlayView({
    //         getElement: createContextMenu,
    //         onRemoveVertexCallback: (updatedVertexGrp: Array<LatLng>) =>
    //             onChangePolygon && onChangePolygon(updatedVertexGrp, "delete")
    //     });
    // }, [onChangePolygon]);

    // useEffect(() => {
    //     return () => overlay?.setMap(null);
    // }, [overlay]);

    const createPolygon = useCallback(
      (key: string, { latlng, editable, color }: DrawInfo, googleMap: google.maps.Map) => {
        if (polygonInstances[key]) {
          // already created instance
          polygonInstances[key].setPaths(latlng);
          // for when new map been re-initial (comopnent re-mount)
          polygonInstances[key].setMap(googleMap);
          if (editable) polygonInstances[key].setEditable(true);
        } else {
          //  first time create
          const polygon = new google.maps.Polygon({
            paths: latlng,
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.3,
            editable: editable ?? false,
            map: googleMap
          });

          setPolygonInstances((prev) => ({
            ...prev,
            [key]: polygon
          }));

          // for vertex "delete" context menu
          // google.maps.event.addListener(
          //     polygon,
          //     "contextmenu",
          //     (e: google.maps.PolyMouseEvent) => {
          //         // Check if click was on a vertex control point
          //         if (e.vertex === undefined) return;
          //         overlay.open(googleMap, polygon.getPath(), e.vertex);
          //     }
          // );

          if (editable) {
            const vertexHandler = (action: VextexActions) => {
              const updatedPolygonVextex = new Array(polygon.getPath().getLength());
              polygon.getPath().forEach((vertex, index) => {
                updatedPolygonVextex[index] = vertex.toJSON();
              });
              onChangePolygon && onChangePolygon(updatedPolygonVextex, action);
            };

            google.maps.event.addListener(polygon, 'mouseup', () => {
              // need add listener every time click, due to polygon's path is a new instance on every update
              google.maps.event.addListener(polygon.getPath(), 'set_at', () =>
                vertexHandler('edit')
              );
              // execute on first click
              vertexHandler('edit');

              google.maps.event.addListener(polygon.getPath(), 'insert_at', () =>
                vertexHandler('add')
              );
            });
          }
        }
      },
      [onChangePolygon, polygonInstances]
    );

    // polygon handler
    useEffect(() => {
      if (!googleMap || !polygons) return;

      const keys = Object.keys(polygons);

      if (!keys.length && Object.keys(polygonInstances).length) {
        // clear all polygon if data empty
        Object.keys(polygonInstances).forEach((instanceKey) => {
          polygonInstances[instanceKey].setMap(null);
          setPolygonInstances({});
        });
        return;
      }

      keys.forEach((key) => {
        createPolygon(key, polygons[key], googleMap);
      });
    }, [polygons, googleMap, createPolygon, polygonInstances]);

    const createPolyline = useCallback(
      (key: string, data: DrawInfo, googleMap: google.maps.Map) => {
        const getPolylineOption = () => ({
          path: data.latlng,
          geodesic: true,
          strokeColor: data.color,
          strokeOpacity: 1.0,
          strokeWeight: 2
        });

        if (polylineInstances[key]) {
          // already created instance
          const polyline = polylineInstances[key];
          polyline.setPath(data.latlng);
          polyline.setOptions(getPolylineOption());
        } else {
          // first time create
          const drawPath = new google.maps.Polyline(getPolylineOption());
          drawPath.setMap(googleMap);
          setPolylineInstances({
            [`${key}`]: drawPath,
            ...polylineInstances
          });
        }
      },
      [polylineInstances]
    );

    // path handler
    useEffect(() => {
      if (!googleMap || !paths) return;

      const keys = Object.keys(paths);

      if (!keys.length && Object.keys(polylineInstances).length) {
        // clear all polyline instance of paths path is empty
        Object.keys(polylineInstances).forEach((instanceKey) => {
          polylineInstances[instanceKey].setMap(null);
        });
        setPolylineInstances({});
        return;
      }

      keys.forEach((key) => {
        createPolyline(key, paths[key], googleMap);
      });
    }, [paths, googleMap, createPolyline, polylineInstances]);

    return (
      <>
        <div ref={ref} id="map" style={{ height: '100%', boxSizing: 'initial' }} />
      </>
    );
  }
);
