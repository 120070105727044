import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../model/constants";

export interface ProjectFilter {
	constructor: string,
	constructorEn: string,
	constructorCode: string,
	constructorUid: string,
	type: string,
	typeEn: string,
	typeCode: string,
	filterType: string,
}

export interface InitialState {
	projectFilter: ProjectFilter;
}

const initialState: InitialState = {
	projectFilter: localStorage.getItem('filter') == null ?
		DEFAULT_FILTER : JSON.parse(localStorage.getItem('filter')!)

};

export const filterSlice = createSlice({
	name: "Filter",
	initialState,
	reducers: {
		filter: (state, action) => {
			state.projectFilter = action.payload;
			localStorage.setItem('filter', JSON.stringify(action.payload));
		},
	},
});

export const { filter } = filterSlice.actions;

export default filterSlice.reducer;