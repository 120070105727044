import moment from "moment"

const getDateTime = (date: Date): string => {
	return `${moment(date).format('HH:mm:ss')}`
}

const getZoneAsOfTime = (date: Date): string => {
	return `${moment(date).format('HH:mm:ss')}`
}

export { getDateTime, getZoneAsOfTime }