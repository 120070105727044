import { AppService } from "./appService";

const zonerStatusServices = (service: AppService) => ({
	getZonerStatus: (projectId: string) =>
		service.request(
			'post',
			'zoners/status',
			{
				projectID: projectId
			}
		),

	toggleZonerStatus: (formData: any) =>
		service.request(
			'post',
			'zoners/toggle',
			formData
		),
});

export default zonerStatusServices;
