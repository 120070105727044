import React, { useEffect, useState } from 'react'
import moment from 'moment';

function Clock() {

	const [clock, setClock] = useState(moment().format('DD-MM-yyyy HH:mm:ss'));

	useEffect(() => {
		setInterval(() => {
			const date = moment().format('DD-MM-yyyy  HH:mm:ss');
			setClock(date);
		}, 1000);
	}, []);

	return (
		<div className='clock-div'>
			<label className='normal-label'>{clock}</label>
		</div>
	);
}

export default Clock;