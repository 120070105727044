import React, { useState } from 'react'
import ButtonModel from './button/buttonModel'
import AppTextIconButton from './button/textIconButton'
import LogoutIcon from '../assests/images/icon-logout.png';
import { ConfirmDialog } from './dialog';
import { showSetting } from "../redux/viewSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { AppService } from '../services/appService';
import { HandleLogout } from '../services/authenticationService';
import SwitchLanguage from './switchLanguage';
import { useSelector } from "../redux/store";

import '../i18n'
import { useTranslation } from "react-i18next";
import SwitchZeroCountZones from "./switchZeroCountZones";

const LogoutBlock: React.FC<{ appService: AppService }> = ({ appService }) => {

	const { t, i18n } = useTranslation()

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isOpenComfirm, setIsOpenConfirm] = useState(false);
	const isAuth = useSelector((state: any) => state.user.isAuth as boolean);
	const project = useSelector((state: any) => state.user.project);

	const handleClose = () => {
		setIsOpenConfirm(false);
	};

	const logoutButtonModel: ButtonModel = {
		title: t('logout_button'),
		onClick: () => {
			setIsOpenConfirm(true);
		},
		style: {}
	}

	const clickDimArea = () => {
		dispatch(showSetting(false));
	}

	var canShowLogout = () => {
		return (isAuth
			&& project != null
			&& !(window.location.pathname.includes('/login') ||
				window.location.pathname.includes('/project')))
	}

	const clickConfirm = () => {
		appService.authentication
			.logout()
			.subscribe(
				(value) => {
					HandleLogout(dispatch, navigate, true);
				},
				(error) => {
					HandleLogout(dispatch, navigate, true);
				}
			)
	}

	return (
		<div className={'setting-block-div'}>
			<SwitchLanguage />
			<SwitchZeroCountZones />
			{
				canShowLogout() ? <>
					<div className='logout-block-div'>
						<AppTextIconButton
							viewModel={logoutButtonModel}
							image={LogoutIcon}
						/>
						<ConfirmDialog
							dialogProps={{
								title: t('dialog_logout_title'),
								content: '',
								open: isOpenComfirm,
								btnTitle: t('dialog_logout_confirm_button'),
								btnCancel: t('dialog_logout_cancel_button'),
								handleClose: handleClose
							}}
							confirmCallBack={clickConfirm}
						/>
					</div></> : <></>
			}

			<div
				style={{
					width: '100%',
					height: '100%',
					position: 'absolute'
				}}
				onClick={() => clickDimArea()}>
			</div>
		</div>
	)
}

export default LogoutBlock