import {useEffect, useState} from "react";
import {CHUNK_SIZE} from "../../../model/constants";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";
import ArrowLabel from "../../label/arrowLabel";

type MicZoneTypesContentProps = {
  micTypes: {[key: string]: number}
}

interface MicTypeCount {
  micType: string;
  count: number;
}

const MicZoneTypesContent: React.FC<MicZoneTypesContentProps> = ({ micTypes }) => {
  const typeCounts = Object.keys(micTypes).map((key) => {
    return {
      micType: key,
      count: micTypes[key]
    } as MicTypeCount;
  });
  const [pageIndex, setpageIndex] = useState(1);
  const [sorting, setSorting] = useState({key: "", aesOrder: false});
  const [micTypeList, setMicTypeList] = useState<MicTypeCount[]>(typeCounts);
  
  useEffect(() => {
    if (sorting.key.length > 0) {
      const _micTypeList: any = [...micTypeList]
      _micTypeList.sort((a: any, b: any) => {
        if (sorting.key.includes(".")) {
          const spiltKey = sorting.key.split(".")
          if (typeof (a[spiltKey[0]][spiltKey[1]] ?? "-") === "number" &&
            typeof (b[spiltKey[0]][spiltKey[1]] ?? "-") === "number") {
            const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? 0);
            const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? 0);
            return nestedValueA - nestedValueB
          } else {
            const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? "0").toString();
            const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? "0").toString();
            return nestedValueA.localeCompare(nestedValueB)
          }
        } else {
          if (typeof (a[sorting.key] ?? "-") === "number" && typeof (b[sorting.key] ?? "-") === "number") {
            const valueA = (a[sorting.key] ?? 0);
            const valueB = (b[sorting.key] ?? 0);
            return valueA - valueB
          }
          return (a[sorting.key] ?? "-").localeCompare((b[sorting.key] ?? "-"))
        }
      })
      setMicTypeList(sorting.aesOrder ? _micTypeList : _micTypeList.reverse())
    } else {
      setMicTypeList(typeCounts);
    }

  }, [sorting])

  const groupMicTypeListList = (sorting.key.length > 0 ? micTypeList : micTypeList)?.map((value, index) => {
    return index % CHUNK_SIZE === 0 ?
      (sorting.key.length > 0 ? (micTypeList ?? []) : micTypeList).slice(index, index + CHUNK_SIZE) : null
  }).filter((e) => {
    return e
  });

  const getMaxCount = () => {
    let max = 0;
    micTypeList?.forEach((element) => {
      if (element.count > max) {
        max = element.count;
      }
    });
    return max;
  }

  const setSortingHandle = (key: string) => {
    var aesOrder = !sorting.aesOrder;

    if (key !== sorting.key) {
      aesOrder = true;
    }
    setSorting({
      key: key,
      aesOrder: aesOrder
    })
  }

  const getSortArrowByKey = (key: string) => {
    if (key === sorting.key) {
      return !sorting.aesOrder;
    }
    return false;
  }

  console.log('groupMicTypeListList[pageIndex - 1]', typeCounts, micTypeList, groupMicTypeListList[pageIndex - 1])
  return (
    <div className='paging-container-div' style={{width: '100%'}}>
      <div className='zone-detail-table-header '>
        <div style={{flex: 1}} onClick={() => setSortingHandle("micType")}>
          <ArrowLabel title={'Type'} isUp={getSortArrowByKey("micType")}/>
        </div>
        <div style={{width: 120, flexShrink: 0, textAlign: 'right'}} onClick={() => setSortingHandle("count")}>
          <ArrowLabel title={'No.'} isUp={getSortArrowByKey("count")}/>
        </div>
        <div style={{marginLeft: 12, width: 80, flexShrink: 0}}></div>
      </div>

      <div style={{height: '11px'}}/>
      <div className='div-spacing-zone-line'/>
      <div className='paging-div-full-content'>
        {
          (groupMicTypeListList[pageIndex - 1] ?? [])?.map((value, index) => {
            return (
              <div
                key={index}
                style={{
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  display: 'flex'
                }}
              >
                <div style={{whiteSpace: 'pre-line', flex: 1}}>
                  <NormalLabel title={value.micType}/>
                </div>
                <div style={{width: 120, flexShrink: 0, textAlign: 'right'}}>
                  <NormalLabel title={`${value.count}`}/>
                </div>
                <div style={{marginLeft: 12, width: 80, flexShrink: 0, display: 'flex', alignItems: 'center',}}>
                  {getMaxCount() > 0 && (
                    <div style={{
                      width: `${value.count / getMaxCount() * 100}%`,
                      height: 14,
                      backgroundColor: 'white'
                    }}/>
                  )}
                </div>
              </div>
            )
          })
        }
      </div>
      <div className='div-spacing-zone-line'/>
      <Paging
        pageSize={groupMicTypeListList?.length ?? 1}
        onPageClick={(index) => {
          setpageIndex(index);
        }}
      />
    </div>
  );
}

export default MicZoneTypesContent;