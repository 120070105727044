import { i18n, TFunction } from "i18next";
import {useEffect, useState} from "react";
import { CHUNK_SIZE, FILTER_HUMAN } from "../../../model/constants";
import {ContractorDetail, ZoneItemDetail} from "../../../model/project/projectModel";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";
import ArrowLabel from "../../label/arrowLabel";

export const ContractorContent: React.FC<{
	filterType: string,
	contractorList: ContractorDetail[],
	t: TFunction<"translation">,
	i18n: i18n
}> = ({ filterType, contractorList, t, i18n }) => {

	const [pageIndex, setpageIndex] = useState(1);
	const [sorting, setSorting] = useState({ key: "", aesOrder: false });
	const isEng = i18n.language.includes("en")
	const [contractorLists, setContractorLists] = useState<ContractorDetail[]>(contractorList);

	useEffect(() => {
		if (sorting.key.length > 0) {
			const _contractorList: any = [...contractorList]
			_contractorList.sort((a: any, b: any) => {
				if (sorting.key.includes(".")) {
					const spiltKey = sorting.key.split(".")
					if (typeof (a[spiltKey[0]][spiltKey[1]] ?? "-") === "number" &&
						typeof (b[spiltKey[0]][spiltKey[1]] ?? "-") === "number") {
						const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? 0);
						const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? 0);
						return nestedValueA - nestedValueB
					} else {
						const nestedValueA = (a[spiltKey[0]][spiltKey[1]] ?? "0").toString();
						const nestedValueB = (b[spiltKey[0]][spiltKey[1]] ?? "0").toString();
						return nestedValueA.localeCompare(nestedValueB)
					}
				} else {
					if (typeof (a[sorting.key] ?? "-") === "number" && typeof (b[sorting.key] ?? "-") === "number") {
						const valueA = (a[sorting.key] ?? 0);
						const valueB = (b[sorting.key] ?? 0);
						return valueA - valueB
					}
					return (a[sorting.key] ?? "-").localeCompare((b[sorting.key] ?? "-"))
				}
			})
			setContractorLists(sorting.aesOrder ? _contractorList : _contractorList.reverse())
		} else {
			setContractorLists(contractorList);
		}

	}, [sorting])

	const groupContractorListList = (sorting.key.length > 0 ? contractorLists : contractorList)?.map((value, index) => {
		return index % CHUNK_SIZE === 0 ?
			(sorting.key.length > 0 ? (contractorLists ?? []) : contractorList).slice(index, index + CHUNK_SIZE) : null
	}).filter((e) => { return e });
	
	const getMaxContractorWorkersCount = () => {
		let max = 0;
		contractorList?.forEach((element) => {
			if (element.count > max) {
				max = element.count;
			}
		});
		return max;
	}
	
	const setSortingHandle = (key: string) => {
		var aesOrder = !sorting.aesOrder;

		if (key !== sorting.key) {
			aesOrder = true;
		}
		setSorting({
			key: key,
			aesOrder: aesOrder
		})
	}

	const getSortArrowByKey = (key: string) => {
		if (key === sorting.key) {
			return !sorting.aesOrder;
		}
		return false;
	}

	return (
		<div className='paging-container-div' style={{ width: '100%' }}>
			<div className='zone-detail-table-header '>
				<div style={{ flex: 1 }} onClick={() => setSortingHandle(isEng ? "contractorEn" : "contractorZh")}>
					<ArrowLabel title={t('home_contractor')} isUp={getSortArrowByKey(isEng ? "contractorEn" : "contractorZh")} />
				</div>
				<div style={{ width: 120, flexShrink: 0, textAlign: 'right' }} onClick={() => setSortingHandle("count")}>
					{filterType === FILTER_HUMAN ? (
						<ArrowLabel title={t('home_zone_detail_worker_count')} isUp={getSortArrowByKey("count")} />
					) : (
						<ArrowLabel title={t('home_machine_count')} isUp={getSortArrowByKey("count")} />
					)}
				</div>
				<div style={{ marginLeft: 12, width: 80, flexShrink: 0 }}></div>
			</div >

			<div style={{ height: '11px' }} />
			<div className='div-spacing-zone-line' />
			<div className='paging-div-full-content'>
				{
					(groupContractorListList[pageIndex - 1] ?? [])?.map((value, index) => {
						return (
							<div
								key={index}
								style={{
									paddingTop: '16px',
									paddingBottom: '16px',
									display: 'flex'
								}}
							>
								<div style={{ whiteSpace: 'pre-line', flex: 1 }}>
									<NormalLabel title={isEng ? value.contractorEn : value.contractorZh} />
								</div>
								<div style={{ width: 120, flexShrink: 0, textAlign: 'right' }}>
									<NormalLabel title={value.count} />
								</div>
								<div style={{ marginLeft: 12, width: 80, flexShrink: 0, display: 'flex', alignItems: 'center', }}>
									{getMaxContractorWorkersCount() > 0 && (
										<div style={{ width: `${value.count / getMaxContractorWorkersCount() * 100}%`, height: 14, backgroundColor: 'white' }} />
									)}
								</div>
							</div>
						)
					})
				}
			</div>
			<div className='div-spacing-zone-line' />
			<Paging
				pageSize={groupContractorListList?.length ?? 1}
				onPageClick={(index) => {
					setpageIndex(index);
				}}
			/>
		</div >
	);
}