import iconWorkerYellow from '../../assests/images/icon-worker-yellow.png';
import iconWorkerRed from '../../assests/images/icon-worker-red.png';
import iconHeartbeatGrey from '../../assests/images/icon-heartbeat-grey.png';
import iconHeartbeatOrange from '../../assests/images/icon-heartbeat-orange.png';
import iconTempGrey from '../../assests/images/icon-temp-grey.png';
import iconTempOrange from '../../assests/images/icon-temp-orange.png';
import iconSosGrey from '../../assests/images/icon-sos-grey.png';
import iconSosOrange from '../../assests/images/icon-sos-orange.png';
import iconExclamationGrey from '../../assests/images/icon-exclamation-grey.png';
import iconExclamationOrange from '../../assests/images/icon-exclamation-orange.png';
import iconBatteryGrey from '../../assests/images/icon-battery-charging-grey.png';
import iconBatteryOrange from '../../assests/images/icon-battery-charging-orange.png';
import iconZoneGreen from '../../assests/images/icon-zone-green.png';

export const MARKER_ICON_SET = {
  worker: iconWorkerYellow,
  activeWorker: iconWorkerRed
};

export const INFO_WINDOW_ICON_SET = {
  heartbeat: iconHeartbeatGrey,
  heartbeatAlert: iconHeartbeatOrange,
  temperature: iconTempGrey,
  temperatureAlert: iconTempOrange,
  sos: iconSosGrey,
  sosAlert: iconSosOrange,
  exclamation: iconExclamationGrey,
  exclamationAlert: iconExclamationOrange,
  battery: iconBatteryGrey,
  batteryAlert: iconBatteryOrange
};

export const ZONE_ICON_SET = {
  zoneGreen: iconZoneGreen
};

export const INTERVAL_FETCH_TIME = 30 * 1000;
