import React from 'react'
import { Container } from 'react-bootstrap'
import NormalLabel from '../label/normalLabel'
import '../../i18n'
import { useTranslation } from "react-i18next";
import {MicSummary} from "../../model/MicSummary";

type TotalMicProps = {
  micSummary: MicSummary | null | undefined
}

const TotalMic: React.FC<TotalMicProps> = ({ micSummary }) => {
  const { t } = useTranslation()
  return (
    <Container
      className='div-box-container'
      style={{ width: '100%', height: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          padding: '20px',
        }}
      >
        <div>
          <div style={{ padding: '5px 0' }} >
            <NormalLabel title={t("home_mimep_total")} fontSize="24" />
          </div>
          <div style={{ marginTop: 30 }}>
            <NormalLabel title={micSummary ? `${micSummary.totalCount}` : '-'} fontSize="90" />
          </div>
        </div>
        <div>
          <div style={{ width: 200, marginTop: 20 }}>
            <div className='full-size-flex-row-div'>
              <div style={{ flex: '2' }}>
                <NormalLabel title={t("home_mimep_in_assembling")} lineHeight="1.3" />
              </div>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={micSummary ? `${micSummary.inAssemblingCount}` : '-'} fontSize="36" />
              </div>
            </div>
            <div className='full-size-flex-row-div' style={{ marginTop: 20 }}>
              <div style={{ flex: '2' }}>
                <NormalLabel title={t("home_mimep_not_yet_installed")} lineHeight="1.3" />
              </div>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={micSummary ? `${micSummary.notYetInstalledCount}` : '-'} fontSize="36" />
              </div>
            </div>
            <div className='full-size-flex-row-div' style={{ marginTop: 20 }}>
              <div style={{ flex: '2' }}>
                <NormalLabel title={t("home_mimep_completed")} lineHeight="1.3" />
              </div>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={micSummary ? `${micSummary.completedCount}` : '-'} fontSize="36" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default TotalMic;