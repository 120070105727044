import { Zone } from "../model/home/homePageModel";
import {
	AlertTableModel
} from "../model/alertTable/alertTableModel";
import { ControlZone, ZoneInfo } from "../model/project/projectModel";

export const SampleAlertTableList: AlertTableModel = {
	data: [
		{
			id: "",
			projectId: "",
			createdAt: new Date(),
			title: {
				en: "10-02-2023 17:05 Cheng Chi Fai - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_A</b>",
				tc: "10-02-2023 17:05 鄭志輝 - 建榮工程有限公司 SOS呼叫；位置：<b style=\"color: #F87236;\">Zone_A</b>"
			},
			body: {
				en: "10-02-2023 17:05 Cheng Chi Fai - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_A</b>",
				tc: "10-02-2023 17:05 鄭志輝 - 建榮工程有限公司 SOS呼叫；位置：<b style=\"color: #F87236;\">Zone_A</b>"
			},
			viewed: false
		}, {
			id: "",
			projectId: "",
			createdAt: new Date(),
			title: {
				en: "10-02-2023 12:42 Ko Lai Hing - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_A</b>",
				tc: "10-02-2023 12:42 高麗興 - 建榮工程有限公司 心率異常；位置：<b style=\"color: #F87236;\">Zone_A</b>"
			},
			body: {
				en: "10-02-2023 12:42 Ko Lai Hing - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_A</b>",
				tc: "10-02-2023 12:42 高麗興 - 建榮工程有限公司 心率異常；位置：<b style=\"color: #F87236;\">Zone_A</b>"
			},
			viewed: false
		}, {
			id: "",
			projectId: "",
			createdAt: new Date(),
			title: {
				en: "10-02-2023 10:29 Wong Kwok Wai - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_A</b>",
				tc: "10-02-2023 10:29 黃國偉 - 建榮工程有限公司 體溫異常；位置：<b style=\"color: #F87236;\">Zone_A</b>"
			},
			body: {
				en: "10-02-2023 10:29 Wong Kwok Wai - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_A</b>",
				tc: "10-02-2023 10:29 黃國偉 - 建榮工程有限公司 體溫異常；位置：<b style=\"color: #F87236;\">Zone_A</b>"
			},
			viewed: false
		}, {
			id: "",
			projectId: "",
			createdAt: new Date(),
			title: {
				en: "09-02-2023 15:42 Wong Chi Wai - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_B</b>",
				tc: "09-02-2023 15:42 王志偉 - 建榮工程有限公司 體溫異常；位置：<b style=\"color: #F87236;\">Zone_B</b>"
			},
			body: {
				en: "09-02-2023 15:42 Wong Chi Wai - Kin Wing Construction Ltd abnormal body temperature; location: <b style=\"color: #F87236;\">Zone_B</b>",
				tc: "09-02-2023 15:42 王志偉 - 建榮工程有限公司 體溫異常；位置：<b style=\"color: #F87236;\">Zone_B</b>"
			},
			viewed: false
		}
	],
	totalCount: 123,
}

export const SampleNormalZoneList: Zone[] = [
	{
		name: "Entrance",
		count: 5
	}, {
		name: "Zone_1A",
		count: 20
	}, {
		name: "Zone_2A",
		count: 25
	}, {
		name: "Zone_3A",
		count: 48
	}, {
		name: "Zone_4A",
		count: 37
	}, {
		name: "Zone_5A",
		count: 15
	}, {
		name: "TBM_A",
		count: 0
	}, {
		name: "TBM_B",
		count: 0
	}
]

export const SampleControlZoneList: ControlZone[] = [
	{
		zone: "VA2",
		isDenyAllWorkers: false,
		authorizedWorkers: {
			count: 0,
			list: []
		},
		unauthorizedWorkers: {
			count: 0,
			list: []
		},
		snapshotTime: "string",
		trade: "",
		contractor: "",
		location: ""
	}
]

export const SampleZoneInfo: ZoneInfo = {
	zones: [{
		name: "Zone_A1",
		count: 20,
		items: [{
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "黃小虎",
			workerNameEn: "黃小虎",
			workerNameZh: "黃小虎",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "A. Eng",
			tradeZh: "A. Eng",
			country: "",
			trade: "A. Eng",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "32",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "梁國華",
			workerNameEn: "梁國華",
			workerNameZh: "梁國華",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "Eng",
			tradeZh: "Eng",
			country: "",
			trade: "Eng",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "29",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "何玉蘭",
			workerNameEn: "何玉蘭",
			workerNameZh: "何玉蘭",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "G. Eng.",
			tradeZh: "G. Eng.",
			country: "",
			trade: "G. Eng.",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "36",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "鍾家強",
			workerNameEn: "鍾家強",
			workerNameZh: "鍾家強",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "Sr. Eng",
			tradeZh: "Sr. Eng",
			country: "",
			trade: "Sr. Eng",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "42",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "張偉",
			workerNameEn: "張偉",
			workerNameZh: "張偉",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "Sr. Eng",
			tradeZh: "Sr. Eng",
			country: "",
			trade: "Sr. Eng",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "40",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "楊正雄",
			workerNameEn: "楊正雄",
			workerNameZh: "楊正雄",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "Eng.",
			tradeZh: "Eng.",
			country: "",
			trade: "Eng.",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "27",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "孫詠晴",
			workerNameEn: "孫詠晴",
			workerNameZh: "孫詠晴",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "G. Eng.",
			tradeZh: "G. Eng.",
			country: "",
			trade: "G. Eng.",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "33",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "高一諾",
			workerNameEn: "高一諾",
			workerNameZh: "高一諾",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "G. Eng.",
			tradeZh: "G. Eng.",
			country: "",
			trade: "G. Eng.",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "35",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "好運工程有限公司",
			workerId: "",
			workerName: "劉欣怡",
			workerNameEn: "劉欣怡",
			workerNameZh: "劉欣怡",
			workerPhoneNo: "",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			tradeEn: "Sr. Eng",
			tradeZh: "Sr. Eng",
			country: "",
			trade: "Sr. Eng",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "31",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "吳子軒",
			workerNameEn: "吳子軒",
			workerNameZh: "吳子軒",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C406",
			tradeZh: "C406",
			country: "",
			trade: "C406",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "26",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "何丹玲",
			workerNameEn: "何丹玲",
			workerNameZh: "何丹玲",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C406",
			tradeZh: "C406",
			country: "",
			trade: "C406",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "28",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "張鳳英",
			workerNameEn: "張鳳英",
			workerNameZh: "張鳳英",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C406",
			tradeZh: "C406",
			country: "",
			trade: "C406",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "36",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "陳志豪",
			workerNameEn: "陳志豪",
			workerNameZh: "陳志豪",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C406",
			tradeZh: "C406",
			country: "",
			trade: "C406",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "29",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "陳大文",
			workerNameEn: "陳大文",
			workerNameZh: "陳大文",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C407b",
			tradeZh: "C407b",
			country: "",
			trade: "C407b",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "43",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "朱平威",
			workerNameEn: "朱平威",
			workerNameZh: "朱平威",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C409",
			tradeZh: "C409",
			country: "",
			trade: "C409",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "27",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "徐宇玉",
			workerNameEn: "徐宇玉",
			workerNameZh: "徐宇玉",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C407b",
			tradeZh: "C407b",
			country: "",
			trade: "C407b",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "39",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "郭浩然",
			workerNameEn: "郭浩然",
			workerNameZh: "郭浩然",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C409",
			tradeZh: "C409",
			country: "",
			trade: "C409",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "36",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "李小明",
			workerNameEn: "李小明",
			workerNameZh: "李小明",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C409",
			tradeZh: "C409",
			country: "",
			trade: "C409",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "48",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "王偉",
			workerNameEn: "王偉",
			workerNameZh: "王偉",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C409",
			tradeZh: "C409",
			country: "",
			trade: "C409",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "43",
			workerImgUrl: "",
			isAuth: true
		}, {
			contractor: "鴻圖建築有限公司",
			workerId: "",
			workerName: "黃婷婷",
			workerNameEn: "黃婷婷",
			workerNameZh: "黃婷婷",
			workerPhoneNo: "",
			contractorEn: "鴻圖建築有限公司",
			contractorZh: "鴻圖建築有限公司",
			tradeEn: "C409",
			tradeZh: "C409",
			country: "",
			trade: "C409",
			zone: "",
			zoneFull: "",
			locationMethod: "",
			checkInTime: "",
			checkInDevice: "",
			lastSeenHub: "",
			lastSeenTime: "",
			seenCount: 0,
			isMachine: "",
			age: "39",
			workerImgUrl: "",
			isAuth: true
		}],
		contractors: [{
			contractor: "好運工程有限公司",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			trade: "好運工程有限公司",
			count: 9,
		}, {
			contractor: "鴻圖建築有限公司",
			contractorEn: "好運工程有限公司",
			contractorZh: "好運工程有限公司",
			trade: "鴻圖建築有限公司",
			count: 11,
		}],
	}],
	contractors: []
}
