import React from 'react'
import '../i18n'
import {useTranslation} from "react-i18next";
import NormalLabel from "./label/normalLabel";
import {Switch} from "@mui/material";
import {useSelector} from "../redux/store";
import {showZeroCountZones} from "../redux/viewSlice";
import {useDispatch} from "react-redux";

const SwitchZeroCountZones: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isShowZeroCountZones = useSelector((state: any) => state.view.showZeroCountZones);
  return (
    <div className='setting-language-div' style={{ marginBottom: 20 }}>
      <NormalLabel title={t('show_empty_zones')} fontSize={'14'} style={{ width: 'fit-content' }} />
      <Switch
        sx={{
          '.MuiSwitch-track': {
            background: 'white',
          },
          '.Mui-checked': {
            color: 'rgb(255, 143, 13) !important',
          },
          '.Mui-checked + .MuiSwitch-track': {
            background: 'rgb(255, 143, 13) !important',
          }
        }}
        defaultChecked={isShowZeroCountZones}
        onClick={() => {
          dispatch(showZeroCountZones(!isShowZeroCountZones));
        }}
      />
    </div>
  );
}

export default SwitchZeroCountZones