import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import tc from "./locales/tc/translation.json";
// import sc from "./locales/sc/translation.json"

const resources = {
	en,
	tc,
}

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		defaultNS: "common",
		fallbackLng: "en",
	});