import React, { useEffect, useState } from 'react'
import NormalLabel from '../label/normalLabel';
import AppIconButton from '../button/IconButton';
import ButtonModel from '../button/buttonModel';
import IconClose from '../../assests/images/icon-close.png';
import Paging from '../paging';
import { AppService } from '../../services/appService';
import { showLoading } from '../../redux/viewSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RefreshToken } from '../../services/authenticationService';
import { CHUNK_SIZE } from '../../model/constants';
import { AlertTableModel } from '../../model/alertTable/alertTableModel';
// import { Hidden } from '@mui/material';
import { SampleAlertTableList } from '../../testing/sampleData';

import '../../i18n'
import { useTranslation } from "react-i18next";
import OuterLineButton from '../button/outerLineButton';
import { ConfirmDialog } from '../dialog';

const AlertTableDetail: React.FC<{
	appService: AppService,
	projectId: string,
	projectUid: string,
	isAlertWarning: boolean,
	isDemo?: boolean,
	canDeleteMsg?: boolean,
	deleteAllMessageHandle: () => void,
	closeCallBack: React.MouseEventHandler
}> = ({ appService, projectId, projectUid, isDemo = false, canDeleteMsg = false, deleteAllMessageHandle, closeCallBack, isAlertWarning }) => {

	const { t, i18n } = useTranslation()

	const [alertTableList, setAlertTableList] = useState<AlertTableModel>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [pageIndex, setpageIndex] = useState(1);
	const [filterType, setFilterType] = useState('All');
	const [filterPeriod, setFilterPeriod] = useState('All');

	const [isOpenComfirm, setIsOpenConfirm] = useState(false);

	// const groupAlertMessageList = (alertTableList?.data ?? [])?.map((value, index) => {
	// 	return index % CHUNK_SIZE === 0 ? (alertTableList?.data ?? []).slice(index, index + CHUNK_SIZE) : null
	// }).filter((e) => { return e });

	useEffect(() => {
		if (isDemo) {
			setAlertTableList(SampleAlertTableList)
		} else {
			getAlertTable()
		}
	}, [pageIndex, filterType, filterPeriod])

	const getAlertTable = () => {
		dispatch(showLoading(true));
		appService.dashboardService
			.getAlertTable({
				"projectID": projectId,
				"projectUID": projectUid,
				'type': filterType !== 'All' ? filterType : undefined,
				'period': filterPeriod !== 'All' ? filterPeriod : undefined,
				"page": Math.max(0, pageIndex - 1),
				"pageSize": 20
			})
			.subscribe(
				(value: any) => {
					setAlertTableList(value.data.data);
					dispatch(showLoading(false));
				},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							getAlertTable();
						},
						() => {
							setOpen(true);
							dispatch(showLoading(false));
						});
				}
			);
	}

	const deleteAllMessage = () => {
		dispatch(showLoading(true));
		appService.dashboardService
			.deleteAllMessage({
				"projectID": projectId,
			})
			.subscribe(
				(value: any) => {
					dispatch(showLoading(false));
					deleteAllMessageHandle();
					getAlertTable();
				},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							getAlertTable();
						},
						() => {
							setOpen(true);
							dispatch(showLoading(false));
						});
				}
			);
	}

	const buttonModel: ButtonModel = {
		title: '',
		onClick: closeCallBack,
		style: {}
	}

	const deleteBtnModel: ButtonModel = {
		title: t('home_alert_table_delete_all'),
		onClick: () => {
			if ((alertTableList?.data.length ?? 0) > 0 && !isAlertWarning) {
				setIsOpenConfirm(true);
			}
		},
		style: {
			width: "160px",
			height: '40px',
			marginRight: "10px",
			opacity: (alertTableList?.data.length ?? 0) === 0 || isAlertWarning ? 0.5 : 1,
			cursor: (alertTableList?.data.length ?? 0) === 0 || isAlertWarning ? "unset" : "pointer"
		}
	}

	const isEng = i18n.language.includes("en")

	const handleClose = () => {
		setIsOpenConfirm(false);
	};

	const alertFilterOptions = [
		{
			label: t('home_alert_table_show_all'),
			value: 'All',
		},
		{
			label: t('home_alert_control_zone'),
			value: 'unauthorized-entry',
		},
		{
			label: t('home_alert_permit'),
			value: 'e-permit',
		},
		{
			label: t('home_alert_inspection'),
			value: 'ai-cam',
		},
		{
			label: t('home_alert_worker_status'),
			value: 'worker-health',
		},
		{
			label: t('home_alert_gas_sensor'),
			value: 'gas-sensor',
		},
		{
			label: t('home_alert_e_lock'),
			value: 'e-lock',
		}
	];
	const periodFilterOptions = [
		{
			label: t('home_alert_table_show_all_period'),
			value: 'All',
		},
		{
			label: t('home_alert_table_show_past_7_days'),
			value: 'last-7-days',
		},
		{
			label: t('home_alert_table_show_past_14_days'),
			value: 'last-14-days',
		},
	]
	return (
		<div className='div-box-container-dark-normal' style={{ border: '2px solid rgba(255,143,13,1)' }}>
			<div className='full-size-flex-div' style={{ flexDirection: 'column' }}>
				<div className='zone-detail-table-header '>
					<div className='full-size-flex-div' style={{ width: 'auto' }}>
						<NormalLabel title={t("alert_table_title") + ' - '} fontSize={'20'} style={{ whiteSpace: 'nowrap' }} />

						<div style={{position: 'relative', padding: '16px 10px', display: 'flex', alignItems: 'center'}}>
							<div>
								<NormalLabel title={alertFilterOptions.find(x => x.value === filterType)?.label || '-'} fontSize={'20'} style={{ whiteSpace: 'nowrap' }}/>
							</div>
							<div style={{marginLeft: 6, transform: 'translate(0, -3px)'}}>
								<svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.5 17L0.210582 0.499997L28.7894 0.5L14.5 17Z" fill="white"/>
								</svg>
							</div>
							<select
								value={filterType}
								onChange={e => setFilterType(e.target.value)}
								style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', fontSize: 24}}
							>
								{alertFilterOptions.map(x => (
									<option value={x.value}>{x.label}</option>
								))}
							</select>
						</div>

						<div style={{position: 'relative', padding: '16px 10px', display: 'flex', alignItems: 'center'}}>
							<div>
								<NormalLabel title={periodFilterOptions.find(x => x.value === filterPeriod)?.label || '-'} fontSize={'20'} style={{ whiteSpace: 'nowrap' }}/>
							</div>
							<div style={{marginLeft: 6, transform: 'translate(0, -3px)'}}>
								<svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.5 17L0.210582 0.499997L28.7894 0.5L14.5 17Z" fill="white"/>
								</svg>
							</div>
							<select
								value={filterPeriod}
								onChange={e => setFilterPeriod(e.target.value)}
								style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', fontSize: 24}}
							>
								{periodFilterOptions.map(x => (
									<option value={x.value}>{x.label}</option>
								))}
							</select>
						</div>
					</div>
					<div style={{ width: '90%', justifyContent: 'flex-end', alignContent: 'flex-end', display: 'flex' }}>
						{
							canDeleteMsg ? <OuterLineButton viewModel={deleteBtnModel} /> : <></>
						}
						<AppIconButton viewModel={buttonModel} image={IconClose} />
					</div>
				</div>
				<div className='paging-container-div' style={{ marginTop: '5px' }}>
					<div>
						<div className='paging-div' >
							{
								(alertTableList?.data ?? []).map((value, index) => {
									return (
										<div
											key={index}
											style={{
												paddingTop: '12px',
												paddingBottom: '12px'
											}}
										>
											<div style={{ display: 'flex' }}>
												<div >
													{
														<div
															className='circle-div'
														/>
													}
												</div>
												<div>
													{/* <div style={{ overflow: 'hidden', width: '40vw', display: 'flex' }}>
														<div>
															<NormalLabel title={getAlertMessageText(value)} fontSize='14' key={index} />
														</div>
														<div style={{ paddingLeft: '10px' }}>
															<NormalLabel title={value.zone} fontSize='16' key={`${value?.zone ?? ""}${index}`} textColor={"rgba(242, 151, 72, 1)"} />
														</div>
													</div> */}
													<p style={{ color: 'white', fontSize: '16px', whiteSpace: 'pre-wrap' }}>
														<div dangerouslySetInnerHTML={{
															__html: isEng ? value.body.en :
																value.body.tc
														}} />
													</p>
													<div style={{ height: '16px' }}></div>
												</div>
											</div>
											<div>
												<div className='div-spacing-zone-line' />
											</div>
										</div>
									)
								})
							}
						</div>
						<Paging
							pageSize={Math.ceil((alertTableList?.totalCount ?? 0) / 20)}
							onPageClick={(index) => {
								setpageIndex(index);
							}}
						/>
					</div>
				</div>
				<ConfirmDialog
					dialogProps={{
						title: t("home_alert_table_delete_dialog_content"),
						content: '',
						open: isOpenComfirm,
						btnTitle: t("home_filter_confirm_button"),
						btnCancel: t('home_filter_cancel_button'),
						handleClose: handleClose
					}}
					confirmCallBack={() => {
						if ((alertTableList?.data.length ?? 0) > 0 && !isAlertWarning) {
							deleteAllMessage();
						}
					}}
				/>
			</div>
		</div >
	)
}

export default AlertTableDetail

function setOpen(arg0: boolean) {
	throw new Error('Function not implemented.');
}
function dispatch(arg0: any) {
	throw new Error('Function not implemented.');
}

