import { createSelector } from '@reduxjs/toolkit';
import { MapWorker } from '../../model/gisMap/gisMapModel';
import { RootState } from '../store';

export const selectGisLoading = (state: RootState) => state.gisMap.gisLoading;

export const selectMapProjectIsGis = (state: RootState) => state.gisMap.projectInfo.isGIS;

export const selectMapProjectConfig = (state: RootState) => state.gisMap.projectInfo;

export const selectMapWorkers = (state: RootState) => state.gisMap.mapWorkers;
export const selectMapWorkersObj = createSelector([selectMapWorkers], (mapWorkers) => {
  const result: Record<string, MapWorker> = {};
  mapWorkers.forEach((worker) => {
    result[worker.id] = worker;
  });
  return result;
});

export const selectMapZone = (state: RootState) => state.gisMap.mapZone;
export const selectMapGeoFences = (state: RootState) => state.gisMap.mapGeoFences;
