import { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Box, Tooltip, Typography } from '@mui/material';
import { SitemapAnnotations, Zone } from '../../model/home/homePageModel';
import controlZonepinImage from '../../assests/images/site-map-control-zone-pin.png';
import controlZonepinImageOrange from '../../assests/images/site-map-control-zone-pin-orange.png';
import React from 'react';
import { ControlZone } from '../../model/project/projectModel';
import {useTranslation} from "react-i18next";
import {useSelector} from "../../redux/store";

const maxScale = window.innerWidth <= 1440 ? 0.32 : window.innerWidth >= 2560 ? 0.57 : window.innerWidth >= 1920 ? 0.41 : window.innerWidth >= 1680 ? 0.38 : 0.41
const initX = window.innerWidth <= 1440 ? 6 : window.innerWidth >= 2560 ? 19 : window.innerWidth >= 1920 ? 32 : window.innerWidth >= 1680 ? 0 : 32
const initY = window.innerWidth <= 1440 ? 9 : window.innerWidth >= 2560 ? 0 : window.innerWidth >= 1920 ? 0 : window.innerWidth >= 1680 ? 12 : 0

export interface Coordinate {
  x: number,
  y: number
}

const ZoomPanSitemap: React.FC<{
  mainContentViewType?: string, // default \ permit | ai-cam | gas-sensor
  permitOverview?: { [key: string]: number } | null,
  aiCamAlertOverview?: { [key: string]: number } | null,
  gasSensorOverview?: { [key: string]: number } | null,
  eLockOverview?: { [key: string]: number } | null,
  micSummaryZones?: { [key: string]: number } | null | undefined,
  zones: Zone[], // [{ name: 'Zone 1', value: 'Zone 1' }, { name: 'Zone 2', value: 'Zone 2' }]
  controlZone: ControlZone[],
  imageUrl: string,
  annotations: SitemapAnnotations[], // [{ x: 0, y: 0, zoneId: 'Zone 1' }, { x: 1, y: 1, zoneId: 'Zone 2' }]
  onClickImage: (coordinate: Coordinate) => void,
  onClickAnnotationOfIndex: (index: number, zoneName: string) => void,
}> = ({
    mainContentViewType, permitOverview, aiCamAlertOverview, gasSensorOverview, eLockOverview, micSummaryZones, zones, imageUrl, controlZone, annotations, onClickImage, onClickAnnotationOfIndex
}) => {
    const {t} = useTranslation()
    const isShowMic = useSelector((state: any) => state.view.showMic);
    // To handle separation of mouseEvent - click & pan
    const [sitemapImageCoordinate, setSitemapImageCoordinate] = useState<number[]>();
    const [sitemapImageMoved, setSitemapImageMoved] = useState(false);

    let controlZoneName = controlZone.map(e => e.zone)
    if (isShowMic) {
      const combinedZones = [
        ...micSummaryZones ? Object.keys(micSummaryZones) : [],
        ...controlZone.map(e => e.zone),
        ...zones.map(x => x.name),
      ]
      controlZoneName = combinedZones
        .filter((x, i, self) => self.indexOf(x) === i)
        .filter(x => !!x);
    }

    const handleSitemapImageOnMouseDown = (e: any) => {
      const { offsetX, offsetY } = e.nativeEvent;
      setSitemapImageCoordinate([offsetX, offsetY]);
    };

    const handleSitemapImageOnMouseDownAndMove = () => {
      if (sitemapImageCoordinate) {
        setSitemapImageMoved(true);
      }
    };

    const handleSitemapImageOnMouseUp = (e: any) => {
      const { offsetX, offsetY } = e.nativeEvent;
      if (sitemapImageCoordinate && !sitemapImageMoved) {
        const [_x, _y] = sitemapImageCoordinate;
        if (offsetX === _x && offsetY === _y) {
          onClickImage && onClickImage({
            x: _x,
            y: _y
          });
        }
      }
      setSitemapImageCoordinate([]);
      setSitemapImageMoved(false);
    };

    const getNormalZoneCount = (zoneId: string) => {
      if (isShowMic) {
        return `${micSummaryZones?.[zoneId] ?? 0}`
      }
      if (mainContentViewType === 'permit') {
        if (permitOverview != null) {
          return `${permitOverview[zoneId] ?? 0}`
        }
      }
      if (mainContentViewType === 'ai-cam') {
        if (aiCamAlertOverview != null) {
          return `${aiCamAlertOverview[zoneId] ?? 0}`
        }
      }
      if (mainContentViewType === 'gas-sensor') {
        if (gasSensorOverview != null) {
          return `${gasSensorOverview[zoneId] ?? 0}`
        }
      }
      if (mainContentViewType === 'e-lock') {
        if (eLockOverview != null) {
          return `${eLockOverview[zoneId] ?? 0}`
        }
      }
      if (zones.length > 0) {
        const _zoneInfo = zones.filter(e => (e.name ?? "").includes(zoneId))[0] as Zone
        if (_zoneInfo != null) {
          return `${_zoneInfo.count ?? 0}`
        }
      }
      return "0"
    }

    const getControlZoneCount = (zoneId: string) => {
      if (isShowMic) {
        return `${micSummaryZones?.[zoneId] ?? 0}`
      }
      if (mainContentViewType === 'permit') {
        if (permitOverview != null) {
          return `${permitOverview[zoneId] ?? 0}`
        }
      }
      if (mainContentViewType === 'ai-cam') {
        if (aiCamAlertOverview != null) {
          return `${aiCamAlertOverview[zoneId] ?? 0}`
        }
      }
      if (mainContentViewType === 'gas-sensor') {
        if (gasSensorOverview != null) {
          return `${gasSensorOverview[zoneId] ?? 0}`
        }
      }
      if (mainContentViewType === 'e-lock') {
        if (eLockOverview != null) {
          return `${eLockOverview[zoneId] ?? 0}`
        }
      }
      if (zones.length > 0) {
        const _zoneInfo = controlZone.filter(e => (e.zone ?? "").includes(zoneId))[0] as ControlZone
        if (_zoneInfo != null) {
          return `${_zoneInfo.authorizedWorkers.count ?? 0}/${_zoneInfo.unauthorizedWorkers.count ?? 0}`
        }
      }
      return "0/0"
    }

    const getControlPinImage = (zoneId: string) => {
      if (zones.length > 0) {
        const _zoneInfo = controlZone.filter(e => (e.zone ?? "").includes(zoneId))[0] as ControlZone
        if (_zoneInfo != null && _zoneInfo.unauthorizedWorkers.count > 0) {
          return controlZonepinImageOrange
        }
      }
      return controlZonepinImage
    }

    return (
      <Box
        sx={{
          position: 'relative',
          '& .react-transform-wrapper': {
            width: '100% !important',
            maxHeight: '65vh !important',
          },
        }}
      >
        <TransformWrapper
          initialScale={maxScale}
          initialPositionX={initX}
          initialPositionY={initY}
          minScale={maxScale}
          wheel={{ disabled: true }}
          doubleClick={{ disabled: true }}
        // centerOnInit
        >
          {({ state: { scale }, zoomIn, zoomOut, resetTransform }) => (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: 12,
                  right: 12,
                  zIndex: 2,
                }}
              >
                <Box
                  component="button"
                  onClick={() => zoomIn()}
                  sx={{
                    padding: '6px 12px',
                    background: 'white',
                    border: '1px solid grey',
                    borderRadius: '5px',
                    fontSize: '6px',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="button">{t('zoomIn')} +</Typography>
                </Box>
                <Box
                  component="button"
                  onClick={() => zoomOut()}
                  sx={{
                    padding: '6px 12px',
                    background: 'white',
                    border: '1px solid grey',
                    borderRadius: '5px',
                    marginLeft: '10px',
                    fontSize: '6px',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="button">{t('zoomOut')} -</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  position: 'relative',
                }}
                onMouseDown={handleSitemapImageOnMouseDown}
                onMouseMove={handleSitemapImageOnMouseDownAndMove}
                onMouseUp={handleSitemapImageOnMouseUp}
              >
                <TransformComponent>
                  {
                    (imageUrl != null && imageUrl.length > 0) ? <img src={imageUrl} style={{ width: '100% !important', height: '100% !important' }} alt="" /> : <div style={{ width: '100% !important', height: '100% !important' }}></div>
                  }
                  {
                    annotations.map(({ x, y, zoneId }, index) => (
                      <Tooltip
                        key={`${x},${y}`}
                        title={(zoneId ?? '')}
                      >
                        {
                          controlZoneName.indexOf(zoneId) > -1 ? <Box
                            key={zoneId}
                            onMouseDown={e => e.stopPropagation()}
                            onMouseUp={e => e.stopPropagation()}
                            onClick={() => {
                              console.log(zoneId);
                              onClickAnnotationOfIndex(index, zoneId);
                            }}
                            sx={{
                              position: 'absolute',
                              left: x,
                              top: y,
                              transform: `
                              translate(-${1 / scale * 50}px, -${1 / scale * 80}px)
                          `,
                              justifyContent: 'center',
                              alignContent: 'center',
                              width: `${(1 / scale) * 105}px`,
                              height: `${(1 / scale) * 80}px`,
                              backgroundImage: `url(${getControlPinImage(zoneId)})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                            }}
                          >
                            < Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transform: `
                                scale(${1 / scale}) 
                            `,
                                width: `${(1 / scale) * 105}px`,
                                height: `${(1 / scale) * 80}px`,
                              }}
                            >
                              <ZonePinView
                                zoneName={zoneId ?? ''}
                                zoneCount={getControlZoneCount(zoneId)}
                              />
                            </Box>
                          </Box> : getNormalZoneCount(zoneId) !== "0" ? <Box
                            key={zoneId}
                            onMouseDown={e => e.stopPropagation()}
                            onMouseUp={e => e.stopPropagation()}
                            onClick={() => {
                              onClickAnnotationOfIndex && onClickAnnotationOfIndex(index, zoneId);
                            }}
                            sx={{
                              position: 'absolute',
                              left: x,
                              top: y,
                              transform: `
                            translate(-${1 / scale * 50}px, -${1 / scale * 110}px)
                            rotate(-${controlZoneName.indexOf(zoneId) > -1 ? "0" : "45"}deg)
                            `,
                              justifyContent: 'center',
                              alignContent: 'center',
                              width: `${(1 / scale) * 90}px`,
                              height: `${(1 / scale) * 90}px`
                            }}
                            className='site-map-pin-marker'
                          >
                            < Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transform: `
                                  scale(${1 / scale})
                                  rotate(45deg)
                              `,
                                width: `${(1 / scale) * 90}px`,
                                height: `${(1 / scale) * 90}px`,
                              }}
                            >
                              <ZonePinView
                                zoneName={zoneId ?? ''}
                                zoneCount={getNormalZoneCount(zoneId)}
                              />
                            </Box>
                          </Box> : <></>
                        }
                      </Tooltip>
                    ))
                  }
                </TransformComponent>
              </Box>
            </>
          )}
        </TransformWrapper>
      </Box >
    );
  };

export default ZoomPanSitemap;

const ZonePinView: React.FC<{
  zoneName: string,
  zoneCount: string
}> = ({ zoneName, zoneCount }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center'
      }}
    >
      <Typography
        variant="body1"
      >
        {zoneName}
      </Typography>
      <Typography
        variant="body1"
      >
        {zoneCount}
      </Typography>
    </Box>
  )
}