import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Logo from '../assests/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { login, setProject } from "../redux/userSlice";
import { MessageDialog } from '../components/dialog';
import { AppService } from '../services/appService';
import { showFilter, showLoading } from '../redux/viewSlice';
import { DEFAULT_FILTER } from '../model/constants';
import { filter } from '../redux/filterSlice';

import '../i18n'
import { useTranslation } from "react-i18next";

type UserSubmitForm = {
	username: string;
	password: string;
};

const Login: React.FC<{ appService: AppService }> = ({ appService }) => {

	const { t } = useTranslation()

	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const handleClose = () => {
		setOpen(false);
	};

	const validationSchema = Yup.object().shape({
		username: Yup.string().required(t('login_enter_user_name')),
		password: Yup.string().required(t('login_enter_password'))
	});

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<UserSubmitForm>({
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data: UserSubmitForm) => {
		dispatch(showLoading(true));
		dispatch(showFilter(false));
		await callLogin({
			username: data['username'],
			password: data['password']
		});
	};

	async function callLogin({ username, password }: { username: string; password: string; }) {
		const loginFormData = {
			"username": username,
			"password": password,
		};
		appService.authentication
			.login(loginFormData)
			.subscribe(
				(value) => {
					dispatch(setProject(null));
					dispatch(filter(DEFAULT_FILTER))
					dispatch(login(true));
					navigate('/project');
					dispatch(showLoading(false));
					localStorage.setItem('uid', username);
					return true;
				},
				(error) => {
					dispatch(showLoading(false));
					setOpen(true);
					return false;
				}
			);
	};

	return (
		<Container fluid className='main-container'>
			<div className='login-div'>
				<div>
					<img src={Logo} alt='brand logo' />
				</div>
				<div style={{ height: 84 }} />
				<form onSubmit={handleSubmit(onSubmit)} method='POST'>
					<label className='login-label'>
						{t('login_user_name')}
					</label>
					<br />
					<input
						{...register('username')}
						className={`${errors.username ?
							'login-input-error' :
							'login-input'}`}
						type="text"
						name="username"
					/>
					<br />
					<span className='login-input-error-label'>
						{errors.username?.message}
					</span>

					<div style={{ height: 40 }} />
					<label className='login-label'>
						{t('login_password')}
					</label>
					<br />
					<input
						{...register('password')}
						className={`${errors.username ?
							'login-input-error' :
							'login-input'}`}
						type="password"
						name="password"
					/>
					<br />
					<span className='login-input-error-label'>
						{errors.password?.message}
					</span>
					<div style={{ height: 40 }} />
					<label className='login-label-password-hint'>
						{t('login_forgot_pw')}
					</label>
					<div style={{ height: 50 }} />
					<input
						className='login-button'
						type="submit"
						value={t('login_button')}
					/>
				</form>
			</div>
			<MessageDialog
				dialogProps={{
					title: t('login_alert_wrong_title'),
					content: t('login_alert_wrong_message'),
					open: open,
					btnTitle: t('login_alert_button'),
					handleClose: handleClose
				}}
			/>
		</Container>
	);
}

export default Login;