import React, {useCallback, useEffect, useState} from 'react'
import {Zone} from "../../model/home/homePageModel";
import {ControlZone, ProjectInfo} from "../../model/project/projectModel";
import {useTranslation} from "react-i18next";
import NormalLabel from "../label/normalLabel";
import AppIconButton from "../button/IconButton";
import IconClose from "../../assests/images/icon-close.png";
import {AppService} from "../../services/appService";
import {parseISO} from "rsuite/cjs/utils/dateUtils";
import moment from "moment/moment";
import {Switch} from "@mui/material";
import {useSelector} from "../../redux/store";
import ZoneListForSensor from "./zoneListForSensor";

interface GasSensor {
  projectId: string,
  zoneName: string,
  deviceId: string,
  deviceType: string,
  deviceName: string,
  deviceInternalName: string,
  distinctSensorTypes: string[],
  sensors: {
    type: string,
    unit: string,
    reading: string,
    date: string,
  }[],
}

const GasSensorZoneList: React.FC<{
  appService: AppService,
  projectID: string,
  gasSensorOverview?: { [key: string]: number } | null,
  gasSensors?: any[],
  envSensors?: any[],
  zoneList: Zone[],
  controlZoneList: ControlZone[],
  isShowZeroCountZones: boolean,
  onChangeViewType?: (viewType: string) => void
}> = ({ appService, projectID, gasSensorOverview, gasSensors, envSensors, zoneList, controlZoneList, isShowZeroCountZones, onChangeViewType }) => {
  const { t } = useTranslation()
  const project = useSelector((state: any) => state.user.project as ProjectInfo);
  const [selectedZoneName, setSelectedZoneName] = useState<string|null>();
  const [isSelectedZoneDenyAllWorkers, setIsSelectedZoneDenyAllWorkers] = useState<boolean|null>();
  const [zoneGasSensors, setZoneGasSensors] = useState<GasSensor[]|null>();
  
  console.log('gasSensors', gasSensors)
  console.log('envSensors', envSensors)

  const gasSensorOverviewJson = gasSensorOverview ? JSON.stringify(gasSensorOverview) : null;
  useEffect(() => {
    if (selectedZoneName) {
      appService.dashboardService
        .getTodayProjectZoneGasSensorList({
          projectID, zoneName: selectedZoneName
        })
        .subscribe(
          (value: any) => {
            setZoneGasSensors(value.data.data);
          }
        );
    }
  }, [gasSensorOverviewJson, selectedZoneName]);
  
  const toggleZoneIsDenyAllWorkers = useCallback((isDenyAllWorkers: boolean) => {
    appService.dashboardService
      .toggleControlZoneIsDenyAllWorkers({
        "projectID": project.projectID,
        "zoneName": selectedZoneName,
        isDenyAllWorkers,
      })
      .subscribe(
        (value: any) => {
        },
        (error) => {
        }
      )
  }, [project.projectID, selectedZoneName]);

  const getZoneList = () => {
    return zoneList.filter(x => {
      const gasSensor = gasSensors?.find(y => y.zoneName === x.name);
      const envSensor = envSensors?.find(y => y.zoneName === x.name);
      return gasSensor || envSensor;
    }).map(x => ({
      name: x.name,
      count: 1,
    }) as Zone)
  }

  const getControlZoneList = () => {
    return controlZoneList.filter(x => {
      const gasSensor = gasSensors?.find(y => y.zoneName === x.zone);
      const envSensor = envSensors?.find(y => y.zoneName === x.zone);
      return gasSensor || envSensor;
    }).map(x => ({
      name: x.zone,
      count: 1,
    }) as Zone)
  }

  const getGasSensorTypeReading = (gasSensor: GasSensor, type: string) => {
    const sensor = gasSensor.sensors.find(x => x.type === type);
    if (sensor) {
      const {reading, unit, date} = sensor;
      const dateString = moment(parseISO(date)).format('DD-MM-yyyy  HH:mm:ss')
      return `${reading} ${unit} (${dateString})`;
    }
    return '-';
  }

  return (
    <>
      {selectedZoneName ? (
        <div style={{ width: '100%', height: '62vh', padding: '15px 20px 20px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <NormalLabel title={selectedZoneName} fontSize={'20'} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <NormalLabel title={`Emergency Evacuation`} fontSize={'14'} style={{ width: 'fit-content' }} />
                <Switch
                  sx={{
                    '.MuiSwitch-track': {
                      background: 'white',
                    },
                    '.Mui-checked': {
                      color: 'rgb(255, 143, 13) !important',
                    },
                    '.Mui-checked + .MuiSwitch-track': {
                      background: 'rgb(255, 143, 13) !important',
                    }
                  }}
                  checked={isSelectedZoneDenyAllWorkers ?? false}
                  onClick={() => {
                    setIsSelectedZoneDenyAllWorkers(!isSelectedZoneDenyAllWorkers);
                    toggleZoneIsDenyAllWorkers(!isSelectedZoneDenyAllWorkers)
                  }}
                />
              </div>
              <AppIconButton
                viewModel={{
                  onClick: () => setSelectedZoneName(null),
                }}
                image={IconClose}
              />
            </div>
          </div>
          <div style={{ marginTop: 12, flex: 1, backgroundColor: 'black', borderRadius: 8, padding: 15, overflow: 'auto' }}>
            <table className="ai-cam-alert-table">
              <thead>
              <tr>
                <th><NormalLabel title={'Device'} fontSize={"18"} /></th>
                <th><NormalLabel title={'Readings'} fontSize={"18"} /></th>
              </tr>
              </thead>
              <tbody>
              {zoneGasSensors?.map(gasSensor => (
                <tr key={gasSensor.deviceId}>
                  <td>
                    <NormalLabel title={gasSensor.deviceInternalName} fontSize={"18"} lineHeight="1.3" />
                    <NormalLabel title={`(${gasSensor.deviceId})`} fontSize={"18"} lineHeight="1.3" />
                  </td>
                  <td>
                    {['CO2', 'CO', 'O2', 'H2S', 'LEL-MPS'].map(type => (
                      <NormalLabel title={`${type}: ${getGasSensorTypeReading(gasSensor, type)}`} fontSize={"18"} lineHeight="1.3" />
                    ))}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          <div className='home-bottom-zone-div'>
            <ZoneListForSensor
              contentTitle={t('home_confined_space')}
              contentType={'gas-sensor'}
              title={t('home_working_zone')}
              selectedZone={undefined}
              zoneList={getZoneList()}
              gasSensors={gasSensors}
              envSensors={envSensors}
              lastUpdateTime={null ?? ""}
              isControlZone={false}
              onChangeViewType={onChangeViewType}
              onItemClickHandle={x => {
                // setSelectedZoneName(x.name)
              }}
              mutedList={[]}
            />
          </div>
          <div className='home-bottom-zone-div'>
            <ZoneListForSensor
              contentType={'gas-sensor'}
              title={t('home_controlled_zone')}
              selectedZone={undefined}
              zoneList={getControlZoneList()}
              gasSensors={gasSensors}
              envSensors={envSensors}
              lastUpdateTime={null ?? ""}
              isControlZone={true}
              onItemClickHandle={x => {
                // setSelectedZoneName(x.name)
                // const controlZone = controlZoneList.find(y => y.zone === x.name);
                // if (controlZone) {
                //   setIsSelectedZoneDenyAllWorkers(controlZone.isDenyAllWorkers);
                // }
              }}
              mutedList={[]}
            />
          </div>
        </>
      )}
    </>
  )
}

export default GasSensorZoneList