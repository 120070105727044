import React, { useState } from 'react'
import NormalLabel from '../label/normalLabel';
import AppIconButton from '../button/IconButton';
import ButtonModel from '../button/buttonModel';
import IconClose from '../../assests/images/icon-close.png';
import { GroupedPresentWorkers } from '../../model/home/homePageModel';
import Paging from '../paging';
import { CHUNK_SIZE } from '../../model/constants';
import '../../i18n'
import { useTranslation } from "react-i18next";

const WorkInZoneDetail: React.FC<{
	groupedList: GroupedPresentWorkers[],
	closeCallBack: React.MouseEventHandler
}> = ({ groupedList, closeCallBack }) => {

	const { t, i18n } = useTranslation()

	const buttonModel: ButtonModel = {
		title: '',
		onClick: closeCallBack,
		style: {}
	}

	const [pageIndex, setpageIndex] = useState(1);

	const groupConstractorsList = (groupedList ?? [])?.map((value, index) => {
		return index % CHUNK_SIZE === 0 ? groupedList.slice(index, index + CHUNK_SIZE) : null
	}).filter((e) => { return e });

	const isEng = i18n.language.includes("en")

	return (
		<div
			className='div-box-container-dark-normal'
			style={{ width: '100%', height: '100%', paddingLeft: '30px', paddingRight: '30px' }}
		>
			<div className='full-size-flex-div' style={{ flexDirection: 'column' }}>
				<div className='full-size-flex-div' >
					{/* <div className='full-size-flex-div' >
						<NormalLabel
							title={`(${detail.time ?? "-"}${getTimeString(detail.time ?? "00:00:00")}) 在場人數`}
							fontSize={'18'}
						/>
					</div>
					<div className='full-size-flex-div'>
						<NormalLabel
							title={`${detail.count}`}
							fontSize={'32'}
						/>
					</div> */}
					<div style={{ width: '100%', justifyContent: 'flex-end', alignContent: 'flex-end', display: 'flex' }}>
						<AppIconButton viewModel={buttonModel} image={IconClose} />
					</div>
				</div>
				<div
					className='paging-container-div'
					style={{ marginTop: '5px', paddingTop: '20px' }}
				>
					<div className='zone-detail-table-header '>
						<div style={{ flex: '4' }}>
							<NormalLabel title={t('home_contractor_worker_count')} fontSize={'14'} />
						</div>
						<div style={{ flex: '2', textAlign: 'right' }}>
							<NormalLabel title={t('home_now_worker_present')} fontSize={'14'} />
						</div>
						<div style={{ flex: '2', textAlign: 'right' }}>
							<NormalLabel title={t('home_09_worker_present')} fontSize={'14'} />
						</div>
						<div style={{ flex: '2', textAlign: 'right' }}>
							<NormalLabel title={t('home_02_worker_present')} fontSize={'14'} />
						</div>
					</div>
					<div style={{ height: '11px' }} />
					<div className='div-spacing-zone-line' />
					<div className='paging-div'>
						{
							(groupConstractorsList[pageIndex - 1] ?? [])?.map((value, index) => {
								return (
									<div key={index} style={{ paddingTop: '15px', paddingBottom: '15px', display: 'flex' }}>
										<div style={{ whiteSpace: 'pre-line', marginRight: '5px', flex: '4' }}>
											<NormalLabel
												title={isEng ?
													(value.nowContractors?.contractorEn ?? value.morningContractors?.contractorEn ?? value.afternoonContractors?.contractorEn ?? "") :
													(value.nowContractors?.contractorZh ?? value.morningContractors?.contractorZh ?? value.afternoonContractors?.contractorZh ?? "")
												}
												fontSize={'14'} />
										</div>
										<div style={{ textAlign: 'right', flex: '2' }}>
											<NormalLabel title={`${value.nowContractors?.count ?? "-"}`} fontSize={'14'} />
										</div>
										<div style={{ textAlign: 'right', flex: '2' }}>
											<NormalLabel title={`${value.morningContractors?.count ?? "-"}`} fontSize={'14'} />
										</div>
										<div style={{ textAlign: 'right', flex: '2' }}>
											<NormalLabel title={`${value.afternoonContractors?.count ?? "-"}`} fontSize={'14'} />
										</div>
									</div>
								)
							})
						}
					</div>
					<div className='div-spacing-zone-line' />
					<Paging
						pageSize={groupConstractorsList?.length ?? 1}
						onPageClick={(index) => {
							setpageIndex(index);
						}}
					/>
				</div>
			</div >
		</div >
	)
}

export default WorkInZoneDetail