import { i18n, TFunction } from "i18next";
import { useEffect, useState } from "react";
import { CHUNK_SIZE } from "../../../model/constants";
import { ZoneItemDetail } from "../../../model/project/projectModel";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";

export const WorkerContent: React.FC<{
	wokerList: (ZoneItemDetail)[],
	isControlZone: boolean,
	t: TFunction<"translation">,
	i18n: i18n
}> = ({ wokerList, isControlZone, t, i18n }) => {

	const [pageIndex, setpageIndex] = useState(1);

	const [sorting, setSorting] = useState({ key: "", aesOrder: false });

	const [workerLists, setWorkerLists] = useState<ZoneItemDetail[]>(wokerList);

	useEffect(() => {
		if (sorting.key.length > 0) {

			const _wokerList: any = [...wokerList]

			_wokerList.sort((a: any, b: any) => {
				return a[sorting.key].localeCompare(b[sorting.key])
			})

			setWorkerLists(sorting.aesOrder ? _wokerList : _wokerList.reverse())
		} else {
			setWorkerLists(wokerList);
		}

	}, [sorting])

	const groupWorkerList = (sorting.key.length > 0 ? workerLists : wokerList)?.map((value, index) => {
		return index % CHUNK_SIZE === 0 ?
			(sorting.key.length > 0 ? (workerLists ?? []) : wokerList).slice(index, index + CHUNK_SIZE) : null
	}).filter((e) => { return e });

	const setSortingHandle = (key: string) => {
		var aesOrder = !sorting.aesOrder;

		if (key !== sorting.key) {
			aesOrder = true;
		}
		setSorting({
			key: key,
			aesOrder: aesOrder
		})
	}

	const getSortArrowByKey = (key: string) => {
		if (key === sorting.key) {
			return !sorting.aesOrder;
		}
		return false;
	}

	const isEng = i18n.language.includes("en")

	return (
		<div className='paging-container-div' style={{ width: '100%' }}>
			<div style={{
				paddingLeft: '30px',
				paddingRight: '30px',
				display: 'flex',
				width: '100%'
			}}
			>
				<div style={{ flex: "2" }} >{/*onClick={() => setSortingHandle(isEng ? "workerNameEn" : "workerNameZh")}>*/}
					{/* <ArrowLabel title={t('home_zone_detail_name')} isUp={getSortArrowByKey(isEng ? "workerNameEn" : "workerNameZh")} /> */}
					<NormalLabel title={t('home_zone_detail_name')} />
				</div>
				<div style={{ flex: "1", paddingLeft: isControlZone ? '10px' : '0px' }} >{/*onClick={() => setSortingHandle("age")}>*/}
					{/* <ArrowLabel title={t('home_zone_detail_worker_age')} isUp={getSortArrowByKey("age")} /> */}
					<NormalLabel title={t('home_zone_detail_worker_age')} />
				</div>
				<div style={{ flex: "1", paddingLeft: isControlZone ? '10px' : '0px' }} > {/* onClick={() => setSortingHandle(isEng ? "tradeEn" : "tradeZh")}> */}
					{/* <ArrowLabel title={t('home_trade')} isUp={getSortArrowByKey(isEng ? "tradeEn" : "tradeZh")} /> */}
					<NormalLabel title={t('home_trade')} />
				</div>
				<div style={{ flex: isControlZone ? '6' : '4' }} > {/* onClick={() => setSortingHandle(isEng ? "contractorEn" : "contractorZh")}> */}
					{/* <ArrowLabel title={t('home_contractor')} isUp={getSortArrowByKey(isEng ? "contractorEn" : "contractorZh")} /> */}
					<NormalLabel title={t('home_contractor')} />
				</div>
				{
					isControlZone ? <div style={{ flex: '1', paddingLeft: '10px' }}>
						<NormalLabel title={t('home_zone_authorisation')} />
					</div> : null
				}

			</div>
			<div style={{ height: '11px' }} />
			<div className='div-spacing-zone-line' />
			<div className='paging-div-full-content'>
				{
					(groupWorkerList[pageIndex - 1] ?? [])?.map((value, index) => {
						return (
							zoneContentTable(
								index,
								isEng ? value.workerNameEn : value.workerNameZh,
								value.age,
								isEng ? value.tradeEn : value.tradeZh,
								isEng ? value.contractorEn : value.contractorZh,
								isControlZone,
								value.isAuth,
								t,
							)
						)
					})
				}
			</div>
			<div className='div-spacing-zone-line' />
			<Paging
				pageSize={groupWorkerList?.length ?? 1}
				onPageClick={(index) => {
					setpageIndex(index);
				}}
			/>
		</div >
	);
}

const zoneContentTable = (
	index: number,
	workerName: string,
	workerAge: string,
	workerTrade: string,
	workerContractor: string,
	isControlZone: boolean,
	isAuth: boolean,
	t: TFunction<"translation">
) => {

	const getColor = (): string => {
		return (!isAuth && isControlZone) ? "rgba(248, 114, 54, 1)" : "rgba(255, 255, 255, 1)"
	}

	return (
		<div
			key={index}
			style={{
				paddingTop: '13px',
				paddingBottom: '13px',
				display: 'flex',
			}}
		>
			<div
				style={{
					flex: "2",
					whiteSpace: 'pre-line',
					overflowWrap: 'anywhere',
				}}
			>
				<NormalLabel title={workerName} textColor={getColor()} />
			</div>
			<div
				style={{
					flex: "1",
					paddingLeft: '10px'
				}}
			>
				<NormalLabel title={workerAge} textColor={getColor()} />
			</div>
			<div
				style={{
					flex: "1",
					whiteSpace: isControlZone ? 'normal' : 'pre-line',
					paddingLeft: '10px'
				}}
			>
				<NormalLabel title={workerTrade} textColor={getColor()} />
			</div>
			<div
				style={{
					flex: '6',
					whiteSpace: isControlZone ? 'normal' : 'pre-line',
					overflowWrap: 'anywhere',
					maxWidth: isControlZone ? '100%' : '50%'
				}}
			>
				<NormalLabel title={workerContractor} textColor={getColor()} />
			</div>
			{
				isControlZone ? <div style={{ flex: '1', paddingLeft: '10px' }}>
					<NormalLabel title={isAuth ? t("home_controlled_zone_authorised") : t("home_controlled_zone_unauthorised")} textColor={getColor()} />
				</div> : null
			}
		</div >
	)
}