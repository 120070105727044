import React from 'react'

const BorderedLabel: React.FC<{
	title: string,
	fontSize?: string,
	textColor?: string,
	borderColor?: string
}> = ({ title, fontSize = '16', textColor, borderColor = "#FF8F0D" }) => {
	return (
		<label
			style={{
				minWidth: "70%",
				fontSize: `${fontSize}px`,
				lineHeight: '100%',
				color: textColor,
				border: `1px solid ${borderColor}`,
				borderRadius: "5px",
				padding: "5px",
				textAlign: "center",
			}}
		>
			{title}
		</label>
	)
}

export default BorderedLabel