import React from 'react'
import { Container } from 'react-bootstrap'
import NormalLabel from '../label/normalLabel'
import '../../i18n'
import { useTranslation } from "react-i18next";
import ButtonModel from "../button/buttonModel";
import OuterLineButton from "../button/outerLineButton";

const TotalWorker: React.FC<{
	workCount: string,
	lastUpdateTime: string,
	countAmLabel?: string,
	countPmLabel?: string,
	countAm?: number,
	countPm?: number,
	btnModel?: ButtonModel
}> = ({ workCount, lastUpdateTime, countAmLabel, countPmLabel, countAm, countPm, btnModel }) => {
	const { t } = useTranslation()

	return (
		<Container
			className='div-box-container'
			style={{ width: '100%', height: '100%' }}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
					padding: '20px',
				}}
			>
				<div>
					<div style={{ padding: '5px 0' }} >
						<NormalLabel title={t("home_total_worker")} />
						<NormalLabel title={`${t("home_updated_time")} ${lastUpdateTime}`} style={{ marginTop: 8 }} />
					</div>
					<div>
						<NormalLabel title={workCount} fontSize={'110'} />
					</div>
				</div>
				<div>
					<div style={{ width: 120 }}>
						<OuterLineButton viewModel={btnModel!} />
					</div>
          <div style={{ width: 200, marginTop: 20 }}>
            <div className='full-size-flex-row-div'>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={countAmLabel ?? "-"} />
              </div>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={`${countAm ?? "-"}`} fontSize={'36'} />
              </div>
            </div>
            <div className='full-size-flex-row-div' style={{ marginTop: 20 }}>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={countPmLabel ?? "-"} />
              </div>
              <div style={{ flex: '2', textAlign: 'center' }}>
                <NormalLabel title={`${countPm ?? "-"}`} fontSize={'36'} />
              </div>
            </div>
          </div>
				</div>
			</div>
		</Container>
	)
}

export default TotalWorker;