import React, {useEffect, useRef, useState} from 'react'
import {EnvSensorModel, GasSensorModel, Zone} from '../../model/home/homePageModel';
import ButtonModel from '../button/buttonModel';
import AppIconButton from '../button/IconButton';
import NormalLabel from '../label/normalLabel';
import IconSearch from '../../assests/images/icon-search.png';
import IconSearchGrey from '../../assests/images/icon-search-grey.png';
import IconMute from '../../assests/images/icon-mute.png';
import {ControlZone} from '../../model/project/projectModel';

import {useDispatch} from "react-redux";
import {useSelector} from "../../redux/store";
import {ProjectFilter} from '../../redux/filterSlice';
import FilterFilledIcon from '../../assests/images/icon-filter-filled.png';
import FilterIcon from '../../assests/images/icon-filter.png';
import IconButton from '../button/IconButton';
import {showProject, showFilterHideOption, showFilter} from "../../redux/viewSlice";
import '../../i18n'
import {useTranslation} from "react-i18next";
import OuterLineButton from '../button/outerLineButton';
import {FILTER_HUMAN, FILTER_MANCHINE} from '../../model/constants';

const ZoneListForSensor: React.FC<{
  contentTitle?: string,
  contentType?: string,
  title: string,
  selectedZone?: Zone|ControlZone,
  zoneList?: Zone[],
  gasSensors?: any[],
  envSensors?: any[],
  controlZoneList?: ControlZone[],
  mutedList?: string[],
  lastUpdateTime: string,
  isControlZone: boolean,
  showAdminBtn?: boolean,
  onChangeViewType?: (viewType: string) => void
  onItemClickHandle: (zone: any) => void
}> = ({contentTitle, contentType, title, selectedZone, zoneList, controlZoneList, gasSensors, envSensors, mutedList, lastUpdateTime, showAdminBtn, isControlZone, onChangeViewType, onItemClickHandle}) =>
{

  const {t} = useTranslation()

  const [isSearch, setIsSearch] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const filter = useSelector((state: any) => state.filter.projectFilter as ProjectFilter);

  const clickSearchHandle = () =>
  {
    setIsSearch(!isSearch);
  }

  const onChangeText = (value: string) =>
  {
    setSearchKey(value.toLowerCase());
  }

  const buttonModel: ButtonModel = {
    title: '',
    onClick: clickSearchHandle,
    style: {}
  }

  const getSelectedStyle = (zoneInfo: Zone) =>
  {
    const _zone = selectedZone as Zone
    if (_zone?.name === zoneInfo.name
      && _zone?.count === zoneInfo.count)
    {
      return 'div-box-container-dark-highlight'
    }
    return 'div-box-container-dark'
  }

  const filterButtonModel: ButtonModel = {
    onClick: () =>
    {
      dispatch(showFilter(false))
      dispatch(showFilterHideOption(true));
      dispatch(showProject(false));
    }
  }

  const handleClickOutside = (event: Event) =>
  {
    if (ref.current && !ref.current.contains(event.target as Node))
    {
      if (isSearch)
      {
        setIsSearch(false)
        setSearchKey('')
      }
    }
  };

  const adminPanelBtn: ButtonModel = {
    title: t('home_admin_panel_title'),
    onClick: () =>
    {
      window.open(process.env.REACT_APP_ADMIN_PANEL_URL, "_blank")
    },
    style: {
      width: '35%',
      height: '40px',
    }
  }

  useEffect(() =>
  {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
    {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div
      style={{width: '100%', height: '100%'}}
    >
      <div className='zone-list-div'>
        <div style={{height: 40}}>
          {!isControlZone && (
            <div style={{display: 'flex',}}>
              <div style={{position: 'relative', padding: '16px 10px', display: 'flex', alignItems: 'center'}}>
                <div>
                  <NormalLabel title={contentTitle ?? ''} fontSize={'20'}/>
                </div>
                <div style={{marginLeft: 6, transform: 'translate(0, -3px)'}}>
                  <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 17L0.210582 0.499997L28.7894 0.5L14.5 17Z" fill="white"/>
                  </svg>
                </div>
                <select
                  value={contentType}
                  onChange={e => onChangeViewType && onChangeViewType(e.target.value)}
                  style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', fontSize: 24}}
                >
                  <option value="default">{t('home_worker_present')}</option>
                  <option value="permit">{t('home_permit')}</option>
                  <option value="ai-cam">{t('home_alert_ai_cam')}</option>
                  <option value="gas-sensor">{t('home_confined_space')}</option>
                  <option value="e-lock">{t('home_alert_e_lock')}</option>
                </select>
              </div>
            </div>
          )}
        </div>
        <div className='zone-list-header-div'>
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: '2'
          }}
          >
            <NormalLabel title={title} fontSize={'14'}/>
            <NormalLabel title={`${t('home_as_of_time')} ${lastUpdateTime}`} fontSize={'14'}/>
          </div>
          {
            (contentType === 'default') &&
            <>
              {
                !isControlZone ? <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: '2',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <div style={{
                    flex: '1',
                    textAlign: 'right'
                  }}>
                    <NormalLabel
                      title={`${filter.filterType === FILTER_HUMAN ?
                        t('home_human_filter_human') :
                        filter.filterType === FILTER_MANCHINE ?
                          t('home_human_filter_machine') :
                          t('home_human_filter_health')}`
                      }
                      fontSize={'16'}
                    />
                  </div>
                  <div style={{
                    flex: '1'
                  }}>
                    <IconButton
                      viewModel={filterButtonModel}
                      image={filter.filterType !== FILTER_HUMAN ?
                        FilterFilledIcon : FilterIcon
                      }
                    />
                  </div>
                </div> : showAdminBtn ? <OuterLineButton viewModel={adminPanelBtn}/> : <></>
              }
            </>
          }
          {
            isSearch ?
              <div
                ref={ref}
                className={
                  searchKey.length > 0 ?
                    'search-div-searching' : 'search-div'
                }
                style={{
                  flex: '2',
                  maxWidth: '170px'
                }}
              >
                <img
                  src={IconSearchGrey}
                  className='img-icon-search'
                  alt=""
                />
                <input
                  placeholder={t('home_search_zone_title')}
                  className='search-input'
                  value={searchKey}
                  onChange={(event) => onChangeText(event.target.value)}
                />
              </div> :
              <div style={{
                flex: '1',
                display: 'flex',
                justifyContent: 'right'
              }}>
                <AppIconButton
                  viewModel={buttonModel}
                  image={IconSearch}
                />
              </ div>
          }
        </div>
        <div>
          <div className='scroll-div'>
            {(zoneList as Zone[])?.length > 0 && zoneList?.filter(zone => {
              return ((zone?.name ?? "").toLowerCase()).includes(searchKey)
            })?.map((value, index) => (
              <div
                key={index}
                onClick={() => {
                  onItemClickHandle(value);
                }}
                className={getSelectedStyle(value)}
                style={{ height: 'auto', paddingTop: 20, paddingBottom: 20 }}
              >
                {normalZoneContent(t, value, gasSensors, envSensors)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const normalZoneContent = (
  t: any,
  zoneModel: Zone,
  gasSensors?: any[],
  envSensors?: any[]
): JSX.Element =>
{
  const gasSensor = gasSensors?.find(x => x.zoneName === zoneModel.name);
  const envSensor = envSensors?.find(x => x.zoneName === zoneModel.name);
  
  const renderTitleCountString = (title: string, countString: string, highlight?: boolean) => (
    <div style={{ padding: 8, width: '50%' }}>
      <div>
        <NormalLabel title={title} fontSize={'14'} lineHeight="1.5" style={{ textAlign: 'center', whiteSpace: 'initial', wordBreak: 'break-word' }} />
        <NormalLabel title={countString} fontSize={'24'} style={{ textAlign: 'center', marginTop: 8, color: highlight ? `rgba(14, 249, 96, 1)` : undefined }} />
      </div>
    </div>
  )
  const renderTitleCount = (title: string, count?: number | null) => {
    return renderTitleCountString(title, count != null ? `${count}` : '-')
  }

  const gasSensorTypes = [
    {label: t('zone_workers_env_condition_co2'), type: 'CO2'},
    {label: t('zone_workers_env_condition_o2'), type: 'O2'},
    {label: t('zone_workers_env_condition_h2s'), type: 'H2S'},
    {label: t('zone_workers_env_condition_co'), type: 'CO'},
    {label: t('zone_workers_env_condition_flammable_gas'), type: 'LEL-MPS'},
  ];
  const getGasSensorTypeReading = (type: string) => {
    if (gasSensor == null) return '-'
    const sensor = (gasSensor as GasSensorModel).sensors.find(y => y.type === type);
    if (sensor) {
      const {reading} = sensor;
      // const dateString = moment(parseISO(date)).format('DD-MM-yyyy  HH:mm:ss')
      // return `${reading} ${unit} (${dateString})`;
      return `${reading}`;
    }
    return '-';
  }
  const envSensorTypes = [
    {label: t('zone_workers_env_condition_temp'), type: 'TEMPERATURE'},
    {label: t('zone_workers_env_condition_humidity'), type: 'HUMIDITY'},
    {label: t('zone_workers_env_condition_pm2_5'), type: 'PM2.5'},
  ];
  const getEnvSensorTypeReading = (type: string) => {
    if (envSensor == null) return '-'
    const sensor = (envSensor as EnvSensorModel).sensors.find(y => y.type === type);
    if (sensor) {
      const {reading} = sensor;
      // const dateString = moment(parseISO(date)).format('DD-MM-yyyy  HH:mm:ss')
      // return `${reading} ${unit} (${dateString})`;
      return `${reading}`;
    }
    return '-';
  }
  return (
    <div className='full-size-flex-div'>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        <div style={{ maxWidth: '30%' }}>
          <div>
            <NormalLabel title={zoneModel.name} fontSize="14" lineHeight="1.5" />
          </div>
          <div>
            <NormalLabel title={`(${gasSensor?.deviceInternalName || '-'})`} fontSize="14" lineHeight="1.5" style={{ whiteSpace: 'initial', wordBreak: 'break-all' }} />
          </div>
          <div>
            <NormalLabel title={`(${envSensor?.deviceInternalName || '-'})`} fontSize="14" lineHeight="1.5" style={{ whiteSpace: 'initial', wordBreak: 'break-all' }} />
          </div>
        </div>
        <div style={{ maxWidth: '70%', paddingLeft: 12 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', margin: -8 }}>
            {envSensorTypes.map(x => (
              renderTitleCountString(x.label, getEnvSensorTypeReading(x.type)))
            )}
            {gasSensorTypes.map(x => (
              renderTitleCountString(x.label, getGasSensorTypeReading(x.type), true))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZoneListForSensor;