import React from 'react'

const ArrowLabel: React.FC<{
	title: string,
	isUp: boolean,
	fontSize?: string,
	textColor?: string,
}> = ({ title, isUp, fontSize = '16', textColor }) => {
	return (
		<label
			className='normal-label'
			style={{
				fontSize: `${fontSize}px`,
				lineHeight: '100%',
				color: textColor,
				cursor: "pointer",
			}}
		>
			{title}{isUp ? "▲" : "▼"}
		</label>
	)
}

export default ArrowLabel