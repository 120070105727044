import React, {useEffect, useRef, useState} from 'react'
import {Zone} from '../../model/home/homePageModel';
import ButtonModel from '../button/buttonModel';
import AppIconButton from '../button/IconButton';
import NormalLabel from '../label/normalLabel';
import IconSearch from '../../assests/images/icon-search.png';
import IconSearchGrey from '../../assests/images/icon-search-grey.png';
import IconMute from '../../assests/images/icon-mute.png';
import {ControlZone} from '../../model/project/projectModel';

import {useDispatch} from "react-redux";
import {useSelector} from "../../redux/store";
import {ProjectFilter} from '../../redux/filterSlice';
import FilterFilledIcon from '../../assests/images/icon-filter-filled.png';
import FilterIcon from '../../assests/images/icon-filter.png';
import IconButton from '../button/IconButton';
import {showProject, showFilterHideOption, showFilter} from "../../redux/viewSlice";
import '../../i18n'
import {useTranslation} from "react-i18next";
import OuterLineButton from '../button/outerLineButton';
import {FILTER_HUMAN, FILTER_MANCHINE} from '../../model/constants';

enum OperatorType
{
	authorized,
	unauthorized,
	offline,
}

const ZoneList: React.FC<{
	contentTitle?: string,
	contentType?: string,
	title: string,
	selectedZone?: Zone|ControlZone,
	zoneList?: Zone[],
	controlZoneList?: ControlZone[],
	mutedList?: string[],
	lastUpdateTime: string,
	isControlZone: boolean,
	showAdminBtn?: boolean,
	hideContentTypeSelect?: boolean,
	onChangeViewType?: (viewType: string) => void
	onItemClickHandle: (zone: any) => void
}> = ({contentTitle, contentType, title, selectedZone, zoneList, controlZoneList, mutedList, lastUpdateTime, showAdminBtn, hideContentTypeSelect, isControlZone, onChangeViewType, onItemClickHandle}) =>
{

	const {t} = useTranslation()

	const [isSearch, setIsSearch] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();

	const filter = useSelector((state: any) => state.filter.projectFilter as ProjectFilter);

	const clickSearchHandle = () =>
	{
		setIsSearch(!isSearch);
	}

	const onChangeText = (value: string) =>
	{
		setSearchKey(value.toLowerCase());
	}

	const buttonModel: ButtonModel = {
		title: '',
		onClick: clickSearchHandle,
		style: {}
	}

	const getSelectedStyle = (zoneInfo: Zone) =>
	{
		const _zone = selectedZone as Zone
		if (_zone?.name === zoneInfo.name
			&& _zone?.count === zoneInfo.count)
		{
			return 'div-box-container-dark-highlight'
		}
		return 'div-box-container-dark'
	}

	const filterButtonModel: ButtonModel = {
		onClick: () =>
		{
			dispatch(showFilter(false))
			dispatch(showFilterHideOption(true));
			dispatch(showProject(false));
		}
	}

	const handleClickOutside = (event: Event) =>
	{
		if (ref.current && !ref.current.contains(event.target as Node))
		{
			if (isSearch)
			{
				setIsSearch(false)
				setSearchKey('')
			}
		}
	};

	const adminPanelBtn: ButtonModel = {
		title: t('home_admin_panel_title'),
		onClick: () =>
		{
			window.open(process.env.REACT_APP_ADMIN_PANEL_URL, "_blank")
		},
		style: {
			width: '35%',
			height: '40px',
		}
	}

	useEffect(() =>
	{
		document.addEventListener('click', handleClickOutside, true);
		return () =>
		{
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div
			style={{width: '100%', height: '100%'}}
		>
			<div className='zone-list-div'>
				<div style={{height: 40}}>
					{!isControlZone && (
						<div style={{display: 'flex',}}>
							<div style={{position: 'relative', padding: '16px 10px', display: 'flex', alignItems: 'center'}}>
								<div>
									<NormalLabel title={contentTitle ?? ''} fontSize={'20'}/>
								</div>
								{!hideContentTypeSelect && (
									<>
										<div style={{marginLeft: 6, transform: 'translate(0, -3px)'}}>
											<svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M14.5 17L0.210582 0.499997L28.7894 0.5L14.5 17Z" fill="white"/>
											</svg>
										</div>
										<select
											value={contentType}
											onChange={e => onChangeViewType && onChangeViewType(e.target.value)}
											style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', fontSize: 24}}
										>
											<option value="default">{t('home_worker_present')}</option>
											<option value="permit">{t('home_permit')}</option>
											<option value="ai-cam">{t('home_alert_ai_cam')}</option>
											<option value="gas-sensor">{t('home_confined_space')}</option>
											<option value="e-lock">{t('home_alert_e_lock')}</option>
										</select>
									</>
								)}
							</div>
						</div>
					)}
				</div>
				<div className='zone-list-header-div'>
					<div style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						flex: '2'
					}}
					>
						<NormalLabel title={title} fontSize={'14'}/>
						<NormalLabel title={`${t('home_as_of_time')} ${lastUpdateTime}`} fontSize={'14'}/>
					</div>
					{
						(contentType === 'default') &&
						<>
							{
								!isControlZone ? <div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										flex: '2',
										justifyContent: 'center',
										alignContent: 'center'
									}}
								>
									<div style={{
										flex: '1',
										textAlign: 'right'
									}}>
										<NormalLabel
											title={`${filter.filterType === FILTER_HUMAN ?
												t('home_human_filter_human') :
												filter.filterType === FILTER_MANCHINE ?
													t('home_human_filter_machine') :
													t('home_human_filter_health')}`
											}
											fontSize={'16'}
										/>
									</div>
									<div style={{
										flex: '1'
									}}>
										<IconButton
											viewModel={filterButtonModel}
											image={filter.filterType !== FILTER_HUMAN ?
												FilterFilledIcon : FilterIcon
											}
										/>
									</div>
								</div> : showAdminBtn ? <OuterLineButton viewModel={adminPanelBtn}/> : <></>
							}
						</>
					}
					{
						isSearch ?
							<div
								ref={ref}
								className={
									searchKey.length > 0 ?
										'search-div-searching' : 'search-div'
								}
								style={{
									flex: '2',
									maxWidth: '170px'
								}}
							>
								<img
									src={IconSearchGrey}
									className='img-icon-search'
									alt=""
								/>
								<input
									placeholder={t('home_search_zone_title')}
									className='search-input'
									value={searchKey}
									onChange={(event) => onChangeText(event.target.value)}
								/>
							</div> :
							<div style={{
								flex: '1',
								display: 'flex',
								justifyContent: 'right'
							}}>
								<AppIconButton
									viewModel={buttonModel}
									image={IconSearch}
								/>
							</ div>
					}
				</div>
				<div>
					<div className='scroll-div'>
						{
							((zoneList as Zone[])?.length > 0) ?
								zoneList?.filter(zone =>
								{
									return ((zone?.name ?? "").toLowerCase()).includes(searchKey)
								})?.map((value, index) =>
								{
									return (
										<div
											key={index}
											onClick={() =>
											{
												onItemClickHandle(value);
											}}
											className={getSelectedStyle(value)}
										>
											{
												normalZoneContent(index, value)
											}
										</div>
									);
								}) :
								((controlZoneList ?? [])?.length > 0) ?
									controlZoneList?.filter(controlZone =>
									{
										return ((controlZone?.zone ?? "").toLowerCase()).includes(searchKey);
									})?.map((value, index) =>
									{
										if (filter.filterType !== FILTER_MANCHINE)
										{
											return (
												<div
													key={index}
													onClick={() =>
													{
														onItemClickHandle(value)
													}}
													className={
														value.unauthorizedWorkers.count > 0 ?
															'div-box-container-dark-outerline' :
															'div-box-container-dark'
													}
												>
													{
														RestrictZoneContent(
															index,
															value,
															t("home_controlled_zone_authorised"),
															t("home_controlled_zone_unauthorised"),
															(mutedList ?? []).includes(value?.zone ?? ""))
													}
												</div>
											);
										}
										else
										{
											var zonerType = OperatorType.offline;
											var zonerTypeMsg = t("home_controlled_zone_mechine_offLine");
											var textColor = 'rgba(102, 102, 102, 1)';
											if ((value.unauthorizedWorkers.count ?? 0) > 0)
											{
												zonerType = OperatorType.unauthorized;
												zonerTypeMsg = t("home_controlled_zone_mechine_unauthorized");
												textColor = 'rgba(255, 143, 13, 1)';
											}
											else if ((value.authorizedWorkers.count ?? 0) > 0)
											{
												zonerType = OperatorType.authorized;
												zonerTypeMsg = t("home_controlled_zone_mechine_authorized");
												textColor = 'rgba(255, 255, 255, 1)';
											}

											return (
												<div
													key={index}
													onClick={() =>
													{
														onItemClickHandle(value)
													}}
													className={
														(value.unauthorizedWorkers?.count ?? 0) > 0 ?
															'div-box-container-dark-outerline' :
															'div-box-container-dark'
													}
												>
													{
														MechineContent(
															index,
															value.zone ?? "",
															t("home_controlled_zone_detail_mechine_location", {
																location: value.location ?? "-"
															}),
															zonerTypeMsg,
															textColor,
															zonerType,
														)
													}
												</div>
											);
										}
									}) : <></>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

const normalZoneContent = (
	index: number,
	zoneModel: Zone
): JSX.Element =>
{
	return (
		<div className='full-size-flex-div'>
			<div style={{
				flex: 6,
				whiteSpace: 'pre-line',
				overflowWrap: 'anywhere',
			}}>
				<NormalLabel title={zoneModel.name} fontSize='18'/>
			</div>
			<div
				className='full-size-flex-row-div'
				style={{
					flex: 6,
					justifyContent: 'flex-end',
					alignContent: 'flex-end',
					textAlign: 'end'
				}}
			>
				<NormalLabel title={`${zoneModel.count ?? '-'}`} fontSize='44'/>
			</div>
		</div>
	);
}

const RestrictZoneContent = (
	index: number,
	zoneModel: ControlZone,
	authorizedStr: string,
	unauthorizedStr: string,
	isMuted: boolean,
): JSX.Element =>
{

	return (
		<div className='full-size-flex-div'>
			<div className='full-size-flex-div' style={{
				paddingRight: '7px',
				flexDirection: "column",
			}}
			>
				<div style={{flex: 5}}><NormalLabel title={zoneModel.zone} fontSize='18'/></div>

				{
					isMuted ? <img
						src={IconMute}
						style={{
							width: '20px',
							height: '20px',
							marginBottom: '20px'
						}}
						alt=""/> : <></>
				}
			</div>

			<div
				className='full-size-flex-div'
				style={{
					flexDirection: 'column',
					flex: '1',
					paddingTop: '15px',
					paddingBottom: '15px',
				}}
			>
				<div className='full-size-flex-row-div'>
					<div>
						<NormalLabel title={`${authorizedStr}:`} fontSize='14'/>
					</div>
					<div style={{textAlign: 'right', flex: '1'}}>
						<NormalLabel title={`${zoneModel.authorizedWorkers.count}`} fontSize='20'/>
					</div>
				</div>
				<div className='full-size-flex-row-div'>
					<div>
						<NormalLabel title={`${unauthorizedStr}`} fontSize='14'/>
					</div>
					<div style={{width: '10px'}}></div>
					<div style={{textAlign: 'right', flex: '1'}}>
						<div style={{width: '10px'}}></div>
						<NormalLabel title={`${zoneModel.unauthorizedWorkers.count}`} fontSize='20' textColor={"rgba(248, 114, 54, 1)"}/>
					</div>
				</div>
			</div>
		</div>
	);
}

const MechineContent = (
	index: number,
	name: string,
	location: string,
	statusMsg: string,
	textColor: string,
	zonerTpye: OperatorType
): JSX.Element =>
{
	return (
		<div className='full-size-flex-div'>
			<div style={{
				flex: 6,
				whiteSpace: 'pre-line',
				overflowWrap: 'anywhere',
			}}>
				<NormalLabel
					title={`${name}\n${location}`}
					fontSize='18'
					textColor={zonerTpye !== OperatorType.unauthorized ? textColor : 'rgba(255, 255, 255, 1)'}
				/>
			</div>
			<div
				className='full-size-flex-row-div'
				style={{
					flex: 6,
					justifyContent: 'flex-end',
					alignContent: 'flex-end',
					textAlign: 'end'
				}}
			>
				<NormalLabel title={statusMsg} fontSize='18' textColor={textColor}/>
			</div>
		</div>
	);
}

export default ZoneList;