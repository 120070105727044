import { TFunction } from "i18next";
import { FILTER_HEALTH, FILTER_HUMAN, FILTER_MANCHINE } from "../../../model/constants";
import ButtonModel from "../../button/buttonModel";
import NormalLabel from "../../label/normalLabel";
import { Toggle } from 'rsuite';
import AppIconButton from "../../button/IconButton";
import IconClose from '../../../assests/images/icon-close.png';

export const ZoneDetailHeader: React.FC<{
	buttonModel: ButtonModel,
	zoneName: string,
	zoneCount: string,
	isControlZone: Boolean,
	filterType: string,
	machineTitle: string,
	controlZoneUnAuthCount?: string,
	isMuted: boolean,
	toggleStatus: (status: boolean) => void,
	t: TFunction<"translation">,
}> = ({ buttonModel, zoneName, zoneCount, isControlZone, filterType, controlZoneUnAuthCount, isMuted, toggleStatus, t, machineTitle }) => {

	const handleToggle = (checked: boolean) => {
		toggleStatus(checked)
	}

	return (
		<div style={{ paddingBottom: '10px' }}>
			<div className='full-size-flex-div'>
				<div style={{ display: 'flex', flexDirection: 'row', paddingRight: '20px' }}>
					<NormalLabel
						title={
							isControlZone ?
								(filterType === FILTER_HUMAN ? t('home_controlled_zone_detail') :
									filterType === FILTER_MANCHINE ?
										t('home_controlled_zone_detail_mechine_title') :
										t('home_controlled_zone_detail_health_title')) :
								filterType === FILTER_HEALTH ?
									t('home_controlled_zone_detail_health_title')
									: t("home_working_zone_detail")
						}
						fontSize={'20'} />
				</div>
				<div>
					{
						isControlZone && parseInt(controlZoneUnAuthCount ?? "0") > 0 ?
							<div className='circle-div' /> :
							<div className='circle-div-white' />
					}
				</div>
				<div style={{ flex: '1', justifyContent: 'flex-end', alignContent: 'flex-end', display: 'flex' }}>
					{
						isControlZone && filterType === FILTER_HUMAN ? <div style={{ display: 'flex', marginRight: '20px', marginTop: '10px' }}>
							<NormalLabel title={t("home_zoner_alarm_led_off")} fontSize={'20'} />
							<div className={"zone-detail-mute-switch"}>
								{/* toggle name switch for map UI design*/}
								<Toggle
									size="lg"
									checkedChildren="ON"
									unCheckedChildren="OFF"
									checked={isMuted}
									onChange={(checked, event) => {
										handleToggle(checked)
									}}
								/>
							</div>
						</div> : <></>
					}
					<AppIconButton viewModel={buttonModel} image={IconClose} />
				</div>
			</div>
			<div className='full-size-flex-div' style={{ marginRight: '25px' }}>
				<div style={{ marginTop: '25px' }}>
					<NormalLabel
						title={(isControlZone && filterType === FILTER_MANCHINE) ?
							`${machineTitle}` :
							`${zoneName}`}
						fontSize={'28'} />
				</div>
				<div style={{ width: '33px' }} />
				{
					isControlZone && filterType === FILTER_HUMAN ?
						<div className='full-size-flex-div'>
							<div style={{ width: '5%' }} />
							<div >
								<NormalLabel
									title={filterType === FILTER_HUMAN ?
										t("home_controlled_zone_authorised_count") :
										t('home_machine_authorised_count')}
									fontSize={'28'}
								/>
							</div>
							<div style={{ width: '33px' }} />
							<div >
								<NormalLabel title={zoneCount} fontSize={'80'} />
							</div>
							<div style={{ width: '33px' }} />
							<div >
								<NormalLabel
									title={filterType === FILTER_HUMAN ?
										t("home_controlled_zone_unauthorised_count") :
										t('home_machine_unauthorised_count')}
									fontSize={'28'}
								/>
							</div>
							<div style={{ width: '33px' }} />
							<div >
								<NormalLabel title={controlZoneUnAuthCount ?? ""} fontSize={'80'} textColor={"rgba(255, 143, 13, 1)"} />
							</div>
						</ div> : (filterType === FILTER_HUMAN || filterType === FILTER_HEALTH) ? <div>
							<div style={{ paddingLeft: '50px' }}>
								<NormalLabel title={zoneCount} fontSize={'58'} />
							</div>
						</div> : <></>
				}
			</div>
		</div >
	);
}