import React, { useEffect, useState } from 'react'
import { ConstractorListModel, TradeListModel, FilterDetailModel } from '../../model/home/homePageModel'
import ButtonModel from '../button/buttonModel'
import AppIconButton from '../button/IconButton'
import OuterLineButton from '../button/outerLineButton'
import NormalLabel from '../label/normalLabel'
import IconClose from '../../assests/images/icon-close.png';
import { useDispatch } from "react-redux";
import { useSelector } from "../../redux/store";
import { filter, ProjectFilter } from "../../redux/filterSlice";
import TickIcon from '../../assests/images/icon-tick.png';
import { AppService } from '../../services/appService'
import { showLoading } from '../../redux/viewSlice'
import { useNavigate } from 'react-router-dom'
import { RefreshToken } from '../../services/authenticationService'
import { SelectPicker } from 'rsuite';

import '../../i18n'
import { useTranslation } from "react-i18next";
import { ALLCONTRACTOR, ALLTRADE, FILTER_HEALTH, FILTER_HUMAN, FILTER_MANCHINE } from '../../model/constants'

const FilterDetail: React.FC<{
	appService: AppService
	projectId: string,
	hideOption: boolean,
	confirmCallBack: () => void,
	closeCallBack: React.MouseEventHandler
}> = ({ appService, projectId, hideOption, confirmCallBack, closeCallBack }) => {

	const { t, i18n } = useTranslation()

	const navigate = useNavigate();

	const filterModel = (useSelector((state: any) => state.filter.projectFilter) as ProjectFilter);

	const [selectedConstuctor, setSelectedConstuctor] = useState<FilterDetailModel>({
		code: ALLCONTRACTOR.contractorCode,
		uid: ALLCONTRACTOR.contractorUID,
		name: ALLCONTRACTOR.chineseName,
		nameEn: ALLCONTRACTOR.englishName,
	});

	const [selectedTrade, setSelectedTrade] = useState<FilterDetailModel>({
		code: ALLTRADE.tradeCode,
		name: ALLTRADE.nameChi,
		nameEn: ALLTRADE.nameEng,
	});

	const [constructorList, setConstructorList] = useState<ConstractorListModel[]>([ALLCONTRACTOR]);
	const [tradeList, setTradeList] = useState([ALLTRADE]);

	const [filterType, setFilterType] = useState<string>();

	const dispatch = useDispatch();

	// const [isOpenComfirm, setIsOpenConfirm] = useState(false);

	// const handleClose = () => {
	// 	setIsOpenConfirm(false);
	// };

	const isEng = i18n.language.includes("en")

	var didRefresh = false;

	useEffect(() => {
		if (hideOption) {
			setSelectedConstuctor({
				code: filterModel.constructorCode ?? "",
				uid: filterModel.constructorUid ?? "",
				name: filterModel.constructor ?? "",
				nameEn: filterModel.constructorEn ?? "",
			});

			setSelectedTrade({
				code: filterModel.typeCode ?? "",
				name: filterModel.type ?? "",
				nameEn: filterModel.typeEn ?? "",
			});
			getFilterDetail();
		}
		setFilterType(filterModel.filterType ?? "HUMAN");
	}, [])

	const getFilterDetail = () => {
		dispatch(showLoading(true));
		getConstuctorList();
		getWorkerList();
	}

	const closeButtonModel: ButtonModel = {
		title: '',
		onClick: closeCallBack,
		style: {}
	}

	const clickConfirm = () => {
		// setIsOpenConfirm(false);
		const _filterModel: ProjectFilter = {
			constructor: selectedConstuctor?.name ?? "",
			constructorEn: selectedConstuctor.nameEn ?? "",
			constructorCode: selectedConstuctor?.code ?? "",
			constructorUid: selectedConstuctor.uid ?? "",
			type: selectedTrade?.name ?? "",
			typeEn: selectedTrade.nameEn ?? "",
			typeCode: selectedTrade?.code ?? "",
			filterType: filterType ?? FILTER_HUMAN
		}
		dispatch(filter(_filterModel));
		confirmCallBack();
	}

	const confirmButtonModel: ButtonModel = {
		title: t("gneral_button_confirm"),
		onClick: () => {
			clickConfirm();
		},
		style: {
			background: 'linear-gradient(rgb(48, 48, 48), transparent)',
			backgroundColor: 'rgb(22, 22, 28)',
			height: '40px'
		}
	}

	const changeConstutor = (value: ConstractorListModel) => {
		setSelectedConstuctor({
			code: value.contractorCode,
			uid: value.contractorUID,
			name: value.chineseName,
			nameEn: value.englishName,
		});
	}

	const changeTrade = (value: TradeListModel) => {
		setSelectedTrade({
			code: value.tradeCode,
			name: value.nameChi,
			nameEn: value.nameEng,
		});
	}

	const changeFilterType = (type: string) => {
		setFilterType(type);
	}

	const getConstuctorList = () => {
		appService.projectServices
			.getConstractorList(projectId)
			.subscribe(
				(value: any) => {
					const _contractorList: ConstractorListModel[] = [ALLCONTRACTOR];
					value.data.data.subContractor.map((e: any) => {
						_contractorList.push(
							{
								address: e.address,
								chineseName: e.chineseName,
								contactName: e.contactName,
								contactPhone: e.contactPhone,
								contractorCode: e.contractorCode,
								contractorUID: e.contractorUID,
								country: e.country,
								englishName: e.englishName,
								idDocument: e.idDocument,
								idNo: e.idNo,
								remarks: e.remarks,
							}
						)
					});
					setConstructorList(_contractorList);
					dispatch(showLoading(false));
				},
				(error) => {
					if (!didRefresh) {
						didRefresh = true;
						RefreshToken(
							appService,
							error.httpStatusCode,
							dispatch,
							navigate,
							() => {
								getFilterDetail();
							}
						);
					} else {
						dispatch(showLoading(false));
					}

				}
			)
	}

	const getWorkerList = () => {
		appService.projectServices
			.getProjectTradeList(projectId)
			.subscribe(
				(value: any) => {
					const _workerTradeList: TradeListModel[] = [ALLTRADE];
					value.data.data.trades.map((e: any) => {
						_workerTradeList.push(
							{
								tradeCode: e.tradeCode,
								nameEng: e.nameEng,
								nameChi: e.nameChi,
								descEng: e.descEng,
								descChi: e.descChi,
							}
						)
					});
					setTradeList(_workerTradeList);
					dispatch(showLoading(false));
				},
				(error) => {
				}
			)
	}

	const constructorData = constructorList.map((value, index) => {
		return ({
			label: isEng ? value.englishName : value.chineseName ?? "",
			value: value.contractorCode ?? ""
		})
	})

	const tradeData = tradeList.map((value, index) => {
		return ({
			label: isEng ? value.nameEng : value.nameChi ?? "",
			value: value.tradeCode ?? ""
		})
	})

	return (
		<div
			className='div-box-container-dark-normal'
			style={{ border: '2px solid rgba(255,143,13,1)' }}
		>
			<div className='full-size-flex-div' style={{ flexDirection: 'column' }}>
				<div style={{ display: 'flex', marginBottom: '30px' }}>
					<div className='full-size-flex-div'>
						<NormalLabel title={!hideOption ? t('home_human_filter_title') : t('home_contractor_filter_title')} fontSize={'20'} />
					</div>
					<div>
						<AppIconButton viewModel={closeButtonModel} image={IconClose} />
					</div>
				</div>
				{
					hideOption ? <>
						<div style={{ marginBottom: '20px' }}>
							<NormalLabel title={t('home_contractor')} />
						</div>
						<div style={{ marginBottom: '20px' }}>
							<div>
								<SelectPicker
									cleanable={false}
									value={selectedConstuctor.code}
									data={constructorData}
									style={{
										width: '100%',
									}}

									menuStyle={{
										backgroundColor: 'white',
										color: 'black',
										fontWeight: '700',
										fontSize: '10px',
									}}
									onChange={(value, event) => {
										event.preventDefault()
										const itemValue = value;
										if (itemValue != undefined
											&& itemValue.length > 0) {
											changeConstutor([...constructorList].filter((e) => e.contractorCode === itemValue)[0]);
										}
									}}
								/>
							</div>
						</div>
						<div style={{ marginBottom: '20px' }}>
							<NormalLabel title={t('home_trade')} />
						</div>
						<div style={{ marginBottom: '20px', height: '50px' }}>
							<div>
								<SelectPicker
									readOnly={filterType === "HUMAN"}
									cleanable={false}
									value={selectedTrade.code}
									data={tradeData}
									style={{
										width: '100%',
									}}
									menuStyle={{
										backgroundColor: 'white',
										color: 'black',
										fontWeight: '700',
										fontSize: '10px',
									}}
									onChange={(value, event) => {
										event.preventDefault()
										const itemValue = value;
										if (itemValue != undefined
											&& itemValue.length > 0) {
											changeTrade([...tradeList].filter((e) => e.tradeCode === itemValue)[0]);
										}
									}}
								/>
								{
									filterType === "HUMAN" ? <div style={{
										width: '100%',
										height: '50px',
										background: 'rgba(0, 0, 0, 0.35)',
										position: 'relative',
										bottom: "50px",
										borderRadius: '5px'
									}} /> : <div />
								}
							</div>
						</div>
					</> : <>
						<div className='check-box-div' style={{ marginBottom: '20px' }}>
							<div style={{ width: '20%' }}>
								{
									checkBoxItem(t('home_human_filter_human'), filterType === FILTER_HUMAN ?? true, () => {
										changeFilterType(FILTER_HUMAN)
									})
								}
							</div>
							<div style={{ width: '20%' }}>
								{
									checkBoxItem(t('home_human_filter_machine'), filterType === FILTER_MANCHINE, () => {
										changeFilterType(FILTER_MANCHINE)
									})
								}
							</div>
							{/*{<div style={{ width: '20%' }}>*/}
							{/*	{*/}
							{/*		checkBoxItem(t('home_human_filter_health'), filterType === FILTER_HEALTH, () => {*/}
							{/*			changeFilterType(FILTER_HEALTH)*/}
							{/*		})*/}
							{/*	}*/}
							{/*</div> }*/}
						</div>
					</>
				}
				<div style={{ width: '12%' }}>
					<OuterLineButton viewModel={confirmButtonModel} />
				</div>
			</div>
			{/* <ConfirmDialog
				dialogProps={{
					title: t("home_filter_confirm_title"),
					content: '',
					open: isOpenComfirm,
					btnTitle: t("home_filter_confirm_button"),
					btnCancel: t('home_filter_cancel_button'),
					handleClose: handleClose
				}}
				confirmCallBack={() => clickConfirm()}
			/> */}
		</div >
	)
}

const checkBoxItem = (
	title: string,
	checked: boolean,
	onClickChange: () => void
) => {
	return (
		<div className='checkbox-wrapper'>
			<label className='check-box-label' >
				{title}
				<input
					type="checkbox"
					checked={checked}
					className={checked ? 'checked' : ''}
					onChange={() => {
						if (checked) return
						onClickChange();
					}}
				/>
				{
					checked ? <img
						src={TickIcon}
						className='check-box-tick-img' /> : null
				}
			</label>
		</div >
	);
}
export default FilterDetail