import { createSlice } from '@reduxjs/toolkit';
import { ProjectInfo } from '../model/project/projectModel';

export interface UserState {
  isAuth: boolean;
  project: ProjectInfo | null;
}

const initialState: UserState = {
  isAuth: Boolean(localStorage.getItem('isAuth')) ?? false,
  project:
    localStorage.getItem('project') != null
      ? JSON.parse(localStorage.getItem('project')!)
      : null,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuth = action.payload;
      localStorage.setItem('isAuth', action.payload);
    },
    setProject: (state, action) => {
      state.project = action.payload;
      localStorage.setItem('project', JSON.stringify(action.payload));
    },
    Logout: (state) => {
      state.isAuth = false;
      state.project = null;
      localStorage.removeItem('project');
      localStorage.removeItem('isAuth');
      localStorage.removeItem('filter');
    },
  },
});

export const { login, Logout, setProject } = UserSlice.actions;

export default UserSlice.reducer;
