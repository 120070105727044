import React, {useCallback, useEffect, useState} from 'react'
import {EnvSensorModel, GasSensorModel, HealthDataModel, WorkerWatchModel, Zone} from '../../model/home/homePageModel'
import ButtonModel from '../button/buttonModel'
import { useSelector } from "../../redux/store";
import { ProjectFilter } from '../../redux/filterSlice'
import {
	AuthorizedWorkerDetail,
	ContractorDetail,
	ControlZone,
	ZoneInfo,
	ZoneItemDetail
} from '../../model/project/projectModel'
import { AppService } from '../../services/appService'
import { useDispatch } from "react-redux";
import { showLoading } from '../../redux/viewSlice'
import { useNavigate } from 'react-router-dom'
import { FILTER_HEALTH, FILTER_HUMAN, FILTER_MANCHINE } from '../../model/constants'
import { RefreshToken } from '../../services/authenticationService'
import { SampleZoneInfo } from '../../testing/sampleData'

import '../../i18n'
import { TFunction, useTranslation } from "react-i18next";
import { i18n } from 'i18next'
import { WorkerHealthContent } from './zoneDetailComponent/workerHealthComponent'
import { MechanicalContent } from './zoneDetailComponent/machineComponent'
import { WorkerContent } from './zoneDetailComponent/workerDetailComponent'
import { WorkerWithHealthContent } from './zoneDetailComponent/workerDetailWithHealthComponent'
import { ContractorContent } from './zoneDetailComponent/contractorDetailComponent'
import { ZoneDetailHeader } from './zoneDetailComponent/zoneHeaderComponent'
import NormalLabel from "../label/normalLabel";
import AppIconButton from "../button/IconButton";
import IconClose from "../../assests/images/icon-close.png";
import moment from "moment";
import {parseISO} from "rsuite/cjs/utils/dateUtils";
import {Switch} from "@mui/material";

const ZoneDetail: React.FC<{
	appService: AppService,
	projectId: string,
	projectUId: string,
	isControlZone: boolean,
	detail?: Zone | ControlZone,
	workerWatch?: WorkerWatchModel[],
	isMuted: boolean,
	isDemo?: boolean,
	muteToggleHandle: () => void,
	closeCallBack: React.MouseEventHandler
}> = ({ appService, projectUId, projectId, detail, workerWatch, isControlZone = false, isDemo = false, isMuted, muteToggleHandle, closeCallBack }) => {

	const { t, i18n } = useTranslation()

	const filterModel = (useSelector((state: any) => state.filter.projectFilter)) as ProjectFilter;

	const [zoneInfo, setZoneInfo] = useState<ZoneInfo>();
	const [controlZoneInfo, setControlZoneInfo] = useState<ControlZone>();
	const [zoneOverview, setZoneOverview] = useState<any>();
	const [asOfDate, setAsOfDate] = useState<Date|null>(null);

	const [muted, setMuted] = useState(isMuted);
	const [isSelectedZoneDenyAllWorkers, setIsSelectedZoneDenyAllWorkers] = useState<boolean|null>((detail as ControlZone).isDenyAllWorkers);

	var didInit = false;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isEng = i18n.language.includes("en")

	useEffect(() => {
		if (!didInit) {
			// console.log(isControlZone)
			if (!isDemo) {
				if (isControlZone) {
					getControlZoneDetail()
				} else {
					getZoneDetail(true)
				}
			} else {
				setZoneInfo(SampleZoneInfo)
			}
			didInit = true;
		}

		const timer = setInterval(() => {
			if (!isControlZone && filterModel.filterType !== FILTER_HEALTH) {
				getZoneDetail(false)
			}
		}, 1000 * 60 * 15);

		const healthDataTimer = setInterval(() => {
			if (!isControlZone && filterModel.filterType === FILTER_HEALTH) {
				getZoneDetail(false)
			}
		}, 1000 * 60 * 2);

		return () => {
			clearInterval(timer);
			clearInterval(healthDataTimer);
		};

	}, [])

	const toggleZoneIsDenyAllWorkers = useCallback((isDenyAllWorkers: boolean) => {
		(detail as ControlZone).isDenyAllWorkers = isDenyAllWorkers;
		
		appService.dashboardService
			.toggleControlZoneIsDenyAllWorkers({
				"projectID": projectId,
				"zoneName": (detail as ControlZone).zone,
				isDenyAllWorkers,
			})
			.subscribe(
				(value: any) => {
				},
				(error) => {
				}
			)
	}, [projectId, detail]);

	const getZoneDetail = (showLoad: boolean) => {
		if (showLoad) {
			dispatch(showLoading(true));
		}

		if (detail != null) {
			const zoneName = (detail as Zone).name;

			appService.dashboardService
				.getZoneDetail({
					"projectID": projectId,
					"projectUID": projectUId,
					"contractorID": filterModel.constructorUid !== "all" ? filterModel.constructorUid : null,
					"contractor": filterModel.constructorCode !== "all" ? filterModel.constructorCode : null,
					"zones": [zoneName],
					"trade": filterModel.typeCode !== "all" ? filterModel.type : null,
					"workerType": (filterModel.filterType === FILTER_MANCHINE) ? "machine" : "human",
				})
				.subscribe(
					(value: any) => {
						const workerList = value.data.data;
						setZoneInfo(workerList)
						dispatch(showLoading(false));
						setAsOfDate(new Date());
					},
					(error) => {
						RefreshToken(
							appService,
							error.httpStatusCode,
							dispatch,
							navigate,
							() => {
								dispatch(showLoading(false));
								getZoneDetail(true)
							}
						);
					}
				)

			appService.dashboardService
				.getZoneOverview({
					"projectID": projectId,
					"zone": zoneName,
				})
				.subscribe((res: any) => {
					setZoneOverview(res.data.data);
				});
		} else {
			dispatch(showLoading(false));
		}
	}

	const getControlZoneDetail = () => {
		dispatch(showLoading(true));
		const zoneName = (detail as ControlZone).zone;
		appService.dashboardService
			.getControlZone({
				"projectID": projectId,
				"projectUID": projectUId,
				"zone": zoneName,
				"workerType": (filterModel.filterType === FILTER_MANCHINE) ? "machine" : "human",
			})
			.subscribe(
				(value: any) => {
					setControlZoneInfo(value.data.data)
					dispatch(showLoading(false));
					setAsOfDate(new Date());
				},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							dispatch(showLoading(false));
							getControlZoneDetail()
						}
					);
				}
			)

		appService.dashboardService
			.getZoneOverview({
				"projectID": projectId,
				"zone": zoneName,
			})
			.subscribe((res: any) => {
				setZoneOverview(res.data.data);
			});
	}

	const _buttonModel: ButtonModel = {
		title: '',
		onClick: closeCallBack,
		style: {}
	}

	const convertControlZone = (e: AuthorizedWorkerDetail, isAuth: boolean): ZoneItemDetail => {
		return {
			contractor: e.contractor ?? "-",
			workerId: e.workerId ?? "-",
			workerName: e.workerNameZh ?? e.chineseName ?? e.workerName ?? "-",
			workerNameEn: e.workerNameEn ?? e.englishName ?? e.workerName ?? "-",
			workerNameZh: e.workerNameZh ?? e.chineseName ?? e.workerName ?? "-",
			workerPhoneNo: e.contactPhone ?? "-",
			contractorEn: e.contractorEn ?? "-",
			contractorZh: e.contractorZh ?? "-",
			tradeEn: e.tradeEn ?? "-",
			tradeZh: e.tradeZh ?? "-",
			country: e.country ?? "-",
			trade: e.trade ?? "-",
			zone: e.zone ?? "-",
			zoneFull: e.zoneFull ?? "-",
			locationMethod: e.locationMethod ?? "-",
			checkInTime: e.checkInTime ?? "-",
			checkInDevice: e.checkInDevice ?? "-",
			lastSeenHub: e.lastSeenHub ?? "-",
			lastSeenTime: e.lastSeenTime ?? "-",
			seenCount: e.seenCount ?? 0,
			isMachine: e.isMachine ?? "-",
			age: e.age ?? "-",
			workerImgUrl: e.workerImgUrl ?? "-",
			isAuth: isAuth
		}
	}

	const getZoneList = (): ZoneItemDetail[] => {
		if (isControlZone) {
			var unauthList: ZoneItemDetail[] = []
			var authList: ZoneItemDetail[] = []
			unauthList = controlZoneInfo?.unauthorizedWorkers.list.map(e => convertControlZone(e, false)) ?? []
			authList = controlZoneInfo?.authorizedWorkers.list.map(e => convertControlZone(e, true)) ?? []
			const zoneItemList = [...unauthList, ...authList];
			const healthZone = zoneItemList.map((value, index) => {
				const smartagStatusValue = (value.smartagStatus?.length ?? 0) > 0 ? value.smartagStatus! : "{ \"HR\": null, \"B_Temp\": null}"
				const smartagStatus = JSON.parse(smartagStatusValue)
				const healthDataModel: HealthDataModel = {
					workerName: isEng ? value.workerNameEn : value.workerNameZh,
					workerAge: value.age,
					workerTrade: isEng ? value.tradeEn : value.tradeZh,
					workerContractor: isEng ? value.contractorEn : value.contractorZh,
					workerHr: smartagStatus.HR != null ? Number(smartagStatus.HR) : undefined,
					workerTemp: smartagStatus.B_Temp != null ? Number(smartagStatus.B_Temp) : undefined,
					isFall: (smartagStatus["alert_Fall"] ?? "0") === "1",
					isNoHelmet: (smartagStatus["alert_helmOff"] ?? "0") === "1",
					isStandStill: (smartagStatus["alert_Still"] ?? "0") === "1",
					isSos: (smartagStatus["alert_SOS"] ?? "0") === "1",
					isSmartagOff: (smartagStatus["alert_BTOFF"] ?? "0") === "1",
					isAuth: value.isAuth,
				}
				return {
					...value,
					healthData: healthDataModel
				}
			})

			return healthZone;
		} else {
			const normalZone = ((zoneInfo?.zones.length ?? 0) > 0) ? (zoneInfo?.zones[0].items ?? []) : []

			if (filterModel.filterType === FILTER_HUMAN || filterModel.filterType === FILTER_HEALTH) {
				const healthZone = normalZone.map((value, index) => {
					const smartagStatusValue = (value.smartagStatus?.length ?? 0) > 0 ? value.smartagStatus! : "{ \"HR\": null, \"B_Temp\": null}"
					const smartagStatus = JSON.parse(smartagStatusValue)
					const healthDataModel: HealthDataModel = {
						workerName: isEng ? value.workerNameEn : value.workerNameZh,
						workerAge: value.age,
						workerTrade: isEng ? value.tradeEn : value.tradeZh,
						workerContractor: isEng ? value.contractorEn : value.contractorZh,
						workerHr: smartagStatus.HR != null ? Number(smartagStatus.HR) : undefined,
						workerTemp: smartagStatus.B_Temp != null ? Number(smartagStatus.B_Temp) : undefined,
						isFall: (smartagStatus["alert_Fall"] ?? "0") === "1",
						isNoHelmet: (smartagStatus["alert_helmOff"] ?? "0") === "1",
						isStandStill: (smartagStatus["alert_Still"] ?? "0") === "1",
						isSos: (smartagStatus["alert_SOS"] ?? "0") === "1",
						isSmartagOff: (smartagStatus["alert_BTOFF"] ?? "0") === "1",
						isAuth: value.isAuth,
					}
					return {
						...value,
						healthData: healthDataModel
					}
				})

				return healthZone;
			} else {
				return normalZone;
			}

		}
	}

	const toggleMuteHandle = (status: boolean) => {
		dispatch(showLoading(true));

		const toggleStatus = status ? "ON" : "OFF"
		const zoneName = (detail as ControlZone).zone
		appService.zonerStatusServices
			.toggleZonerStatus({
				"projectID": projectId,
				"zone": zoneName,
				"status": toggleStatus
			})
			.subscribe((value: any) => {
				muteToggleHandle()
				const updateStatusCode = value.data.data.statusCode
				const updateMessage = value.data.data.message
				const updateStatue = toggleStatus === "ON" ? true : false
				setMuted((updateStatusCode === "200" && updateMessage === "OK") ? updateStatue : false)
				dispatch(showLoading(false));
			},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							dispatch(showLoading(false));
							toggleMuteHandle(status)
						}
					);
				})
	}

	const getMachineTitle = () => {
		if (isControlZone && filterModel.filterType === FILTER_MANCHINE) {
			const zone = (detail as ControlZone);
			return `${zone.zone ?? "-"}  |  ${zone.trade ?? "-"}  |  ${zone.contractor ?? "-"}  |  ${t("home_controlled_zone_detail_mechine_location", {
				location: zone.location ?? "-"
			})}`
		}
		return "";
	}

	const renderHeader = () => {
		if (filterModel.filterType === FILTER_HUMAN) {
			const zoneName = `${isControlZone ?
				(detail as ControlZone).zone :
				(detail as Zone).name}`
			const zoneCount = `${isControlZone ?
				(detail as ControlZone).authorizedWorkers.count + (detail as ControlZone).unauthorizedWorkers.count :
				zoneInfo?.zones[0]?.count ?? "-"}`
			const controlZoneAuthCount = `${isControlZone ? (detail as ControlZone).authorizedWorkers.count : null}`
			const controlZoneUnAuthCount = `${isControlZone ? (detail as ControlZone).unauthorizedWorkers.count : null}`
			const renderTitleCountString = (title: string, countString: string, color?: string) => (
				<div style={{ padding: 12 }}>
					<div>
						<NormalLabel title={title} fontSize={'18'} lineHeight="1.5" />
						<NormalLabel title={countString} fontSize={'24'} style={{ marginTop: 8, color }} />
					</div>
				</div>
			)
			const renderTitleCount = (title: string, count?: number | null) => {
				return renderTitleCountString(title, count != null ? `${count}` : '-')
			}
			const gasSensorTypes = [
				{label: t('zone_workers_env_condition_co2'), type: 'CO2'},
				{label: t('zone_workers_env_condition_o2'), type: 'O2'},
				{label: t('zone_workers_env_condition_h2s'), type: 'H2S'},
				{label: t('zone_workers_env_condition_co'), type: 'CO'},
				{label: t('zone_workers_env_condition_flammable_gas'), type: 'LEL-MPS'},
			]
			const getGasSensorTypeReading = (type: string) => {
				const gasSensors = zoneOverview?.gasSensor as GasSensorModel[];
				if (!gasSensors?.length) return '-';

				const gasSensor = gasSensors[0];
				const sensor = gasSensor.sensors.find(x => x.type === type);
				if (sensor) {
					const {reading} = sensor;
					// const dateString = moment(parseISO(date)).format('DD-MM-yyyy  HH:mm:ss')
					// return `${reading} ${unit} (${dateString})`;
					return `${reading}`;
				}
				return '-';
			}
			const envSensorTypes = [
				{label: t('zone_workers_env_condition_temp'), type: 'TEMPERATURE'},
				{label: t('zone_workers_env_condition_humidity'), type: 'HUMIDITY'},
				{label: t('zone_workers_env_condition_pm2_5'), type: 'PM2.5'},
			];
			const getEnvSensorTypeReading = (type: string) => {
				const envSensors = zoneOverview?.envSensor as EnvSensorModel[];
				if (!envSensors?.length) return '-';

				const envSensor = envSensors[0];
				const sensor = envSensor.sensors.find(x => x.type === type);
				if (sensor) {
					const {reading} = sensor;
					// const dateString = moment(parseISO(date)).format('DD-MM-yyyy  HH:mm:ss')
					// return `${reading} ${unit} (${dateString})`;
					return `${reading}`;
				}
				return '-';
			}
			return (
				<div style={{ paddingTop: '20px', paddingBottom: '10px', display: 'flex', position: 'relative' }}>
					<div style={{ position: 'absolute', right: 0, top: 0 }}>
						<AppIconButton viewModel={_buttonModel} image={IconClose} />
					</div>

					{isControlZone && (
						<div style={{ position: 'absolute', right: 40, top: 1, display: 'flex', alignItems: 'center' }}>
							<NormalLabel title={`Emergency Evacuation`} fontSize={'14'} style={{ width: 'fit-content' }} />
							<Switch
								sx={{
									'.MuiSwitch-track': {
										background: 'white',
									},
									'.Mui-checked': {
										color: 'rgb(255, 143, 13) !important',
									},
									'.Mui-checked + .MuiSwitch-track': {
										background: 'rgb(255, 143, 13) !important',
									}
								}}
								checked={isSelectedZoneDenyAllWorkers ?? false}
								onClick={() => {
									setIsSelectedZoneDenyAllWorkers(!isSelectedZoneDenyAllWorkers);
									toggleZoneIsDenyAllWorkers(!isSelectedZoneDenyAllWorkers)
								}}
							/>
						</div>
					)}
					
					<div style={{ height: 120, display: 'flex', flexDirection: 'column', }}>
						<div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, }}>
							<div style={{ display: 'flex' }}>
								<NormalLabel title={t('home_as_of_time') + ' ' + (asOfDate ? moment(asOfDate).format('YYYY-MM-DD HH:mm') : '-')} fontSize={'20'} />
								<div style={{ marginLeft: 16, display: 'flex', alignItems: 'center' }}>
									{isControlZone && parseInt(controlZoneUnAuthCount ?? "0") > 0 ? (
										<div className='circle-div' style={{ marginRight: 0 }} />
									) : (
										<div className='circle-div-white' style={{ marginRight: 0 }} />
									)}
								</div>
							</div>
						</div>
						<div style={{ marginTop: 20, display: 'flex', alignItems: 'center', flex: 1 }}>
							<div style={{ maxWidth: '160px' }}>
								<NormalLabel title={zoneName} fontSize={'24'} lineHeight="1.5" />
							</div>
							<div style={{ marginLeft: 32 }}>
								<NormalLabel title={zoneCount} fontSize={'80'} />
							</div>
						</div>
					</div>

					{isControlZone && (
						<div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column' }}>
							<div style={{ flexShrink: 0, }}>
								<NormalLabel title={`${t('home_controlled_zone')}:`} fontSize={'18'} lineHeight="1.5" />
							</div>
							<div style={{ flex: 1, marginTop: 8 }}>
								<div style={{ padding: '12px 16px', backgroundColor: 'black' }}>
									<div style={{ display: 'flex', margin: -12, textAlign: 'center' }}>
										{renderTitleCountString(t("home_controlled_zone_authorised_count"), controlZoneAuthCount)}
										{renderTitleCountString(t("home_controlled_zone_unauthorised_count"), controlZoneUnAuthCount, `rgba(255, 143, 13, 1)`)}
									</div>
								</div>
							</div>
						</div>
					)}
					
					<div style={{ marginLeft: isControlZone ? 10 : 40, display: 'flex', flexDirection: 'column' }}>
						<div style={{ flexShrink: 0, }}>
							<NormalLabel title={t('zone_workers_elock')} fontSize={'18'} lineHeight="1.5" />
						</div>
						<div style={{ flex: 1, marginTop: 8 }}>
							<div style={{ padding: '12px 16px', backgroundColor: 'black' }}>
								<div style={{ display: 'flex', margin: -12, textAlign: 'center' }}>
									{renderTitleCount(t('zone_workers_elock_count'), zoneOverview?.eLock?.totalCount)}
									{renderTitleCount(t('zone_workers_elock_unauthorised_access'), zoneOverview?.eLock?.unauthorizedUnlockCount)}
								</div>
							</div>
						</div>
					</div>

					<div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
						<div style={{ flexShrink: 0, }}>
							<NormalLabel title={t('zone_workers_epermit')} fontSize={'18'} lineHeight="1.5" />
						</div>
						<div style={{ flex: 1, marginTop: 8 }}>
							<div style={{ padding: '12px 16px', backgroundColor: 'black' }}>
								<div style={{ display: 'flex', margin: -12, textAlign: 'center' }}>
									{renderTitleCountString(t('zone_workers_epermit_pending_approval'), '-')}
									{renderTitleCount(t('zone_workers_epermit_in_progress'), zoneOverview?.permit?.activeCount)}
									{renderTitleCount(t('zone_workers_epermit_overdue'), zoneOverview?.permit?.expiredCount)}
								</div>
							</div>
						</div>
					</div>

					<div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
						<div style={{ flexShrink: 0, }}>
							<NormalLabel title={t('zone_workers_env_condition')} fontSize={'18'} lineHeight="1.5" />
						</div>
						<div style={{ flex: 1, marginTop: 8 }}>
							<div style={{ padding: '12px 16px', backgroundColor: 'black' }}>
								<div style={{ display: 'flex', margin: -12, textAlign: 'center' }}>
									{envSensorTypes.map(x => (
										renderTitleCountString(x.label, getEnvSensorTypeReading(x.type)))
									)}
									{gasSensorTypes.map(x => (
										renderTitleCountString(x.label, getGasSensorTypeReading(x.type), getGasSensorTypeReading(x.type) != '-' ? `rgba(14, 249, 96, 1)` : undefined))
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<>
				{detail != null ? ZoneDetailHeader({
					buttonModel: _buttonModel,
					zoneName: `${isControlZone ?
						(detail as ControlZone).zone :
						(detail as Zone).name}`,
					zoneCount: `${isControlZone ?
						(detail as ControlZone).authorizedWorkers.count :
						zoneInfo?.zones[0]?.count ?? "-"}`,
					machineTitle: getMachineTitle(),
					isControlZone: isControlZone,
					filterType: filterModel.filterType,
					controlZoneUnAuthCount: `${isControlZone ? (detail as ControlZone).unauthorizedWorkers.count : null}`,
					isMuted: muted,
					toggleStatus: (status) => {
						toggleMuteHandle(status)
					},
					t: t,
				}) : ZoneDetailHeader({
					buttonModel: _buttonModel,
					zoneName: `-`,
					zoneCount: `-`,
					machineTitle: "- | - | -",
					isControlZone: isControlZone,
					filterType: filterModel.filterType,
					controlZoneUnAuthCount: `-`,
					isMuted: muted,
					toggleStatus: (status) => {
						toggleMuteHandle(status)
					},
					t: t,
				})
				}
			</>
		)
	}
	
	const renderContent = () => {
		if (filterModel.filterType === FILTER_HUMAN) {
			let contractorList = (zoneInfo?.zones.length ?? 0) > 0 ?
				zoneInfo?.zones[0]?.contractors ?? [] : [];
			if (isControlZone) {
				contractorList = [];
				const workerList = getZoneList();
				workerList.forEach((element) => {
					const {contractor, contractorEn, contractorZh} = element;
					if (!contractorList.find(x => x.contractor === contractor)) {
						contractorList.push({
							contractor,
							contractorEn,
							contractorZh,
							trade: '',
							count: 1,
						});
					} else {
						const contractorDetail = contractorList.find(x => x.contractor === contractor);
						if (contractorDetail) {
							contractorDetail.count += 1;
						}
					}
				});
			}
			return (
				<>
					<div style={{ width: '30%' }}>
						{ContractorContent({
							filterType: filterModel.filterType,
							contractorList,
							t: t,
							i18n: i18n
						})}
					</div>
					<div
						style={{
							width: `70%`,
							marginLeft: '10px'
						}}
					>
						{WorkerWithHealthContent({
							wokerList: getZoneList(),
							workerWatch: workerWatch,
							isControlZone,
							t: t,
							i18n: i18n
						})}
					</div>
				</>
			)
		}
		return (
			<>
				{
					!isControlZone && filterModel.filterType !== FILTER_HEALTH ? <div style={{ width: '40%' }}>
						{
							ContractorContent({
								filterType: filterModel.filterType,
								contractorList: (zoneInfo?.zones.length ?? 0) > 0 ?
									zoneInfo?.zones[0]?.contractors ?? [] : [],
								t: t,
								i18n: i18n
							})
						}
					</div> : null
				}
				<div
					style={{
						width: `${isControlZone || filterModel.filterType === FILTER_HEALTH ?
							"100" : "60"}%`,
						marginLeft: '10px'
					}}
				>
					{
						(filterModel.filterType === FILTER_MANCHINE) ?
							MechanicalContent({
								isControlZone,
								filterType: filterModel.filterType,
								mechanicalList: getZoneList(),
								t: t,
								i18n: i18n,
							}) : filterModel.filterType === FILTER_HEALTH && !isControlZone ?
								WorkerHealthContent({
									wokerList: getZoneList(),
									t: t,
									i18n: i18n
								}) : WorkerContent({
									wokerList: getZoneList(),
									isControlZone,
									t: t,
									i18n: i18n,
								})
					}
				</div>
			</>
		)
	}

	return (
		<div
			className='div-box-container-dark-zone-detail'
			style={{
				border: '2px solid rgba(255,143,13,1)',
				display: 'flex',
				height: '100%',
				flexDirection: 'column'
			}}
		>
			{renderHeader()}
			
			<div className='full-size-flex-row-div'>
				{renderContent()}
			</div>
		</div >
	)
}

export default ZoneDetail;