import React from 'react'
import ButtonModel from '../button/buttonModel';
import OuterLineButton from '../button/outerLineButton';
import NormalLabel from '../label/normalLabel';
import IconWarning from '../../assests/images/icon-warning.png';
import IconMute from '../../assests/images/icon-mute.png';
import { AlertTableListModel, AlertTableModel } from '../../model/alertTable/alertTableModel';

import '../../i18n'
import { TFunction, useTranslation } from "react-i18next";

const AlertTable: React.FC<{
	messageList: AlertTableListModel[],
	isAlertWarning: boolean,
	mutedCount: number,
	showAllCallBack: React.MouseEventHandler
}> = ({ messageList, isAlertWarning, mutedCount, showAllCallBack }) => {

	const { t, i18n } = useTranslation()

	const buttonModelAlert: ButtonModel = {
		title: t('home_alert_table_show_all'),
		onClick: showAllCallBack,
		style: {
			height: '40px',
		}
	}

	const buttonModelAlertWarning: ButtonModel = {
		title: t('home_alert_table_show_all'),
		onClick: showAllCallBack,
		style: {
			height: '50px',
			backgroundColor: 'transparent',
			border: '2px solid white',
			color: 'white',
			background: 'transparent'
		}
	}

	const isEng = i18n.language.includes("en")

	return (
		<div
			className={
				isAlertWarning ?
					'div-box-container-warning' :
					'div-box-container'
			}
			style={{
				paddingLeft: '25px',
				paddingRight: '25px'
			}}
		>
			<div
				className='full-size-flex-div'
				style={{
					flexDirection: 'column',
					paddingTop: '15px',
					paddingBottom: '15px',
				}}
			>
				<div
					className='full-size-flex-div'
					style={{
						alignItems: 'center',
						flex: '1'
					}}
				>
					<div
						className='full-size-flex-div'
						style={{
							flex: '4'
						}}
					>
						<div>
							<NormalLabel title={t('alert_table_title')} />
						</div>
						{
							isAlertWarning ?
								<img
									src={IconWarning}
									style={{
										width: '36px',
										height: '36px',
										marginLeft: '15px'
									}}
									alt="" /> :
								null
						}
						{
							mutedCount > 0 ? <div style={{ display: 'flex' }}>
								<img
									src={IconMute}
									style={{
										width: '24px',
										height: '24px',
										margin: '10px 10px 10px 30px',
									}}
									alt="" />
								<NormalLabel title={t("home_zoner_muted_zone", {
									count: mutedCount
								})} textColor={"rgba(255, 84, 84, 1)"} />
							</div> : <></>
						}

					</div>
					<div
						style={{
							flex: '1',
						}}
					>
						<OuterLineButton
							viewModel={
								isAlertWarning ?
									buttonModelAlertWarning :
									buttonModelAlert}
						/>
					</div>
				</div>
				<div
					className='full-size-flex-div'
					style={{
						flexDirection: 'column',
						paddingTop: '15px',
					}}
				>
					{
						messageList.map((value, index) => {
							return (
								<div
									key={index}
									style={{
										paddingTop: "10px",
										paddingBottom: '10px',
										display: 'flex'
									}}
								>
									<div className='circle-div' />
									<div
										dangerouslySetInnerHTML={{ __html: isEng ? value.body.en : value.body.tc }}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'Hidden',
											height: '30px',
											maxWidth: '38vw',
											textOverflow: 'ellipsis',
											color: 'white',
											fontSize: '16px',
										}}
									/>

									{/* <div style={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										maxWidth: '700px',
									}}>
										<div dangerouslySetInnerHTML={{ __html: "" }}></div>
										<NormalLabel title={getAlertMessageText(value)} fontSize='16' key={index} />
									</div>
									<div style={{ paddingLeft: '10px' }}>
										<NormalLabel title={value.zone} fontSize='16' key={`${value?.zone ?? ""}${index}`} textColor={"rgba(242, 151, 72, 1)"} />
									</div> */}

								</div>
							);
						})
					}
				</div>
			</div>
		</div >
	)
}

export default AlertTable;