import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import ButtonModel from './buttonModel'

const AppIconButton: React.FC<{
	viewModel: ButtonModel,
	image: string
}> = ({ viewModel, image }) => {
	return (
		<IconButton
			onClick={viewModel.onClick}
			style={viewModel.style}
			className='app-icon-button'
			disableRipple
		>
			<img src={image} style={{ width: '18px', height: '18px' }} alt="" />
		</IconButton>

	)
}

export default AppIconButton;