import React from 'react'
import ButtonModel from '../button/buttonModel'
import OuterLineButton from '../button/outerLineButton'
import NormalLabel from '../label/normalLabel'

const WorkerInZone: React.FC<{
	time?: string,
	count?: number,
	showButton: boolean,
	btnModel?: ButtonModel
}> = ({ time, count, showButton, btnModel }) => {

	return (
		<div
			className=''
			style={{
				height: '47%',
				padding: '20px',
				justifyContent: 'start',
			}}
		>
			<div className='full-size-flex-row-div'>
				<div style={{ flex: '2', textAlign: 'center' }}>
					<NormalLabel title={time ?? ""} />
				</div>
				<div style={{ flex: '2', textAlign: 'center' }}>
					<NormalLabel title={`${count ?? "-"}`} fontSize={'36'} />
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignContent: 'center',
						flex: '1'
					}}>
					{
						showButton ?
							<OuterLineButton viewModel={btnModel!} /> : <div />
					}
				</div>
			</div>
		</div >
	)
}

export default WorkerInZone