const FILTER_HUMAN = "HUMAN";
const FILTER_MANCHINE = "MANCHINE";
const FILTER_HEALTH = "HEALTH";

export { FILTER_HUMAN, FILTER_MANCHINE, FILTER_HEALTH }

export const DEFAULT_FILTER = {
	constructor: '顯示全部',
	constructorEn: 'Show All',
	constructorCode: 'all',
	constructorUid: 'all',
	type: '顯示全部',
	typeEn: 'Show All',
	typeCode: 'all',
	filterType: 'HUMAN'
}

export const ALLTRADE = {
	tradeCode: "all",
	nameEng: "Show All",
	nameChi: "顯示全部",
	descEng: "Show All",
	descChi: "顯示全部",
}

export const ALLCONTRACTOR = {
	contractorCode: "all",
	idDocument: "顯示全部",
	idNo: "顯示全部",
	englishName: "Show All",
	chineseName: "顯示全部",
	contactName: "顯示全部",
	contactPhone: "顯示全部",
	contractorUID: 'all',
	address: "顯示全部",
	country: "顯示全部",
	remarks: "顯示全部",
}

export const CHUNK_SIZE = 20;