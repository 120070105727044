import React from 'react'
import {Zone} from "../../model/home/homePageModel";
import {ControlZone} from "../../model/project/projectModel";
import ZoneList from "./zoneList";
import {useTranslation} from "react-i18next";
import {MicSummary} from "../../model/MicSummary";

const MicZoneList: React.FC<{
  micSummary: MicSummary | null | undefined,
  zoneList: Zone[],
  controlZoneList: ControlZone[],
  isShowZeroCountZones: boolean,
  onChangeViewType?: (viewType: string) => void
  onSelectZoneName?: (string: string) => void
}> = ({micSummary, zoneList, controlZoneList, isShowZeroCountZones, onChangeViewType, onSelectZoneName}) => {
  const {t} = useTranslation()

  const getZoneList = () => {
    const resultZoneList = zoneList.map(x => ({
      ...x,
      count: micSummary ? micSummary.zones[x.name] ?? 0 : null,
    }) as Zone).filter(x => x.count || isShowZeroCountZones)
    if (micSummary) {
      return resultZoneList.sort((a, b) => b.count! - a.count!)
    }
    return resultZoneList;
  }

  const getControlZoneList = () => {
    const resultZoneList = controlZoneList.map(x => ({
      name: x.zone,
      count: micSummary ? micSummary.zones[x.zone] ?? 0 : null,
    }) as Zone).filter(x => x.count || isShowZeroCountZones)
    if (micSummary) {
      return resultZoneList.sort((a, b) => b.count! - a.count!)
    }
    return resultZoneList;
  }

  return (
    <>
      <div className='home-bottom-zone-div'>
        <ZoneList
          contentTitle={t('home_mimep')}
          contentType={'mic'}
          title={t('home_working_zone')}
          selectedZone={undefined}
          zoneList={getZoneList()}
          lastUpdateTime={null ?? ""}
          isControlZone={false}
          hideContentTypeSelect={true}
          onChangeViewType={onChangeViewType}
          onItemClickHandle={x => onSelectZoneName?.(x.name)}
          mutedList={[]}
        />
      </div>
      <div className='home-bottom-zone-div'>
        <ZoneList
          contentType={'mic'}
          title={t('home_controlled_zone')}
          selectedZone={undefined}
          zoneList={getControlZoneList()}
          lastUpdateTime={null ?? ""}
          isControlZone={true}
          onItemClickHandle={x => onSelectZoneName?.(x.name)}
          mutedList={[]}
        />
      </div>
    </>
  )
}

export default MicZoneList