import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import PinImg from '../assests/images/icon-project-pin.png';
import ButtonModel from '../components/button/buttonModel';
import NormalButton from '../components/button/normalButton';
import { useSelector } from "../redux/store";
import { setProject } from "../redux/userSlice";
import { showFilter, showProject } from "../redux/viewSlice";
import { useDispatch } from "react-redux";
import { AppService } from '../services/appService';
import { ProjectInfo } from '../model/project/projectModel';
import { Spinner } from 'react-bootstrap';
import { filter } from '../redux/filterSlice';
import { DEFAULT_FILTER } from '../model/constants';
import { HandleLogout, RefreshToken } from '../services/authenticationService';
import '../i18n'
import { useTranslation } from "react-i18next";
import IconSearchGrey from '../assests/images/icon-search-grey.png';

const PickProject: React.FC<{
	appService: AppService
}> = ({ appService }) => {

	const { t, i18n } = useTranslation()
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [selectedProject, setSelectedProject] = useState<ProjectInfo>(useSelector((state: any) => state.user.project));
	const isShowProject = useSelector((state: any) => state.view.showProject) as boolean;

	const [isShowLoading, setIsShowLoading] = useState(false);

	const [projectList, setProjectList] = useState<ProjectInfo[]>();

	const [searchKeyWord, setSearchKeyWord] = useState<string>("");

	useEffect(() => {
		getProjectList(false);
	}, []);

	const getProjectList = (isRefreshed: boolean) => {
		setIsShowLoading(true);
		appService.projectServices
			.getAttendProjectList()
			.subscribe(
				(value: any) => {
					setProjectList(value.data.data);
					setIsShowLoading(false);
				},
				(error) => {
					setIsShowLoading(false);
					if (isRefreshed) {
						HandleLogout(dispatch, navigate);
					} else {
						RefreshToken(
							appService,
							error.httpStatusCode,
							dispatch,
							navigate,
							() => {
								getProjectList(true);
							}
						);
					}
				}
			);
	}

	const selectItemHandle = (value: ProjectInfo) => {
		setSelectedProject(value);
	}

	const confirmHandle = () => {
		if (selectedProject) {
			dispatch(setProject(selectedProject));
			if (isShowProject) {
				dispatch(filter(DEFAULT_FILTER))
				dispatch(showProject(false));
				dispatch(showFilter(false));
			} else {
				navigate('/home');
			}
		}
	}

	const buttonModel: ButtonModel = {
		title: t('pick_project_confirm'),
		onClick: confirmHandle,
		style: {
			paddingTop: '30px',
			paddingBottom: '30px'
		}
	}

	return (
		<Container fluid className='main-container' style={{ backgroundColor: 'transparent' }}>
			{
				isShowProject === false ? <div style={{ height: '12%' }} /> : null
			}
			<div className='project-container'>
				<label className='project-title'>{t('pick_project_title')}</label>
				<div className='div-spacing-line' style={{ marginBottom: '5px' }} />
				<div>
					<img
						src={IconSearchGrey}
						style={{
							marginLeft: "7%",
							width: "18px",
							height: "18px",
						}}
						alt=""
					/>
					<input
						className={'project-search-input'}
						type="text"
						name="keywords"
						placeholder={t("pick_project_search_placeholder")}
						onChange={(event) => {
							setSearchKeyWord(event.target.value.toLowerCase())
						}}
					/>
				</div>

				<div className='div-spacing-line' style={{ marginBottom: '10px', marginTop: '5px' }} />
				<div className='project-container-scroll'>
					{
						projectList?.filter((e) => {
							return i18n.language.includes("tc") ?
								e.chineseName.toLowerCase().includes(searchKeyWord) :
								e.englishName.toLowerCase().includes(searchKeyWord);
						})?.map((project, index) => {
							return (
								<div
									key={index}
									onClick={() => selectItemHandle(project)}
									className={
										selectedProject?.projectID === project.projectID ?
											'project-list-div-selected' :
											'project-list-div'}
								>
									<div className='full-size-div' style={{ paddingLeft: '10px', }}>
										<img
											className='project-list-item-pin-image'
											src={PinImg}
											alt='pin image' />
										<label className='project-list-item-label'>
											{
												i18n.language.includes("tc") ?
													(project.chineseName.length > 0 ? project.chineseName : "-") :
													(project.englishName.length > 0 ? project.englishName : "-")
											}
										</label>
									</div>
								</div>
							);
						})
					}
				</div>
				<div className='div-spacing-line' style={{ marginTop: '10px' }} />
				<NormalButton viewModel={buttonModel} />
			</div>
			{
				isShowLoading ? <div className='pop-up-div-loading'>
					<Spinner animation="border" role="status" >
						<span className="visually-hidden"></span>
					</Spinner>
				</div> : null
			}
		</Container >
	);
}

export default PickProject;